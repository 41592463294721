import { useContext, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Avatar, Divider,  ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, useTheme } from '@mui/material';
import Settings from '@mui/icons-material/Settings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import Logout from '@mui/icons-material/Logout';
import { AppContext } from '../../contexts/main';
import CopyToClipboard from '../common/CopyToClipboard';
import { AccountBalance, AccountBalanceOutlined, AccountBalanceWallet, Wallet } from '@mui/icons-material';
import { useWallet } from '@solana/wallet-adapter-react';


export default function UserMenu() {
  
  const theme = useTheme();

  const { disconnect } = useWallet();
  const navigate = useNavigate();

  const { profile, handleSwitchMode } = useContext(AppContext);

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchor(null);
  };

  const handleLogout = () => {
    disconnect();
    setAnchor(null);
    navigate('/welcome');
  };


  return (
    <>
      {profile &&
        <Box sx={{ display: 'flex' }}>
          <Tooltip title="Open menu">
            <Avatar
              alt={`/${profile?.address}`}
              src={profile?.metadata?.image}
              sx={{
                // mt: 0.5,
                mr: 1,
                height: { xs: 30, sm: 35 },
                width: { xs: 30, sm: 35 },
                bgcolor: theme => `${theme.palette.primary.main}`,
                border: theme => `2px solid #ccc`
              }}
              aria-label="recipe"

              onClick={handleOpenUserMenu}
            >
              {profile?.firstName ? profile.firstName.charAt(0) : profile?.address.toString().charAt(0)}
            </Avatar>
          </Tooltip>

          <Menu
            sx={{ mt: '35px' }}
            id="menu-appbar"
            anchorEl={anchor}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                // maxHeight: ITEM_HEIGHT * 4.5,
                width: '30ch',
              },
            }}
            open={Boolean(anchor)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              // onClick={handleCloseUserMenu}
              component='div'
              sx={{ cursor: 'default' }}
            >
              <Avatar
                alt={`/${profile?.address}`}
                src={profile?.metadata?.image}
                sx={{
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                }}
              >
                {profile?.firstName ? profile.firstName.charAt(0) : profile?.address.toString().charAt(0)}
              </Avatar>

              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    noWrap
                    component="h6"
                  // sx={{ }}
                  >
                     {profile?.firstName ? `${profile.firstName} ${profile.lastName}`: `@${profile.alias}`}
                  </Typography>
                }
                secondary={
                  <>
                    {`@${profile.alias}`}
                  </>
                }
                secondaryTypographyProps={{ display: 'flex' }}
              />
            </MenuItem>

            <Divider />

            <MenuItem
              // onClick={handleCloseUserMenu}
              component='div'
              sx={{ cursor: 'default' }}
            >
              <ListItemIcon>
                <AccountBalanceWallet fontSize="small" />
              </ListItemIcon>

              <ListItemText
                primary={
                  <>
                    <Typography
                      variant="caption"
                      noWrap
                      component="p"
                    // sx={{ }}
                    >
                      {profile?.address.toString()}
                    </Typography>

                    <CopyToClipboard textToCopy={profile?.address.toString()} />
                  </>
                }
                primaryTypographyProps={{ display: 'flex' }}
              />
            </MenuItem>

            <Divider />

            <MenuItem
              component={NavLink}
              to="settings"
              onClick={() => setAnchor(null)}
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>

              <Typography variant="subtitle2" >
                Settings
              </Typography>

            </MenuItem>

            <MenuItem
              component={NavLink}
              to="help"
              onClick={() => setAnchor(null)}
            >
              <ListItemIcon>
                <HelpOutlineIcon fontSize="small" />
              </ListItemIcon>

              <Typography variant="subtitle2" >
                Help center
              </Typography>

            </MenuItem>

            <MenuItem
              onClick={() => {
                setAnchor(null);
                handleSwitchMode();
              }}
            >
              <ListItemIcon>
                <NightsStayIcon fontSize="small" />
              </ListItemIcon>

              <Typography variant="subtitle2" >
                {`To ${theme.palette.mode === 'light' ? 'dark' : 'light'} theme`}
              </Typography>

              {/* <ThemeSwitcher /> */}

              {/* <FormControlLabel
            value="start"
            control={<Switch color="primary" />}
            label={
              <Typography variant="subtitle2" >
                Dark theme
              </Typography>
            }
            labelPlacement="start"
          /> */}

              {/* <ListItemIcon>
            <NightsStayIcon fontSize="small" />
          </ListItemIcon> */}

              {/* <Typography variant="body2" color="text.secondary">
            ⌘X
          </Typography> */}

            </MenuItem>

            <Divider />

            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>

              <Typography variant="subtitle2" >
                Logout
              </Typography>

            </MenuItem>

          </Menu>
        </Box>
      }
    </>
  );
}