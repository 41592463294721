import { Container, Grid } from "@mui/material"
import React from "react"
import { ErrorComponent } from "../components/common"
import Header from "../components/layouts/Header"
import Footer from "../components/layouts/Footer"
import LeftColumn from "../components/layouts/LeftColumn"
import RightColumn from "../components/layouts/RightColumn"

export default function ErrorPage() {

  return (
    <>
      <Header />

      <div id="main">
        <Container maxWidth="lg">
          <Grid container spacing={2}>

            <Grid item sm={2}>
              <LeftColumn />
            </Grid>

            <Grid item sm={7}>
              <ErrorComponent />
            </Grid>

            <Grid item sm={3}>
              <RightColumn />
            </Grid>

          </Grid>
        </Container>
      </div>

      <Footer />
    </>
  )

}