import { Box, Button, Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";


export default function AccessRequest() {

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 400,
          border: theme => `1px solid ${theme.palette.background.paper}`,
          backgroundImage: theme => `url('/access-request-banner-${theme.palette.mode}.png')`
        }}
      >
        <Box
        // display={'flex'}
        // flexDirection={'column'}
        // justifyContent={'center'}
        >
          <Box
            component={'h3'}
            textAlign={'center'}
          >
            Request
          </Box>

          <Box
            component={'h2'}
            textAlign={'center'}
          >
            Early Access
          </Box>
        </Box>

        <Button
          component={Link}
          to={'https://docs.google.com/forms/d/1WgO7Y6wcM8fI1G02gML9jiulg45wwe4uL6PSTwcZEZk'}
          target='_blank'
          variant='contained'
        >
          Fill Form
        </Button>

      </Card>

    </>
  )
}
