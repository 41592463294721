import React, { useContext, useEffect, useState } from "react"
import { Avatar, Box, Button, Container, Grid, Input, InputLabel, Link, useTheme } from "@mui/material";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-material-ui";
import Footer from "../components/layouts/Footer";
import RegisterForm from "../components/RegisterForm";
import { AppContext } from '../contexts/main';
import { Ju, Profile, PublicKey } from "@ju-protocol/sdk";
import { Loader } from "../components/common";
import { NavLink } from "react-router-dom";
// import backgroundImage from '/public/welcome.jpg'; // Import using relative path


export default function WelcomePage() {

  const { ju, profile, profileLoading, profileError } = useContext(AppContext);

  const { connecting, connected, publicKey, disconnect } = useWallet();

  // const fetchData = () => {
  //   // setProfileLoading(true);
  //   // setProfileError(null);

  //   // ju.core().profiles(app).getProfile(publicKey!)
  //   //   .then(data => {
  //   //     setProfile(data);
  //   //   })
  //   //   .catch(error => {
  //   //     setProfileError(error)
  //   //   })
  //   //   .finally(() => {
  //   //     setProfileLoading(false);
  //   //   })
  // }

  useEffect(() => {

  }, [connected, publicKey]);

  return (
    <Box
      sx={{
        backgroundImage: `url('background-3.jpg')`,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }}
      display={'flex'}
      height={'100vh'}
    >

      <Box
        sx={{
          display: { xs: 'none', sm: 'flex' },
        }}
        alignItems='center'
        justifyContent={'center'}
        height={'100%'}
        width={'60%'}
      // bgcolor={theme.palette.secondary.main}
      >
        <img
          alt="Welcome to Feel3 Space"
          src="/welcome.png"
          width={"300px"}
        />
        {/* Decentralized Social Network */}
      </Box>

      <Box
        display={'flex'}
        alignItems='center'
        justifyContent={'center'}
        p={3}
        height={'100%'}
        width={'40%'}
        bgcolor={theme => theme.palette.secondary.main}
        boxShadow={10}
      >
        <Box
          width={'330px'}
        >
          {(!connecting && !connected) &&
            <Box textAlign={'center'}>
              <h2>Connect wallet</h2>
              <WalletMultiButton />

              {/* <Button
                      sx={{
                        display: 'block',
                        m: '10px auto'
                      }}
                      variant="text"
                      size="small"
                      onClick={disconnect}
                    >
                      Disconnect Wallet
                    </Button> */}
            </Box>
          }

          {(connecting || profileLoading) &&
            <Loader height="300px" />
          }

          {connected && !profile && !profileLoading &&
            <Box width={'100%'}>
              <RegisterForm />

              <Button
                sx={{
                  display: 'block',
                  m: '10px auto'
                }}
                variant="text"
                size="small"
                onClick={disconnect}
              >
                Disconnect Wallet
              </Button>
            </Box>
          }

          {connected && !profileLoading && profile &&
            <Box
              textAlign={'center'}
            >
              <Box
                mb={2}
              >
                Welcome
                <Box component='h2' mt={0}>
                  {profile.firstName ? profile.firstName : `@${profile.alias}`} !
                </Box>
              </Box>

              <Button
                component={NavLink}
                to={`/${profile.address.toString()}`}
                variant='contained'
              >
                Go to Profile
              </Button>
            </Box>
          }
        </Box>
      </Box>


    </Box>
  );

}