import { PublicKey } from "@solana/web3.js";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useContext } from "react";
import { AppContext } from "../contexts/main";


dayjs.extend(relativeTime);


export function getProfilePda(address?: string): PublicKey | null {
  if (address) {
    try {
      let pubkey = new PublicKey(address);

      // Check if address is PDA while all Ju entities are Pda
      if (!PublicKey.isOnCurve(pubkey.toBuffer())) {
        return pubkey
      }
    } catch (error) {
      // console.log('error :>> ', error);
    }
  }
  return null
}

export function getSubspacePda(address?: string): PublicKey | null {
  if (address) {
    try {
      let pubkey = new PublicKey(address);

      // Check if address is PDA while all Ju entities are Pda
      if (!PublicKey.isOnCurve(pubkey.toBuffer())) {
        return pubkey
      }
    } catch (error) {
      // console.log('error :>> ', error);
    }
  }
  return null
}


// export function isReactToPublication(publication: PublicKey): boolean {

//   const { ju, APP, profileAddress } = useContext(AppContext);

//   const reactionPda = ju.core().pdas().reaction(APP, publication, )

//   try {
//     let pubkey = new PublicKey(address);

//     // Check if address is PDA while all Ju entities are Pda
//     if (!PublicKey.isOnCurve(pubkey.toBuffer())) {
//       return pubkey
//     }
//   } catch (error) {
//     console.log('error :>> ', error);
//   }

//   return false
// }


export function fromTimestamp(timestamp: any): string {
    
  return dayjs.unix(Number(timestamp)).fromNow();
}


export const DataURIToByteString = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  let ia;

  // if (dataURI.split(',')[0].indexOf('base64') >= 0) {
  //   byteString = atob(dataURI.split(',')[1]);
  // } else {
  //   byteString = encodeURI(dataURI.split(',')[1]);
  // }

  // write the bytes of the string to a typed array
  ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return ia;
}