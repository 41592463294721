import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, Step, StepLabel, Stepper, TextField, Tooltip, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Close } from '@mui/icons-material';


interface SubmitDialogProps {
  isOpen: boolean;
  steps: string[];
  activeStep: number;
  isCompleted: boolean;
  stepError: string | null;
}

export default function SubmitDialog({ isOpen, steps, activeStep, isCompleted, stepError }: SubmitDialogProps) {

  // console.log('stepError', stepError)

  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
  };

  if (isCompleted) {
    setTimeout(() => setOpen(false), 1000);
  }


  return (
    <>
      <Dialog
        open={open}
        onClose={stepError !== null ? handleClose : undefined}

        aria-labelledby="submit-dialog"

        sx={{
          minHeight: '200px'
        }}
      >
        {/* <DialogTitle>Submitting data...</DialogTitle> */}

        <DialogContent>

          <Box
            width={'100%'}
            mb={1}
            textAlign={'center'}
          >

            {stepError !== null
              ?
              <>
                <Typography
                  component={'p'}
                  // variant='caption'
                  color={theme => theme.palette.error.main}
                >
                  Failed
                </Typography>

                {/* <Typography
                  component={'p'}
                  variant='caption'
                >
                  error text:
                </Typography> */}
              </>
              :
              <>
                {!isCompleted && stepError === null
                  ?
                  <>
                    <Typography
                      component={'p'}
                      // variant='caption'
                      color={theme => theme.palette.primary.main}
                    >
                      {`Processing ${activeStep + 1} / ${steps.length} ...`}
                    </Typography>

                    <Typography
                      component={'p'}
                      variant='caption'
                    >
                      (please don't close this page)
                    </Typography>
                  </>
                  :
                  <>
                    <Typography
                      component={'p'}
                      // variant='caption'
                      color={theme => theme.palette.primary.main}
                    >
                      Done!
                    </Typography>

                    <Typography
                      component={'p'}
                      variant='caption'
                    >
                      submitted successfully
                    </Typography>
                  </>
                }
              </>
            }

          </Box>

          <Box
            width={'100%'}
            my={3}
            textAlign={'center'}
          >

            {stepError !== null
              ?
              <>
                <Typography
                  component={'p'}
                  variant='caption'
                  color={theme => theme.palette.secondary.dark}
                >
                  {stepError}
                </Typography>
              </>
              :
              <>
                {!isCompleted
                  ?
                  <CircularProgress color='secondary' />
                  :
                  <CheckCircleOutlineIcon color='success' sx={{ fontSize: 44 }} />
                }
              </>
            }

          </Box>

          <Box sx={{ width: '100%' }}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
            >
              {steps.map((label, index) => {

                const labelProps: {
                  optional?: React.ReactNode;
                  error?: boolean;
                } = {};
                if (stepError !== null && activeStep === index) {
                  // labelProps.optional = (
                  //   <Typography variant="caption" color="stepError">
                  //     Alert message
                  //   </Typography>
                  // );
                  labelProps.error = true;
                }
                return (
                  <Step key={label}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          </Box>

        </DialogContent>

      </Dialog>

      {/* <IconButton
        sx={{
          position: 'absolute',
          top: -2,
          right: -2
        }}
      >
        <Close />
      </IconButton> */}
    </>
  );
}
