import React, { useContext, useEffect, useState } from 'react'
import { Ju, PublicKey } from '@ju-protocol/sdk'
import { useConnection } from "@solana/wallet-adapter-react";
import { useProfile } from '../hooks';
import { Avatar, Button, FormControl, Box, Input, InputLabel, styled, TextField, InputAdornment, CircularProgress, Typography, Alert } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { AppContext } from '../contexts/main';
import { enqueueSnackbar } from 'notistack';
import { AlternateEmail, Check, PersonOff, Warning } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';


interface RegistrationFormData {
  firstName: string;
  lastName: string;
  handler: string;
  avatar: string;
}

interface RegisterFormProps {
  onSubmit: (formData: RegistrationFormData) => void;
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: 'auto',
  backgroundColor: theme.palette.primary.main,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui - focused': {
    color: '#fff',
  },
  '& .MuiOutlinedInput-root': {
    borderColor: '#fff'
  }
}));

export default function RegisterForm() {

  const { APP, ju, profileClient, setProfile } = useContext(AppContext);

  const [success, setSuccess] = useState<boolean>(false);

  const [alias, setAlias] = useState<string | null>(null);
  const [aliasError, setAliasError] = useState<string | null>(null);

  const [isCheckingAlias, setIsCheckingAlias] = useState(false);
  const [isAliasAvailable, setIsAliasAvailable] = useState<boolean | undefined>(undefined);

  const [firstName, setFirstName] = useState<string>();
  const [firstNameError, setFirsNameError] = useState<string | null>(null);

  const [lastName, setLastName] = useState<string>();
  const [lastNameError, setLastNameError] = useState<string | null>(null);

  const [inviteCode, setInviteCode] = useState<string>();
  const [inviteCodeError, setInviteCodeError] = useState<string | null>(null);

  // const [avatar, setAvatar] = useState<ImageAttachment | undefined>(undefined);
  // const [avatarError, setAvatarError] = useState<string | null>(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isValidationError, setIsValidationError] = React.useState(false);

  const submitSteps: string[] = [
    "Upload metadata to decentralized storage",
    "Save data into Solana blockchain"
  ];
  const [activeStep, setActiveStep] = useState<number>(0);
  const [submitCompleted, setSubmitCompleted] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);


  const aliasChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;

    setIsAliasAvailable(undefined);

    setAlias(value);
  }

  // Alias availability
  const checkAlias = async () => {
    if (alias && !(alias.length < 4 || alias.length > 30)) {
      setIsAliasAvailable(undefined);
      setIsCheckingAlias(true);

      // console.log(`checking alias ${alias} ...`)

      const aliasPda = ju.core().pdas().alias(
        {
          app: APP,
          alias
        }
      );

      try {
        const isExist = await ju.rpc().accountExists(aliasPda);

        if (isExist) {
          setAliasError(`@${alias} is unavailable`)

          setIsAliasAvailable(false);
          setIsCheckingAlias(false);

          setIsValidationError(true);
        } else {
          setIsAliasAvailable(true);
          setIsCheckingAlias(false);

          setIsValidationError(false);
        }
      } catch (error) {
        setAliasError(`Unable to check @${alias} alias`);

        setIsAliasAvailable(false);
        setIsCheckingAlias(false);

        setIsValidationError(true);
      }
    }
  }


  const validateAlias = () => {
    let isError = false;

    if (alias && (alias.length < 4 || alias.length > 30)) {
      isError = true;
      setAliasError('Must be between 3 and 10 characters!');
    } else {
      isError = false;
      setAliasError(null);
    }

    return isError;
  }


  const validateFirstname = () => {
    let isError = false;

    if (firstName && (firstName.length === 0 || firstName.length > 32)) {
      isError = true;
      setFirsNameError('Must be between 1 and 32 characters!');
    } else {
      isError = false;
      setFirsNameError(null);
    }

    return isError;
  }

  const validateLastname = () => {
    let isError = false;

    if (lastName && (lastName.length > 32)) {
      isError = true;
      setLastNameError('Must be not more than 32 characters!');
    } else {
      isError = false;
      setLastNameError(null);
    }

    return isError;
  }


  const validateAll = () => {
    let isError = false;

    if (validateAlias()) {
      isError = true;
    }

    if (validateFirstname()) {
      isError = true;
    }

    if (validateLastname()) {
      isError = true;
    }

    setIsValidationError(isError);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);
    setActiveStep(0);

    profileClient().createProfile(
      {
        alias,
        firstName,
        lastName,
      },
      inviteCode
    )
      .then(res => {
        if (res.profile.address) {
          enqueueSnackbar("Profile created successful!");

          setProfile(res.profile);

          setSubmitCompleted(true);
          setSuccess(true);

          setIsLoading(false);
        }
      })
      .catch(error => {
        enqueueSnackbar("Error");

        setSubmitError(String(error));
        console.log('error', error)

        setIsLoading(false);
      }
      )
  };


  useEffect(() => {
    validateAll();
  }, [
    firstName,
    lastName,
    alias,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      checkAlias();
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [alias])


  return (
    <Box
      width={'100%'}
      mt={2}
      textAlign='center'
    // color={'fff'}
    >
      <h2>
        Create profile
      </h2>

      <Box
        width={'100%'}
        px={3}

        component='form'
        onSubmit={handleSubmit}
      >
        {/* <StyledAvatar >
          <AccountCircleIcon />
        </StyledAvatar> */}

        <Box
          width={'100%'}
          minHeight={'75px'}
        >
          <StyledTextField
            required
            fullWidth
            size='small'
            id="input-alias"
            label="alias"

            autoComplete='off'

            value={alias}

            error={aliasError !== null}
            helperText={aliasError}

            InputProps={{
              startAdornment: (
                <InputAdornment position="start" >
                  <AlternateEmail fontSize='inherit' />
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment position="end" >
                  {isCheckingAlias &&
                    <CircularProgress color="inherit" size={16} />
                  }
                  {isAliasAvailable && !isCheckingAlias &&
                    <Check color="success" fontSize='inherit' />
                  }
                  {isAliasAvailable === false && !isCheckingAlias &&
                    <Warning color="error" fontSize='inherit' />
                  }
                </InputAdornment>
              )
            }}

            // onBlur={checkAlias}

            sx={{ color: '#fff' }}

            onChange={aliasChange}
          />
        </Box>

        <Box
          width={'100%'}
          minHeight={'75px'}
        >
          <TextField
            required
            fullWidth
            size='small'
            id="input-name"
            label="First name"

            autoComplete='off'

            value={firstName}

            error={firstNameError !== null}
            helperText={firstNameError}

            onChange={(e) => setFirstName(e.target.value)}
          />
        </Box>

        <Box
          width={'100%'}
          minHeight={'75px'}
        >
          <TextField
            // required
            fullWidth
            size='small'
            id="input-lastName"
            label="Last name"

            autoComplete='off'

            value={lastName}

            error={lastNameError !== null}
            helperText={lastNameError}

            onChange={(e) => setLastName(e.target.value)}
          />
        </Box>

        <Box
          width={'100%'}
          minHeight={'75px'}
        >
          <TextField
            required
            fullWidth
            size='small'
            id="input-invite-code"
            label="Invite code"

            autoComplete='off'

            value={inviteCode}

            error={inviteCodeError !== null}
            helperText={inviteCodeError}

            onChange={(e) => setInviteCode(e.target.value)}
          />
        </Box>

        {!success
          ?
          <Button
            type='submit'
            // startIcon={isLoading ? <CircularProgress color="inherit" size={16} /> : <PersonAddAltIcon />}
            variant="contained"
            // size='small'
            disabled={isLoading || isValidationError}
          >
            {isLoading
              ?
              <>
                <CircularProgress color="inherit" size={16} /> ...
              </>
              :
              'Sign Up'
            }
            {/* {isLoading ? 'Registering ...' : 'Register'} */}
          </Button>
          :
          <Button
            component={NavLink}
            to={'/'}
          >
            Explore!
          </Button>
        }

      </Box>

    </Box>
  )
}
