import React, { useContext, useEffect, useState } from "react"
import { useParams, useLocation, NavLink } from "react-router-dom";
import { ErrorComponent, Loader } from "../components/common";
import { AppContext } from '../contexts/main';
import PublicationCreateCard from "../components/Publications/PublicationCreateCard";
import PublicationCard, { PublicationCardSkeleton } from "../components/Publications/PublicationCard";
import SubspaceHeader, { SubspaceHeaderSkeleton } from "../components/subspace/SubspaceHeader";
import { PublicKey } from "@solana/web3.js";
import { Subspace, Publication } from "@ju-protocol/sdk";
import { getSubspacePda } from "../utils/helpers";
import { Box, Card } from "@mui/material";


export default function SubspacePage() {

  const { id } = useParams();

  const { profile, subspaceClient, publicationClient } = useContext(AppContext);

  const [subspacePublicKey, setSubspacePublicKey] = useState<PublicKey>();
  const [subspacePublicKeyError, setSubspacePublicKeyError] = useState<string | null>(null);

  const [subspace, setSubspace] = useState<Subspace>();
  const [subspaceLoading, setSubspaceLoading] = useState(false);
  const [subspaceError, setSubspaceError] = useState<Error | null>(null);

  const fetchSubspace = () => {
    setSubspaceLoading(true);
    setSubspaceError(null);

    subspaceClient().getSubspace(subspacePublicKey!)
      .then(data => {
        setSubspace(data);
      })
      .catch(error => {
        setSubspaceError(error)
      })
      .finally(() => {
        setSubspaceLoading(false);
      })
  }


  const [publications, setPublications] = useState<Publication[]>();
  const [publicationsLoading, setPublicationsLoading] = useState(false);
  const [publicationsError, setPublicationsError] = useState<Error | null>(null);

  const fetchPublications = () => {
    setPublicationsLoading(true);
    setPublicationsError(null);

    publicationClient().findPublications(
      {
        isEncrypted: false,
        subspace: subspacePublicKey,
        loadJsonMetadata: true
      }
    )
      .then(data => {
        setTimeout(
          () => {
            setPublications(data);
            setPublicationsLoading(false);
          },
          1000
        );
      })
      .catch(error => {
        setPublicationsError(error);
        setPublicationsLoading(false);
      })
  }

  useEffect(() => {
    const subspacePda = getSubspacePda(id);
    // console.log('subspacePda :>> ', subspacePda);
    if (subspacePda) {
      setSubspacePublicKey(subspacePda);
    } else {
      setSubspacePublicKeyError('Subspace Public key error');
    }
  }, [id]);


  useEffect(() => {
    // console.log('subspacePublicKey', subspacePublicKey)
    if (subspacePublicKey) {
      fetchSubspace();
      fetchPublications();
    }
  }, [subspacePublicKey]);


  return (
    <>

      {/* {subspaceLoading &&
        // <Loader />
        <SubspaceHeaderSkeleton />
      } */}

      {/* {subspacePublicKeyError &&
        <ErrorComponent str="Subspace address error"/>
      } */}

      {(subspaceError || subspacePublicKeyError) &&
        <ErrorComponent str="Subspace not found" />
      }

      {!subspacePublicKeyError && !subspaceError && subspace &&
        <>
          <SubspaceHeader subspace={subspace} subspaceLoading={subspaceLoading} />

          {(subspace.creator.toString() === profile?.address.toString()) &&
            <PublicationCreateCard
              subspaceAddress={subspace.address}
              avatar={subspace.metadata?.image}
            />
          }

          {/* Progress Loader */}
          {publicationsLoading &&
            // <Loader />
            [...Array(3)].map((e, i) =>
              <PublicationCardSkeleton />
            )
          }

          {/* Publications list */}
          {publications && publications.length > 0 &&
            publications.map((publication) =>
              <PublicationCard
                publication={publication}
                publisher={subspace}
                key={publication.address.toString()}
              />
            )
          }

          {/* Empty */}
          {publications && publications.length === 0 &&
            <Card>
              <Box
                minHeight={'50vh'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                color={theme => theme.palette.divider}
              >
                no items
              </Box>
            </Card>
          }

          {/* If smth went wrong */}
          {publicationsError &&
            <ErrorComponent />
          }

        </>
      }

    </>
  )

}