import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material';


interface LoaderProps {
  isLoading?: boolean,
  loaderType?: 'linear' | 'circular',
  height?: string
}

export function Loader({ isLoading=false, loaderType='circular', height='inherit' }: LoaderProps) {

  // const { loaderType, isLoading } = props;

  return (
    <>
      <Box
        display={'flex'}
        height={height}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{}}
      >
        <CircularProgress />
      </Box>
    </>
  )
}
