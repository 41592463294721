import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import WelcomePage from './pages/WelcomePage';
import SettingsPage from './pages/SettingsPage';
import ErrorPage from './pages/ErrorPage';
import ProfilePage from './pages/ProfilePage';
import { AppProvider } from './contexts/main';
import { WalletContext } from './contexts/wallet';
import FriendsPage from './pages/ConnectionsPage';

// fixes the "Buffer is not defined" error
import * as buffer from "buffer";
import MessengerPage from './pages/MessengerPage';
import ConversationPage from './pages/ConversationPage';
import ConnectionsPage from './pages/ConnectionsPage';
import PublicationPage from './pages/PublicationPage';
import FeedPage from './pages/FeedPage';
import ImagesPage from './pages/ImagesPage';
import VideosPage from './pages/VideosPage';
import MusicPage from './pages/MusicPage';
import BookmarksPage from './pages/BookmarksPage';
import SubspaceCreatePage from './pages/SubspaceCreatePage';
import SubspacePage from './pages/SubspacePage';
import SubspacesByProfilePage from './pages/SubspacesByProfilePage';
import SubspaceUpdatePage from './pages/SubspaceUpdatePage';
import SuggestionsPage from './pages/SuggestionsPage';
import NotificationsPage from './pages/NotificationsPage';
declare const window: Window &
  typeof globalThis & {
    Buffer: any
  }
window.Buffer = buffer.Buffer;

const router = createBrowserRouter([
  {
    path: "welcome",
    element: <WelcomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <FeedPage /> },
      {
        path: "feed/",
        element: <FeedPage />,
      },
      {
        path: "notifications/",
        element: <NotificationsPage />,
      },
      {
        path: ":id",
        element: <ProfilePage />,
      },
      {
        path: ":id/connections",
        element: <FriendsPage />,
      },
      {
        path: ":id/:publicationId",
        element: <PublicationPage />,
      },
      {
        path: "pubs/:id",
        element: <PublicationPage />,
      },
      {
        path: "messenger/",
        element: <MessengerPage />,
      },
      {
        path: "messenger/:id",
        element: <ConversationPage />,
      },
      {
        path: ":id/connections/",
        element: <ConnectionsPage />,
      },
      {
        path: ":id/communities/",
        element: <SubspacesByProfilePage />,
      },
      // {
      //   path: ":id/images/",
      //   element: <ImagesPage />,
      // },
      // {
      //   path: ":id/video/",
      //   element: <VideosPage />,
      // },
      // {
      //   path: ":id/music/",
      //   element: <MusicPage />,
      // },
      {
        path: "suggestions/",
        element: <SuggestionsPage />,
      },
      {
        path: "bookmarks/",
        element: <BookmarksPage />,
      },
      {
        path: "settings/",
        element: <SettingsPage />,
      },
      {
        path: "communities/:id",
        element: <SubspacePage />,
      },
      {
        path: "communities/create",
        element: <SubspaceCreatePage />,
      },
      {
        path: "communities/:id/edit",
        element: <SubspaceUpdatePage />,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <WalletContext>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </WalletContext>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
