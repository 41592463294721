import React, { useContext, useEffect, useState } from 'react'
import { useParams, useLocation, useSearchParams, NavLink } from "react-router-dom";
import { useProfileConnectionInitializers } from '../hooks';
import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, IconButton, Link, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GroupIcon from '@mui/icons-material/Group';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ChatIcon from '@mui/icons-material/Chat';
import { Profile, PublicKey } from '@ju-protocol/sdk';
import { AppContext } from '../contexts/main';
import { ErrorComponent } from '../components/common';
import { getProfilePda } from '../utils/helpers';
import { MoreVert } from '@mui/icons-material';


interface ConnectionListItemProps {
  profile: Profile
}
const ConnectionListItem: React.FC<ConnectionListItemProps> = ({ profile }) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleButtonMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>

      <Divider variant="middle" light />

      <ListItem
        sx={{ my: 1.5 }}
        // disableGutters
        alignItems="flex-start"
        secondaryAction={
          <IconButton
            aria-label="menu"
            onClick={handleButtonMenuClick}
          >
            <MoreHorizIcon color='secondary' fontSize='small' />
          </IconButton>
        }
      >
        <ListItemAvatar sx={{ minWidth: 70 }}>
          <>
            <Avatar
              component={NavLink}
              to={`/${profile.address.toString()}`}
              alt={`/${profile.address.toString()}`}
              src={profile.metadata?.image}
              sx={{
                mt: 0.5,
                mr: 1,
                bgcolor: theme => `${theme.palette.primary.main}`
              }}
              aria-label="recipe"
            >
              {profile.alias ? profile.alias.charAt(0).toUpperCase() : profile.address.toString().charAt(0).toUpperCase()}
            </Avatar>
          </>
        </ListItemAvatar>

        <Link
          component={NavLink}
          to={`/${profile.address.toString()}`}
        >
          <ListItemText
            sx={{ maxWidth: 300 }}

            primary={
              <Typography
                noWrap
              >
                {`${profile.firstName} ${profile.lastName}`}
              </Typography>
            }
            secondary={`@${profile.alias}`}
          />
        </Link>

      </ListItem>

      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >

        <MenuItem onClick={handleMenuClose}>
          <Link
            component={NavLink}
            to={`/${profile.address.toString()}`}
            color={'secondary'}
            display={'flex'}
            alignItems={'center'}
          >
            <ListItemIcon>
              <PersonOutlineIcon fontSize="inherit" />
            </ListItemIcon>

            View Profile
          </Link>
        </MenuItem>

        <MenuItem onClick={handleMenuClose}>
          <Link
            component={NavLink}
            to="/messenger"
            color={'secondary'}
            display={'flex'}
            alignItems={'center'}
          >
            <ListItemIcon>
              <ChatIcon fontSize="inherit" />
            </ListItemIcon>
            Message
          </Link>
        </MenuItem>

      </Menu>

    </>
  )
}

const ConnectionListItemSkeleton: React.FC = () => {
  return (
    <>
      <ListItem
        // disableGutters
        sx={{ my: 1.5 }}

        secondaryAction={
          <Skeleton width={'20px'} height={'20px'} sx={{ mr: 2 }} />
        }
      >
        <ListItemAvatar sx={{ minWidth: 70 }}>
          <Skeleton width={'56px'} height={'56px'} variant="circular" />
        </ListItemAvatar>

        <ListItemText
          primary={<Skeleton width={'40%'} animation='wave' />}
          secondary={<Skeleton width={'30%'} animation='wave' />}
        />

      </ListItem>

      <Divider variant="middle" light />
    </>
  )
}


export default function ConnectionsPage() {


  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type") || "followed"

  const [profilePublicKey, setProfilePublicKey] = useState<PublicKey>();
  const [profilePublicKeyError, setProfilePublicKeyError] = useState<string | null>(null);

  const [profile, setProfile] = useState<Profile>();
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileError, setProfileError] = useState<Error | null>(null);

  const [items, setItems] = useState<Profile[] | undefined>(undefined);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsError, setItemsError] = useState<Error | null>(null);

  const { profileClient } = useContext(AppContext);


  const fetchProfile = () => {

    setProfileLoading(true);
    setProfileError(null);

    profileClient().getProfile(profilePublicKey!)
      .then(data => {
        setProfile(data);
      })
      .catch(error => {
        setProfileError(error)
      })
      .finally(() => {
        setProfileLoading(false);
      })

  }

  // Fetching Followers (initializes)
  const fetchConnectionInitializers = () => {

    setItemsLoading(true);
    setItemsError(null);

    // First get a items keys array
    profileClient().findProfilesAsKeysByConnectionInitializer(
      {
        initializer: profilePublicKey!
      }
    )
      .then(keys => {

        // Get Profile[] by keylist
        profileClient().getProfilesByKeyList({ keys })
          .then(items => {
            setTimeout(
              () => {
                setItems(items);
                setItemsLoading(false);
              },
              1000
            );
          })
          .catch(error => {
            // console.log(error);
            setItemsError(error);
            setItemsLoading(false);
          })

      })
      .catch(error => {
        setItemsError(error);
        setItemsLoading(false);
      })
      // .finally(() => {
      //   setItemsLoading(false);
      // })
  }


  // Fetching Followers
  const fetchConnectionTargets = () => {

    setItemsLoading(true);
    setItemsError(null);

    // First get a items keys array
    profileClient().findProfilesAsKeysByConnectionTarget(
      {
        target: profilePublicKey!
      }
    )
      .then(keys => {

        // Get Profile[] by keylist
        profileClient().getProfilesByKeyList({ keys })
          .then(items => {
            setTimeout(
              () => {
                setItems(items);
                setItemsLoading(false);
              },
              1000
            );
          })
          .catch(error => {
            // console.log(error);
            setItemsError(error);
            setItemsLoading(false);
          })

      })
      .catch(error => {
        setItemsError(error);
        setItemsLoading(false);
      })
    // .finally(() => {
    //   setItemsLoading(false);
    // })
  }


  useEffect(() => {
    const profilePda = getProfilePda(id);
    if (profilePda) {
      setProfilePublicKey(profilePda);
    } else {
      setProfilePublicKeyError('Profile Public key error');
    }
  }, []);


  useEffect(() => {
    // console.log('profilePublicKey >>', profilePublicKey)
    if (profilePublicKey) {
      fetchProfile();

      if (type === "followed") {
        fetchConnectionInitializers();
      }

      if (type === "followers") {
        fetchConnectionTargets();
      }
    }
  }, [profilePublicKey, type]);


  return (
    <>

      <Card
        sx={{ minHeight: '90vh' }}
      >

        {profilePublicKeyError &&
          <ErrorComponent str="Profile address error" />
        }

        {profileError &&
          <ErrorComponent str="Profile not found" />
        }

        {!profilePublicKeyError && !profileError && profile &&
          <>
            <CardHeader
              // disableTypography
              sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
              avatar={
                <>
                  <Avatar
                    component={NavLink}
                    to={`/${profile.address.toString()}`}
                    alt={`/${profile.address.toString()}`}
                    src={profile.metadata?.image}
                    sx={{
                      mt: 0.5,
                      mr: 1,
                      bgcolor: theme => `${theme.palette.primary.main}`,
                      border: theme => `2px solid #ccc`
                    }}
                    aria-label="recipe"
                  >
                    {profile.alias ? profile.alias.charAt(0).toUpperCase() : profile.address.toString().charAt(0).toUpperCase()}
                  </Avatar>
                </>
              }
              title={
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={1}
                  textTransform={'uppercase'}
                >
                  Profile Connections <GroupIcon fontSize='small' color='secondary' />
                </Box>
              }
              // subheader={
              //   <Link component={NavLink} to="/nuclearblock">
              //     <Typography variant='caption'>
              //       John Doe
              //     </Typography>
              //   </Link>
              // }

              action={
                <IconButton
                  sx={{ mt: 1.5, mr: 1 }}
                >
                  <MoreVert fontSize='small' />
                </IconButton>
              }
            />

            <CardContent>

              <Box>
                <Button
                  component={NavLink}
                  to="?type=followed"
                  color={type === 'followed' ? 'secondary' : 'primary'}
                  variant={'text'}
                  size='small'
                >
                  Followed
                </Button>

                <Button
                  component={NavLink}
                  to="?type=followers"
                  color={type === 'followers' ? 'secondary' : 'primary'}
                  variant={'text'}
                  size='small'
                >
                  Followers
                </Button>
              </Box>

              <List>
                {itemsLoading && !itemsError &&
                  [...Array(10)].map((e, i) =>
                    <ConnectionListItemSkeleton />
                  )
                }
                {items && items.length > 0 &&
                  items.map(item => (
                    <ConnectionListItem profile={item} />
                  ))}
              </List>
            </CardContent>
          </>
        }
      </Card>

    </>

  )
}
