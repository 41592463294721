import React, { useContext, useEffect, useState } from 'react'
import { useParams, NavLink } from "react-router-dom";
import { Avatar, Box, Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { ErrorComponent, Loader } from '../components/common';
import { PublicKey } from '@solana/web3.js';
import { AppContext } from '../contexts/main';
import { getProfilePda } from '../utils/helpers';
import { Bookmark, MoreVert, MusicNote, PhotoLibrary, Videocam } from '@mui/icons-material';

export default function BookmarksPage() {


  const { profile } = useContext(AppContext);


  const [items, setItems] = useState<string[]>();
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsError, setItemsError] = useState<Error | null>(null);

  const fetchData = () => {
    setItemsLoading(true);
    setItemsError(null);

    // TODO
  }

  return (
    <>
      <Card
        sx={{ minHeight: '90vh' }}
      >
        <CardHeader
          // disableTypography
          sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
          avatar={
            <Avatar
              component={NavLink}
              to={`/${profile?.address}`}
              alt={`/${profile?.address}`}
              src={profile?.metadata?.image}
              sx={{
                mt: 0.5,
                mr: 1,
                bgcolor: theme => `${theme.palette.primary.main}`,
                border: theme => `2px solid #ccc`
              }}
              aria-label="recipe"
            >
              {profile?.firstName ? profile.firstName.charAt(0) : profile?.address.toString().charAt(0)}
            </Avatar>
          }
          title={
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={1}
              textTransform={'uppercase'}
            >
              My Bookmarks <Bookmark fontSize='small' color='secondary' />
            </Box>
          }
          // subheader={
          //   <Link component={NavLink} to="/nuclearblock">
          //     <Typography variant='caption'>
          //       John Doe
          //     </Typography>
          //   </Link>
          // }

          action={
            <IconButton
              sx={{ mt: 1.5, mr: 1 }}
            >
              <MoreVert fontSize='small' />
            </IconButton>
          }
        />

        <CardContent
          sx={{ minHeight: '100%' }}
        >

        </CardContent>

      </Card>
    </>
  )
}
