import React, { useContext, useEffect, useState } from "react"
import { useParams, useLocation, NavLink } from "react-router-dom";
import { ErrorComponent, Loader } from "../components/common";
import { AppContext } from '../contexts/main';
import PublicationCreateCard from "../components/Publications/PublicationCreateCard";
import PublicationCard, { PublicationCardSkeleton } from "../components/Publications/PublicationCard";
import ProfileHeader, { ProfileHeaderSkeleton } from "../components/profile/ProfileHeader";
import { PublicKey } from "@solana/web3.js";
import { Profile, Publication } from "@ju-protocol/sdk";
import { getProfilePda } from "../utils/helpers";
import { Box, Card } from "@mui/material";


export default function ProfilePage() {

  const { id } = useParams();

  const [profilePublicKey, setProfilePublicKey] = useState<PublicKey>();
  const [profilePublicKeyError, setProfilePublicKeyError] = useState<string | null>(null);

  const { profileClient, publicationClient } = useContext(AppContext);


  const [profile, setProfile] = useState<Profile>();
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileError, setProfileError] = useState<Error | null>(null);

  const fetchProfile = () => {
    setProfileLoading(true);
    setProfileError(null);

    profileClient().getProfile(profilePublicKey!)
      .then(data => {
        setProfile(data);
      })
      .catch(error => {
        setProfileError(error)
      })
      .finally(() => {
        setProfileLoading(false);
      })
  }


  const [publications, setPublications] = useState<Publication[]>();
  const [publicationsLoading, setPublicationsLoading] = useState(false);
  const [publicationsError, setPublicationsError] = useState<Error | null>(null);

  const fetchPublications = () => {
    setPublicationsLoading(true);
    setPublicationsError(null);

    publicationClient().findPublications(
      {
        // isEncrypted: false,
        profile: profilePublicKey,
        isReply: false,
        subspace: false,
        loadJsonMetadata: true
      }
    )
      .then(data => {
        // console.log('data >> ', data)
        setTimeout(
          () => {
            setPublications(data);
            setPublicationsLoading(false);
          },
          1000
        );
      })
      .catch(error => {
        setPublicationsError(error);
        setPublicationsLoading(false);
      })
  }

  useEffect(() => {
    const profilePda = getProfilePda(id);
    // console.log('profilePda :>> ', profilePda);
    if (profilePda) {
      setProfilePublicKey(profilePda);
    } else {
      setProfilePublicKeyError('Profile Public key error');
    }
  }, [id]);

  useEffect(() => {
    if (profilePublicKey) {
      fetchProfile();
      fetchPublications();
    }
  }, [profilePublicKey]);


  return (
    <>

      {profileLoading &&
        // <Loader />
        <ProfileHeaderSkeleton />
      }

      {(profileError || profilePublicKeyError) &&
        <Card sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh'
        }}>
          <ErrorComponent str="Profile not found" />
        </Card>
      }

      {!profilePublicKeyError && !profileError && profile &&
        <>
          <ProfileHeader profile={profile} profileLoading={profileLoading} />

          <PublicationCreateCard
            avatar={profile.metadata?.image}
          />

          {/* Progress Loader */}
          {publicationsLoading &&
            // <Loader />
            [...Array(3)].map((e, i) =>
              <PublicationCardSkeleton />
            )
          }

          {/* Publications list */}
          {publications && publications.length > 0 &&
            publications.map((publication) =>
              <PublicationCard
                publication={publication}
                publisher={profile}
                key={publication.address.toString()}
              />
            )
          }

          {/* Empty */}
          {publications && publications.length === 0 &&
            <Card>
              <Box
                minHeight={'40vh'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                color={theme => theme.palette.divider}
              >
                no items
              </Box>
            </Card>
          }

          {/* If smth went wrong */}
          {publicationsError &&
            <ErrorComponent />
          }

        </>
      }

    </>
  )

}