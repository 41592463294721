import React, { useContext, useEffect, useState } from 'react'
import { useParams, NavLink } from "react-router-dom";
import { Avatar, Box, Card, CardContent, CardHeader, IconButton, List, ListItem } from '@mui/material';
import { ErrorComponent, Loader } from '../components/common';
import { PublicKey } from '@solana/web3.js';
import { AppContext } from '../contexts/main';
import { getProfilePda } from '../utils/helpers';
import { MoreVert, MusicNote, PhotoLibrary, TroubleshootOutlined } from '@mui/icons-material';
import { Profile } from '@ju-protocol/sdk';


export default function NotificationsPage() {

  const { profile, profileClient } = useContext(AppContext);

  const [error, setError] = useState<string | null>(null);

  const [connectionInitializers, setConnectionInitializers] = useState<Profile[]>([]);
  const [connectionInitializersLoading, setConnectionInitializersLoading] = useState(false);
  const [connectionInitializersError, setConnectionInitializersError] = useState<Error | null>(null);
 
  const [connectionInitializersKeys, setConnectionInitializersKeys] = useState<PublicKey[]>([]);
  const [connectionInitializersKeysLoading, setConnectionInitializersKeysLoading] = useState(false);
  const [connectionInitializersKeysError, setConnectionInitializersKeysError] = useState<Error | null>(null);

  const fetchConnectionInitializersKeys = () => {
    setConnectionInitializersKeysLoading(true);
    setConnectionInitializersKeysError(null);

    profileClient().findProfilesAsKeysByConnectionTarget(
      {
        target: profile?.address!,
        isIn3Days: true
      }
    )
      .then(data => setConnectionInitializersKeys(data))
      .catch(error => {
        setConnectionInitializersKeysError(error);
      })
      .finally(() => {
        setConnectionInitializersKeysLoading(false);
      })
  }

  useEffect(() => {
    if (profile) {
      fetchConnectionInitializersKeys();
    }
  }, [profile]);


  return (
    <>
      <Card
        sx={{ minHeight: '90vh' }}
      >
        <CardHeader
          // disableTypography
          sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
          avatar={
            <Avatar
              component={NavLink}
              to={`/${profile?.address}`}
              alt={`/${profile?.address}`}
              src={profile?.metadata?.image}
              sx={{
                mt: 0.5,
                mr: 1,
                bgcolor: theme => `${theme.palette.primary.main}`,
                border: theme => `2px solid #ccc`
              }}
              aria-label="recipe"
            >
              {profile?.firstName ? profile.firstName.charAt(0) : profile?.address.toString().charAt(0)}
            </Avatar>
          }
          title={
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={1}
              textTransform={'uppercase'}
            >
              Notifications <MusicNote fontSize='small' color='secondary' />
            </Box>
          }
          // subheader={
          //   <Link component={NavLink} to="/nuclearblock">
          //     <Typography variant='caption'>
          //       John Doe
          //     </Typography>
          //   </Link>
          // }

          action={
            <IconButton
              sx={{ mt: 1.5, mr: 1 }}
            >
              <MoreVert fontSize='small' />
            </IconButton>
          }
        />

        <CardContent
          sx={{ minHeight: '100%' }}
        >

          {connectionInitializers && connectionInitializers.length > 0 && (
            <List>
              {connectionInitializers.map((item, index) => (
                <ListItem >
                  {/* <audio src={item.uri} controls style={{ width: '100%' }} /> */}
                </ListItem>
              ))}
            </List>
          )}

        </CardContent>

      </Card>
    </>
  )
}
