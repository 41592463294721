import React, { useEffect, useState, useContext } from 'react'
import { NavLink } from "react-router-dom";
import { Profile, PublicKey } from '@ju-protocol/sdk'
import { usePublication } from '../../hooks';
import { Avatar, Box, Button, Card, CardHeader, CardMedia, Dialog, DialogTitle, IconButton, ImageList, ImageListItem, Link, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography, useTheme } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { AppContext } from '../../contexts/main';
import { MoreVert } from '@mui/icons-material';


interface PublicationReplyCardProps {
  publicationAddress: PublicKey;
}

export default function PublicationReplyCard({ publicationAddress }: PublicationReplyCardProps) {

  // console.log('publicationAddress', publicationAddress)

  const { profile: myProfile, profileClient } = useContext(AppContext);

  const { publication, publicationLoading, publicationError } = usePublication(publicationAddress);

  const [profile, setProfile] = useState<Profile>();
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileError, setProfileError] = useState<Error | null>(null);

  const fetchProfile = (address: PublicKey) => {
    setProfileLoading(true);
    setProfileError(null);

    profileClient().getProfile(address)
      .then(data => {
        setProfile(data);
      })
      .catch(error => {
        setProfileError(error)
      })
      .finally(() => {
        setProfileLoading(false);
      })
  }

  useEffect(() => {
    if (publication) {
      fetchProfile(publication.profile);
    }
  }, [publication]);

  return (

    <>
      {publication &&
        <Card sx={{ mb: 0, boxShadow: 'none' }} >
          <CardHeader
            sx={{ alignItems: 'flex-start' }}
            avatar={
              <Avatar
                component={NavLink}
                to={`/${publication?.profile.toString()}`}

                src={profile?.metadata?.image}

                sx={{
                  mt: 0.5,
                  height: '30px',
                  width: '30px',
                  bgcolor: theme => `${theme.palette.primary.main}`
                }}
                aria-label="publication-reply-author"
              >
                {profile ? profile.alias?.charAt(0) : ''}
              </Avatar>
            }

            action={
              <IconButton aria-label="settings">
                <MoreVert fontSize='small' />
              </IconButton>
            }

            title={
              <Link
                component={NavLink}
                to={`/${publication?.profile.toString()}`}
                sx={{color: theme => theme.palette.primary.dark}}
              >
                {profile ? (profile.firstName ? `${profile.firstName} ${profile.lastName}` : ` @${profile.alias}`) : publication.profile.toString()}
              </Link>
            }
            subheader={<>

              {publication.metadata?.content?.value}

              {/* <Box
                display={'flex'}
                justifyContent='space-between'
              // fontSize={'0.7rem'}
              >
                <Link
                  href='#'
                  color={'secondary'}
                  underline='hover'
                  variant='caption'
                >
                  reply
                </Link>

                <Link
                  href='#'
                  color={'secondary'}
                  variant='caption'
                >
                  <FavoriteBorderIcon fontSize='inherit' sx={{ mb: -0.1 }} /> 1
                </Link>
              </Box> */}

            </>
            }

            subheaderTypographyProps={{
              sx: {
                display: 'inline-block',
                width: '70ch',
                wordBreak: 'break-all'
              }
            }}
          />

          {/* <CardContent>

        {itemData[selectedValue] &&
          <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{itemData[selectedValue].title}</DialogTitle>
            <img
              src={itemData[selectedValue].img}
              // alt={item.title}
              loading="lazy"
            />
          </Dialog>
        }

      </CardContent> */}

        </Card>
      }
    </>
  )
}
