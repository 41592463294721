import { OpenInNew } from "@mui/icons-material";
import { Box, Button, Container, LinearProgress, Link, Typography, useTheme } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../contexts/main";
import ProfileCompleteness from "./ProfileCompleteness";
import SideMenu from "./SideMenu";


export default function LeftColumn() {

  const { isMobileMenuOpen, profile } = useContext(AppContext);

  const theme = useTheme();
  // useEffect(() => {
    
  // }, [isMobileMenuOpen])
  

  return (
    <Box
      component='aside'
      flexDirection={'column'}
      justifyContent={'space-between'}
      minHeight={'85vh'}
      sx={{
        position: { xs:'fixed', sm: 'sticky' },
        pl: { xs: '10px', sm: 0 },
        top: { xs: 63, sm: 65 },
        boxShadow: { xs: 4, sm: 0 },
        left: { xs: 0, sm: undefined },
        bottom: { xs: 0, sm: undefined },
        width: { xs: '60%', sm: '100%' },
        bgcolor: { xs: theme.palette.background.paper, sm: 'transparent' },
        display: { xs: isMobileMenuOpen ? 'block' : 'none', sm: 'flex' },
        opacity: { xs: isMobileMenuOpen ? 1 : 0, sm: 1 },
        // transition: { xs: 'all 2s linear', sm: undefined },
        animation: { xs: `showNav 250ms ease-in-out both`, sm: undefined },
        zIndex: { xs: 1, sm: undefined },
      }}
    >
      <SideMenu />

      {profile
        ?
        <ProfileCompleteness />
        :
        <>
          <Button
            variant="outlined"
            size="small"
            sx={{ mx: 1 }}
          >
            Create Profile
          </Button>
        </>
      }
    </Box>
  )
}