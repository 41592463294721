import { useContext } from 'react';
import { NavLink } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Button, Container, Grid, Link } from '@mui/material';
import { AppContext } from '../../contexts/main';
import Notifications from './Notifications';
import Search from './Search';
import UserMenu from './UserMenu';
import { Menu, PersonAdd } from '@mui/icons-material';


export default function Header() {

  const { handleMobileMenu, profile } = useContext(AppContext);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        color='primary'
        enableColorOnDark
        sx={{
          backgroundImage: 'linear-gradient(135deg, rgb(79, 82, 97) 0%, rgb(56, 58, 69) 50%)',
          py: 0.5
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            disableGutters
            variant='dense'
          >

            <Grid container spacing={1}>

              <Grid 
              item 
              sm={2}
              // display={'flex'}
              m={'auto 0'}
              >
                <Link
                  component={NavLink}
                  to="/"
                  sx={{ ml: 1.5, flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
                >
                  <img alt='Feel3 Space' src='/logo.png' width={'80px'} />
                </Link>

                <Button
                  // startIcon={}
                  size='small'
                  color={'secondary'}
                  variant={'contained'}
                  sx={{
                    px: 0.5,
                    minWidth: 40,
                    // bgcolor: '#fff',
                    display: { xs: 'flex', sm: 'none' }
                  }}

                  onClick={handleMobileMenu}
                >
                  <Menu />
                </Button>
              </Grid>

              <Grid
                item 
                xs={7} sm={5}
                display={'flex'}
                // justifyContent={'flex-end'}
                alignItems={'center'}
                gap={1}
              >
                <Search />

                {/* <Tooltip title="Magic query">
                  <IconButton
                    onClick={() => { }}
                  >
                    <AutoFixHigh fontSize='medium' sx={{ color: '#eee' }} />
                  </IconButton>
                </Tooltip> */}

              </Grid>

              <Grid
                item
                xs={3} sm={5}
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                gap={3}
              >
                {!profile
                  ?
                  <Box
                    fontSize={'0.9rem'}
                  >
                    <Button
                      component={NavLink}
                      to='/welcome'
                      variant='text'
                      startIcon={<PersonAdd sx={{ color: '#fff' }} />}
                      color={'secondary'}
                      size='small'
                    >
                      Sign up
                    </Button>
                  </Box>
                  :
                  <>
                    <Notifications />
                    <UserMenu />
                  </>
                }
              </Grid>

            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
}