import React, { useEffect, useState, useContext } from 'react'
import { NavLink } from "react-router-dom";
import { Avatar, Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, IconButton, Input, InputAdornment, InputLabel, Link, MenuItem, Select, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import { FileContent, useFilePicker } from 'use-file-picker';
import { Amount, BigNumberValues, BundlrStorageDriver, Currency, JuFile, ProfileJsonMetadata, toJuFileFromBrowser } from '@ju-protocol/sdk';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useProfile } from '../hooks';
import { AppContext } from '../contexts/main';
import { ErrorComponent, Loader } from '../components/common';
import { enqueueSnackbar } from 'notistack';
import { SelectChangeEvent } from '@mui/material/Select';
import dayjs, { Dayjs } from 'dayjs';
import countries from '../libs/countries.json'
import SubmitDialog from '../components/SubmitDialog';
import { Check, Warning, Save, Settings, DriveFileRenameOutline, AlternateEmail } from '@mui/icons-material';
import { DataURIToByteString } from '../utils/helpers';


interface ImageAttachment {
  juFile?: JuFile;
  uri?: string;
}

interface Country {
  flag: any;
  title: string;
  code: number;
  iso2: string;
  iso3: string;
}

interface City {
  title: string;
  code: number;
  iso2: string;
  iso3: string;
}

interface Location {
  latitude: number;
  longitude: number;
  description: string;
}

interface SettingsItemProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
}

// BigIntSupport extends dayjs(), dayjs.unix APIs to support BigInt argument.
// dayjs.extend(bigIntSupport);

const SettingsItem: React.FC<SettingsItemProps> = ({ title, subtitle, children }) => {

  return (
    <Box
      mx={1}
      minHeight={'75px'}
      display={'flex'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
    >
      <Box mt={0.3}>
        <Typography
          variant='body1'
        >
          {title}
        </Typography>

        {subtitle &&
          <Typography
            variant='subtitle2'
            sx={{
              mt: -0.5,
              color: theme => theme.palette.divider
            }}
          >
            {subtitle}
          </Typography>
        }
      </Box>

      <Box
        minWidth={'60%'}
      >
        {children}
      </Box>

    </Box>
  );
}

export default function SettingstPage() {

  const { APP, ju, profile, profileClient, setProfile } = useContext(AppContext);

  const [currentAlias, setCurrentAlias] = useState<string | null>(null);

  const [alias, setAlias] = useState<string | null>(null);
  const [aliasError, setAliasError] = useState<string | null>(null);

  const [isCheckingAlias, setIsCheckingAlias] = useState(false);
  const [isAliasAvailable, setIsAliasAvailable] = useState<boolean | undefined>(undefined);

  const [firstName, setFirstname] = useState<string | undefined>(undefined);
  const [firstNameError, setFirstnameError] = useState<string | null>(null);

  const [lastName, setLastname] = useState<string | undefined>(undefined);
  const [lastNameError, setLastnameError] = useState<string | null>(null);

  const [birthday, setBirthday] = useState<BigNumberValues | null>(null);
  const [birthdayError, setBirthdayError] = useState<string | null>(null);

  // const [country, setCountry] = useState<Country | null>(null);
  // const [countryError, setCountryError] = useState<string | null>(null);

  const [countryCode, setCountryCode] = useState<number>(0);
  const [countryCodeError, setCountryCodeError] = useState<string | null>(null);

  const [city, setCity] = useState<City | null>(null);
  const [cityError, setCityError] = useState<string | null>(null);

  const [currentLocation, setCurrentLocation] = useState<number | null>(null);
  const [currentLocationError, setCurrentLocationError] = useState<string | null>(null);

  const [statusText, setStatusText] = useState<string | undefined>(undefined);
  const [statusTextError, setStatusTextError] = useState<string | null>(null);

  const [bio, setBio] = useState<string | undefined>(undefined);
  const [bioError, setBioError] = useState<string | null>(null);

  const [avatar, setAvatar] = useState<ImageAttachment | undefined>(undefined);
  const [avatarError, setAvatarError] = useState<string | null>(null);

  const [imageCover, setImageCover] = useState<ImageAttachment | undefined>(undefined);
  const [imageCoverError, setImageCoverError] = useState<string | null>(null);

  const [location, setLocation] = useState<Location | undefined>();

  const [isLoading, setIsLoading] = React.useState(false);

  const [isValidationError, setIsValidationError] = React.useState(false);

  const [countryList, setCountryList] = useState<Country[]>([])
  const [stateList, setStateList] = useState<Country[]>([])
  const [cityList, setCityList] = useState<City[]>([])

  const [uploadPrice, setUploadPrice] = useState<Amount<Currency>>();
  const [isUploadPriceFetching, setIsUploadPriceFetching] = useState(false);
  const [isUploadPriceError, setIsUploadPriceError] = useState<Error | null>(null);

  const submitSteps: string[] = [
    "Upload metadata to decentralized storage",
    "Save data into Solana blockchain"
  ];
  const [activeStep, setActiveStep] = useState<number>(0);
  const [submitCompleted, setSubmitCompleted] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const [openAvatarSelector, avatarPickerHelper] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    // minFileSize: 0.1, // in megabytes
    // maxFileSize: 50,
    // imageSizeRestrictions: {
    //   maxHeight: 900, // in pixels
    //   maxWidth: 1600,
    //   minHeight: 600,
    //   minWidth: 768,
    // },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
      console.log('plainFiles', plainFiles)
      toJuFileFromBrowser(plainFiles[0])
        .then(file => setAvatar(
          {
            juFile: file,
            uri: filesContent[0].content
          }
        ))
    },
  });

  const [openCoverImageSelector, imageCoverPickerHelper] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    // minFileSize: 0.1, // in megabytes
    // maxFileSize: 50,
    // imageSizeRestrictions: {
    //   maxHeight: 900, // in pixels
    //   maxWidth: 1600,
    //   minHeight: 600,
    //   minWidth: 768,
    // },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
      toJuFileFromBrowser(plainFiles[0])
        .then(file => setImageCover(
          {
            juFile: file,
            uri: filesContent[0].content
          }
        ))
    },
  });


  const aliasChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;

    setIsAliasAvailable(undefined);

    setAlias(value);
  }

  // Alias availability
  const checkAlias = async () => {
    if (alias && (alias !== profile!.alias) && !(alias.length < 4 || alias.length > 30)) {
      setIsAliasAvailable(undefined);
      setIsCheckingAlias(true);

      // console.log(`checking alias ${alias} ...`)

      const aliasPda = ju.core().pdas().alias(
        {
          app: APP,
          alias
        }
      );

      try {
        const isExist = await ju.rpc().accountExists(aliasPda);

        if (isExist) {
          setAliasError(`@${alias} is unavailable`)

          setIsAliasAvailable(false);
          setIsCheckingAlias(false);

          setIsValidationError(true);
        } else {
          setIsAliasAvailable(true);
          setIsCheckingAlias(false);

          setIsValidationError(false);
        }
      } catch (error) {
        setAliasError(`Unable to check @${alias} alias`);

        setIsAliasAvailable(false);
        setIsCheckingAlias(false);

        setIsValidationError(true);
      }
    }
  }


  const validateAlias = () => {
    let isError = false;

    if (alias && (alias.length < 4 || alias.length > 30)) {
      isError = true;
      setAliasError('Alias must be between 3 and 10 characters!');
    } else {
      isError = false;
      setAliasError(null);
    }

    return isError;
  }


  const validateFirstname = () => {
    let isError = false;

    if (firstName && firstName.length > 20) {
      isError = true;
      setFirstnameError('Firstname must be between 2 and 20 characters!');
    } else {
      isError = false;
      setFirstnameError(null);
    }

    return isError;
  }

  const validateLastname = () => {
    let isError = false;

    if (lastName && lastName.length > 30) {
      isError = true;
      setLastnameError('Lastname must be between 2 and 30 characters!');
    } else {
      isError = false;
      setLastnameError(null);
    }

    return isError;
  }

  const validateStatusText = () => {
    let isError = false;

    if (statusText && statusText.length > 64) {
      isError = true;
      setStatusTextError('Status text must be no more than 64 characters');
    } else {
      isError = false;
      setStatusTextError(null);
    }

    return isError;
  }

  const validateBio = () => {
    let isError = false;

    if (bio && bio.length > 300) {
      isError = true;
      setBioError('Bio must be no more than 300 characters');
    } else {
      isError = false;
      setBioError(null);
    }

    return isError;
  }

  const validateBirthday = () => {
    let isError = false;

    if (birthday && (birthday > dayjs().unix() || birthday < dayjs().subtract(110, 'year').unix())) {
      isError = true;
      setBirthdayError('Birthday date is not correct');

    // } else if (birthday && (birthday < dayjs().subtract(14, 'year').unix())) {
    //   isError = true;
    //   setBirthdayError('User must be >= 14 years old');

    } else {
      isError = false;
      setBirthdayError(null);
    }

    return isError;
  }

  const validateAll = () => {
    let isError = false;

    if (validateAlias()) {
      isError = true;
    }

    if (validateFirstname()) {
      isError = true;
    }

    if (validateLastname()) {
      isError = true;
    }

    if (validateStatusText()) {
      isError = true;
    }

    if (validateBio()) {
      isError = true;
    }

    if (validateBirthday()) {
      isError = true;
    }

    setIsValidationError(isError);
  }


  const onCountryChange = (event: SelectChangeEvent) => {
    let code: number = 0;

    const value = event.target.value

    if (value !== '') {
      code = Number(value);
    }

    setCountryCode(code);
  }

  // const getCountries = () => {
  //   let headers = new Headers();
  //   headers.append("X-CSCAPI-KEY", "RmZWVWwzckwxVTBzbGNmTHF6dGNzSjFCRUhzcGlpd2FYSjI3T1Q4TQ==");

  //   const requestOptions: RequestInit = {
  //     method: 'GET',
  //     headers: headers,
  //     redirect: 'follow'
  //   };

  //   fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
  //     .then(response => response.json())
  //     .then(result => console.log(result))
  //     .catch(error => console.log('error', error));
  // }

  const getUploadPrice = () => {
    setIsUploadPriceFetching(true);

    let totalBytes = 0;
    if (avatar?.juFile && avatar.uri) {
      totalBytes = + DataURIToByteString(avatar.uri).length;
    }

    if (imageCover?.juFile && imageCover.uri) {
      totalBytes = + DataURIToByteString(imageCover.uri).length;
    }

    ju.storage()
      .getUploadPriceForBytes(totalBytes)
      .then(result => {
        // setUploadPrice(`${result.basisPoints / Math.pow(10, result.currency.decimals)} ${result.currency.symbol}`);
        setUploadPrice(result);
      })
      .catch(error => {
        setIsUploadPriceError(error);
      })
      .finally(() => setIsUploadPriceFetching(false))
  }

  const updateProfile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (uploadPrice) {

      setIsLoading(true);
      setActiveStep(0);

      // Upload metadata
      ju.storage().uploadMetadata<ProfileJsonMetadata<JuFile | string>>(
        {
          appId: profile?.app.toString(),
          profileId: profile?.address.toString(),
          image: avatar?.juFile ? avatar?.juFile : avatar?.uri,
          imageCover: imageCover?.juFile ? imageCover?.juFile : imageCover?.uri,
          bio,
          status: statusText
        }
      ).then(res => {

        setActiveStep(1);

        profileClient().updateProfile(
          profile!,
          {
            alias,
            firstName,
            lastName,
            metadataUri: res.uri,
            birthDate: birthday as number,
            countryCode: countryCode, //country?.code ? country.code : null,
            cityCode: city?.code,
          },
          true
        ).then(res => {
          enqueueSnackbar("Profile successfully updated!");

          setSubmitCompleted(true);

          setProfile(res.profile);
          setIsLoading(false);
        })
          .catch(error => {
            // Error handler here
            enqueueSnackbar("Profile update failed")

            setSubmitError(String(error));
            console.log('error 1', error)

            setIsLoading(false);
          })
      })
        .catch(error => {
          // Metadata upload error handler here
          enqueueSnackbar("Profile update failed")

          setSubmitError(String(error));
          console.log('error 2', error)

          setIsLoading(false);
        })
        .finally(() => {

        })
    }
  }


  useEffect(() => {
    if (profile) {
      // console.log('profile :>> ', JSON.stringify(profile, null ,4));

      setCurrentAlias(profile.alias);

      setAlias(profile.alias);
      setFirstname(profile.firstName);
      setLastname(profile.lastName);

      setAvatar(
        {
          juFile: undefined,
          uri: profile.metadata?.image
        }
      );
      setImageCover(
        {
          juFile: undefined,
          uri: profile.metadata?.imageCover
        }
      );

      setStatusText(profile.metadata?.status);
      setBio(profile.metadata?.bio);

      if (String(profile.birthDate) !== '-2208988800') {
        setBirthday(profile.birthDate);
      }

      setCountryCode(profile.countryCode);
    }
  }, [profile]);

  useEffect(() => {
    validateAll();
  }, [
    firstName,
    lastName,
    alias,
    birthday,
    countryCode,
    //country
    city,
    bio,
    statusText
  ]);



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      checkAlias();
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [alias])

  useEffect(() => {
    getUploadPrice();
  }, [avatar?.juFile, imageCover?.juFile])
  


  return (
    <>

      {false && //profileLoading &&
        <Card
          sx={{ minHeight: '90vh' }}
        >
          <CardHeader
            sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
            avatar={
              <Skeleton width={'40px'} height={'40px'} variant="circular" />
            }
            title={
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                textTransform={'uppercase'}
              >
                My Settings <Settings fontSize='small' color='secondary' />
              </Box>
            }
          />

          <CardContent

            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '80vh'
            }}
          >
            <Loader />
          </CardContent>

        </Card>
      }

      {false && //profileError &&
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '90vh'
          }}
        >
          <ErrorComponent />

        </Card>
      }

      {true && //profile && !profileLoading && !profileError &&
        <Card>
          <CardHeader
            // disableTypography
            sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
            avatar={
              <>
                <Avatar
                  component={NavLink}
                  to={`/${profile?.address.toString()}`}
                  alt={`/${profile?.address.toString()}`}
                  src={profile?.metadata?.image}
                  sx={{
                    mt: 0.5,
                    mr: 1,
                    bgcolor: theme => `${theme.palette.primary.main}`
                  }}
                  aria-label="recipe"
                >
                  {profile?.alias ? profile?.alias.charAt(0).toUpperCase() : profile?.address.toString().charAt(0).toUpperCase()}
                </Avatar>
              </>
            }
            title={
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                textTransform={'uppercase'}
              >
                My Settings <Settings fontSize='small' color='primary' />
              </Box>
            }
          // subheader="subheader"
          // action={

          // }
          />

          <CardContent>

            <Box
              component='form'
              onSubmit={updateProfile}
            >

              <SettingsItem
                title='Avatar image'
              // subtitle='test'
              >

                <Box
                  position='relative'
                  my={1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  height={100}
                  width={100}
                  borderRadius={0}
                  border={theme => `1px solid ${theme.palette.divider}`}
                  bgcolor={theme => theme.palette.divider}
                  sx={{
                    cursor: 'pointer',
                    backgroundImage: `url(${avatar?.uri})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    overflow: 'hidden',

                    transition: 'all 0.3s',
                    '&:hover': {
                      opacity: 0.8
                    },
                  }}
                  onClick={openAvatarSelector}
                >
                  {!avatar?.uri &&
                    <Typography
                      sx={{ color: theme => theme.palette.divider }}
                    >
                      not set
                    </Typography>
                  }

                  <DriveFileRenameOutline
                    fontSize='small'
                    color='primary'
                    sx={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px'
                    }}
                  />
                </Box>

              </SettingsItem>


              <SettingsItem
                title='Cover Image'
              >

                <Box
                  position='relative'
                  my={1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  height={110}
                  width={'100%'}
                  borderRadius={0}
                  border={theme => `1px solid ${theme.palette.divider}`}
                  bgcolor={theme => theme.palette.divider}
                  sx={{
                    cursor: 'pointer',
                    backgroundImage: `url(${imageCover?.uri})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    overflow: 'hidden',

                    transition: 'all 0.3s',
                    '&:hover': {
                      opacity: 0.8
                    },
                  }}
                  onClick={openCoverImageSelector}
                >
                  {!imageCover?.uri &&
                    <Typography
                      sx={{ color: theme => theme.palette.divider }}
                    >
                      not set
                    </Typography>
                  }

                  <DriveFileRenameOutline
                    fontSize='small'
                    color='primary'
                    sx={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px'
                    }}
                  />
                </Box>
              </SettingsItem>

              <Divider variant='inset' light sx={{ my: 3 }} />


              <SettingsItem
                title='Alias'
              >
                <TextField
                  fullWidth
                  size='small'
                  id="input-alias"
                  label="alias"

                  autoComplete="off"

                  value={alias}

                  error={aliasError !== null}
                  helperText={aliasError}

                  InputLabelProps={{ shrink: true }}

                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >
                        <AlternateEmail fontSize='inherit' />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" >
                        {isCheckingAlias &&
                          <CircularProgress color="inherit" size={16} />
                        }
                        {isAliasAvailable && !isCheckingAlias &&
                          <Check color="success" fontSize='inherit' />
                        }
                        {isAliasAvailable === false && !isCheckingAlias &&
                          <Warning color="error" fontSize='inherit' />
                        }
                      </InputAdornment>
                    )
                  }}

                  // onChange={(e) => setAlias(e.target.value)}
                  onChange={aliasChange}
                />
              </SettingsItem>

              <SettingsItem
                title='Firstname'
              >

                <TextField
                  fullWidth
                  size='small'
                  id="input-firstName"
                  label="First name"
                  autoComplete="off"

                  value={firstName}

                  InputLabelProps={{ shrink: true }}

                  error={firstNameError !== null}
                  helperText={firstNameError}

                  onChange={(e) => setFirstname(e.target.value)}
                />

              </SettingsItem>

              <SettingsItem
                title='Lastname'
              >

                <TextField
                  fullWidth
                  size='small'
                  id="input-lastName"
                  label="Last name"
                  autoComplete="off"

                  value={lastName}

                  InputLabelProps={{ shrink: true }}

                  error={lastNameError !== null}
                  helperText={lastNameError}

                  onChange={(e) => setLastname(e.target.value)}
                />

              </SettingsItem>


              <Divider variant='inset' light sx={{ my: 3 }} />


              <SettingsItem
                title='Status text'
              >

                <TextField
                  fullWidth

                  multiline
                  minRows={3}
                  maxRows={3}

                  size='small'
                  id="input-status"
                  label="Status"
                  autoComplete="off"

                  value={statusText}

                  InputLabelProps={{ shrink: true }}

                  error={statusTextError !== null}
                  helperText={statusTextError}

                  inputProps={{
                    style: { fontSize: '0.9rem' }
                  }}

                  onChange={(e) => setStatusText(e.target.value)}
                />
              </SettingsItem>


              <Divider variant='inset' light sx={{ my: 3 }} />

              <SettingsItem
                title='Bio'
              >

                <TextField
                  fullWidth
                  size='small'
                  multiline
                  minRows={5}
                  maxRows={5}
                  id="input-bio"
                  label="Bio"
                  autoComplete="off"

                  value={bio}

                  InputLabelProps={{ shrink: true }}

                  error={bioError !== null}
                  helperText={bioError}

                  inputProps={{
                    style: { fontSize: '0.9rem' }
                  }}

                  onChange={(e) => setBio(e.target.value)}
                />
              </SettingsItem>


              <Divider variant='inset' light sx={{ my: 3 }} />


              <SettingsItem
                title='Birth date'
              >

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // label="Birthdate"
                    disableFuture

                    sx={{
                      mb: 1,
                      '& .MuiInputBase-input': { py: 1.2 },
                    }}
                    slotProps={{
                      textField: {
                        error: (birthdayError !== null),
                        helperText: birthdayError,
                      },
                      field: { clearable: true, onClear: () => setBirthday(0) },

                      openPickerButton: { size: 'small' },

                      openPickerIcon: { fontSize: 'inherit' }
                    }}

                    value={birthday ? dayjs.unix(birthday as number) : null}

                    onChange={(newValue) => setBirthday(dayjs(newValue).unix())}
                  />
                </LocalizationProvider>
              </SettingsItem>

              <SettingsItem
                title='Country'
              >
                <Select
                  fullWidth
                  size='small'
                  id="input-city"
                  // label="City"
                  placeholder='select country...'
                  // renderValue={(selected) => {
                  //   if (selected.length === 0) {
                  //     return <em>select country...</em>;
                  //   }

                  //   return selected;
                  // }}
                  value={String(countryCode ?? '')}
                  onChange={onCountryChange}
                >
                  {countries.map((item, index) => (
                    <MenuItem value={item.numeric_code}>{item.emoji}&nbsp;&nbsp;&nbsp;{item.name}</MenuItem>
                  ))}
                </Select>

              </SettingsItem>

              {/* <SettingsItem
                title='City'
              >

                <TextField
                  fullWidth
                  size='small'
                  id="input-city"
                  label="City"
                />

              </SettingsItem> */}


              <Divider variant='inset' light sx={{ my: 3 }} />


              {/* <SettingsItem
                title='Current Location'
              >

                <Box>
                  {location && (
                    <>
                      {location.latitude} / {location.longitude}
                    </>
                  )}

                  <Tooltip title={location ? 'Edit location' : 'Add location'} placement='top' arrow>
                    <IconButton>
                      <EditLocationAlt fontSize='small' color='primary' />
                    </IconButton>
                  </Tooltip>
                </Box>
              </SettingsItem> */}

              {/* <Divider variant='inset' light sx={{ my: 3 }} /> */}

              <Box
                width={'100%'}
                display={'flex'}
                gap={2}
                justifyContent={'flex-end'}
              >
                {(avatar?.uri || imageCover?.uri) && uploadPrice &&
                  <Box textAlign={'right'}>
                    <Typography
                      component={'p'}
                      variant='caption'
                      color={theme => isLoading ? theme.palette.divider : theme.palette.primary.main}
                      sx={{ mt: -0.3 }}
                    >
                      Estimated upload price:
                    </Typography>
                    <Typography
                      component={'p'}
                      variant='caption'
                      sx={{ mt: -0.5 }}
                      color={theme => isLoading ? theme.palette.divider : theme.palette.secondary.main}
                    >
                      {isUploadPriceFetching ? <Skeleton width={'50px'} /> : `${uploadPrice.basisPoints / Math.pow(10, uploadPrice.currency.decimals)} ${uploadPrice.currency.symbol}`}
                    </Typography>
                  </Box>
                }

                <Button
                  type='submit'
                  startIcon={isLoading ? <CircularProgress color="inherit" size={16} /> : <Save />}
                  variant="contained"
                  // size='small'
                  disabled={isLoading || isValidationError}
                >
                  {isLoading ? 'Saving...' : 'Save'}
                </Button>
              </Box>

            </Box>

          </CardContent>

        </Card>
      }

      <SubmitDialog
        isOpen={isLoading}
        steps={submitSteps}
        activeStep={activeStep}
        isCompleted={submitCompleted}
        stepError={submitError}
      />

    </>
  )

}