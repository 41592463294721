import { Profile, Publication } from "@ju-protocol/sdk";
import { Card } from "@mui/material";
import { PublicKey } from "@solana/web3.js";
import React, { useContext, useEffect, useState } from "react"
import PublicationCard, { PublicationCardSkeleton } from "../components/Publications/PublicationCard"
import PublicationCreateCard from "../components/Publications/PublicationCreateCard"
import { AppContext } from "../contexts/main";

export default function FeedPage() {

  const { profileClient, publicationClient } = useContext(AppContext);

  const [publicationKeys, setPublicationKeys] = useState<PublicKey[]>();
  const [publicationKeysLoading, setPublicationKeysLoading] = useState(false);
  const [publicationKeysError, setPublicationKeysError] = useState<Error | null>(null);

  const [publications, setPublications] = useState<Publication[]>();
  const [publicationsLoading, setPublicationsLoading] = useState(false);
  const [publicationsError, setPublicationsError] = useState<Error | null>(null);

  const [page, setPage] = useState(1);

  const [profiles, setProfiles] = useState<Profile[]>();
  const [profilesLoading, setProfilesLoading] = useState(false);
  const [profilesError, setProfilesError] = useState<Error | null>(null);


  const fetchProfiles = () => {
    setProfilesLoading(true);
    setProfilesError(null);

    profileClient().findProfiles(
      {
        loadJsonMetadata: true
      }
    )
      .then(data => {
        setProfiles(data);
      })
      .catch(error => {
        setProfilesError(error)
      })
      .finally(() => {
        setProfilesLoading(false);
      })
  }

  const getPublicationsByPage = () => {
    const limit = 10;
    const paginatedPublicationKeys = publicationKeys!.slice(
      (page - 1) * limit,
      page * limit,
    );
    // console.log('paginatedPublicationKeys :>> ', paginatedPublicationKeys);
    getPublicationsByKeyList(paginatedPublicationKeys);

    setPage(page + 1)
  }

  const getPublicationsByKeyList = (keys: PublicKey[]) => {
    setPublicationsLoading(true);
    setPublicationsError(null);

    publicationClient().getPublicationsByKeyList({ keys, loadJsonMetadata: true })
      .then(data => {
        const pubs = data.filter(item => item !== null) as Publication[];  // TODO: Fix this in SDK
        setPublications(pubs)
      })
      .catch(error => setPublicationsError(error))
      .finally(() => {
        setPublicationsLoading(false);
      })
  }

  const fetchPublicationKeys = () => {
    setPublicationKeysLoading(true);
    setPublicationKeysError(null);

    publicationClient().findPublicationsAsKeys(
      {
        isIn7Days: true,
        isReply: false,
      }
    )
      .then(data => {
        // console.log('data :>> ', data);
        setPublicationKeys(data)
      })
      .catch(error => {
        setPublicationKeysError(error);
        console.log('error :>> ', error);
      })
      .finally(() => {
        setPublicationKeysLoading(false);
      })
  }

  useEffect(() => {
    if (publicationKeys && publicationKeys.length > 0) {
      getPublicationsByPage();
    }
  }, [publicationKeys])

  useEffect(() => {
    fetchPublicationKeys();
  }, [])

  // useEffect(() => {
  //   console.log('publications :>> ', publications);
  // }, [publications])


  return (
    <>
      {/* Add Publication panel */}
      <PublicationCreateCard />

      {/* Progress Loader */}
      {(publicationKeysLoading || publicationsLoading) &&
        [...Array(3)].map((e, i) =>
          <PublicationCardSkeleton />
        )
      }

      {/* Publications output */}
      {publications && publications.length > 0 &&
        publications.sort((a, b) => b.createdAt - a.createdAt).map((item, index) =>
          <>
            <PublicationCard publication={item} />
          </>
        )
      }

      {/* Empty output */}
      {(!publications && !(publicationKeysLoading || publicationsLoading)) &&
        <Card sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '70vh'
        }}>
          No publications
        </Card>
      }
    </>
  )

}