import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import { useConnection } from "@solana/wallet-adapter-react";
import { useProfile } from '../../hooks';
import { Avatar, Box, Card, CardActionArea, CardActions, CardContent, CardHeader, Divider, IconButton, InputBase, Link, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatIcon from '@mui/icons-material/Chat';
import VerifiedIcon from '@mui/icons-material/Verified';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SendIcon from '@mui/icons-material/Send';
import { PublicKey } from '@solana/web3.js';

interface ConversationCardProps {
  profileAddress?: PublicKey;
}

function SendMessage() {
  return (
    <>
      {/* <Avatar
        alt="NY Times"
        src="https://mui.com/static/images/avatar/1.jpg"
        // sx={{ bgcolor: `${theme.palette.primary.main}` }}
        aria-label="recipe"
      >
        R
      </Avatar>

      <InputBase
        multiline
        maxRows={4}
        sx={{
          ml: 2,
          flex: 1,
          fontSize: '0.9rem'
        }}
        placeholder="type your message ..."
        inputProps={{ 'aria-label': 'new-message' }}
      // size={'small'}
      /> */}

      <InputBase
        fullWidth
        multiline
        maxRows={4}
        startAdornment={
          <Avatar
            sx={{
              mr: 1,
              // mb: 1,
              bgcolor: (theme) => `${theme.palette.primary.main}`
            }}
            src="https://mui.com/static/images/avatar/1.jpg"
            aria-label="recipe"
          >
            &nbsp;
            {/* {profile?.alias ? profile.alias.charAt(0).toUpperCase() : publication.profile.toString().charAt(0).toUpperCase()} */}
          </Avatar>
        }
        endAdornment={
          <IconButton
            color="primary"
            // sx={{ p: '10px' }}
            aria-label="directions"
          >
            <SendIcon fontSize='small'/>
          </IconButton>
        }
        sx={{
          ml: 0,
          flex: 1,
          fontSize: '0.9rem',
          // alignItems: 'baseline'
        }}
        color='primary'
        placeholder="type your message ..."
        inputProps={{ 'aria-label': 'new-message' }}
      // value={content}
      // onChange={(event) => setContent(event.target.value)}
      // disabled={isLoading}
      />


    </>
  );
}

export default function ConversationCard(props: ConversationCardProps) {

  const theme = useTheme();

  // const { profileAddress } = props;

  // const { connection } = useConnection();
  // const ju = new Ju(connection);

  // const { profile, profileLoading, profileError } = useProfile(ju, profileAddress)

  return (
    <Card
      sx={{
        position: 'sticky',
        top: '65px'
      }}
    >
      <CardHeader
        // disableTypography
        sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
        avatar={
          <IconButton
            component={NavLink}
            to="/messenger"
            color="primary"
            // sx={{ p: '10px' }}
            aria-label="back"
          >
            <ChevronLeftIcon />
          </IconButton>
        }
        title={
          <Link component={NavLink} to="/nuclearblock">
            John Doe
          </Link>
        }
        subheader="online"
        action={
          <>
            {/* <IconButton
            color="primary"
            sx={{ 
              display: 'inline',
              p: '10px' 
            }}
            aria-label="back"
          >
            <MoreHorizIcon />
          </IconButton> */}

            <Avatar
              component={NavLink}
              to="/nuclearblock"
              alt="NY Times"
              src="https://mui.com/static/images/avatar/5.jpg"
              sx={{
                mt: 0.5,
                mr: 1,
                bgcolor: `${theme.palette.primary.main}`
              }}
              aria-label="recipe"
            >
              R
            </Avatar>
          </>
        }
      />

      <CardContent
        sx={{ height: '70vh' }}
      >
        <List>

          <ListItem
            alignItems="flex-start"

          >
            <ListItemAvatar>
              <Avatar
                alt="NY Times"
                src="https://mui.com/static/images/avatar/5.jpg"
                sx={{ bgcolor: `${theme.palette.primary.main}` }}
                aria-label="recipe"
              >
                R
              </Avatar>
            </ListItemAvatar>

            <ListItemText
              primary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body1"
                    color="text.primary"
                  >
                    Ali Connors
                  </Typography>

                  <Typography
                    sx={{
                      display: 'inline'
                    }}
                    ml={1}
                    fontSize='0.8rem'
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    27.08.2023
                  </Typography>
                </>
              }
              secondary="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, cupiditate enim voluptates nobis ex error distinctio omnis recusandae repellat. Eum, soluta hic. Aliquam officiis sit cupiditate debitis autem qui eveniet! "
            />

            <ListItemSecondaryAction
            // sx={{ top: "15%", transform: 'translateY(-15%)' }}
            >
              <IconButton aria-label="comment">
                <MoreVertIcon fontSize='small' color='secondary' />
              </IconButton>
            </ListItemSecondaryAction>

          </ListItem>

          <Divider variant='middle'>
            <Typography variant="caption" color={'secondary'}>
              28 aug 2023
            </Typography>
          </Divider>

          <ListItem
            alignItems="flex-start"
            // disableGutters
            secondaryAction={
              <IconButton aria-label="comment">
                <MoreVertIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar
                alt="NY Times"
                src="https://mui.com/static/images/avatar/1.jpg"
                sx={{ bgcolor: `${theme.palette.primary.main}` }}
                aria-label="recipe"
              >
                R
              </Avatar>
            </ListItemAvatar>

            <ListItemText
              primary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body1"
                    color="text.primary"
                  >
                    John
                  </Typography>

                  <Typography
                    sx={{
                      display: 'inline'
                    }}
                    ml={1}
                    fontSize='0.8rem'
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    28.08.2023
                  </Typography>
                </>
              }
              secondary="Hey buddy!"
            />

            <ListItemSecondaryAction
            // sx={{ top: "15%", transform: 'translateY(-15%)' }}
            >
              <IconButton aria-label="comment">
                <MoreVertIcon fontSize='small' color='secondary' />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>


        </List>

        {/* <Divider /> */}



      </CardContent>

      {/* <CardActionArea
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          px: 1.5,
          pb: 2
        }}
      >
        <SendMessage />
      </CardActionArea> */}

      <CardActions
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          px: 1.5,
          pb: 2
        }}
      >
        <SendMessage />
      </CardActions>

    </Card>
  )
}
