import React, { useContext, useEffect, useState } from 'react'
import { Profile, PublicKey } from '@ju-protocol/sdk'
import { useProfile, useProfileConnectionInitializers } from '../../hooks';
import { Avatar, Box, Button, Card, CardContent, CardMedia, CircularProgress, Divider, Skeleton, Tooltip, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { AppContext } from '../../contexts/main';
import { Edit, ManageAccounts, PersonAdd, PersonRemove, PersonRemoveAlt1 } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { NavLink } from 'react-router-dom';


interface ProfileHeaderProps {
  profile: Profile;
  profileLoading?: boolean;
}

export default function ProfileHeader({ profile, profileLoading = false }: ProfileHeaderProps) {

  // if (!profileLoading) {
  //   profileLoading = true;
  //   setTimeout(() => {
  //     profileLoading = false;
  //   },
  //     1000);
  // }

  const { profile: myProfile, connectionClient } = useContext(AppContext);

  // console.log('profile :>> ', profile.address.toString());
  // console.log('my profile :>> ', myProfile?.address.toString());

  // const profileLoading = true

  const [followed, setFollowed] = useState<boolean>();
  const [followProcessing, setFollowProcessing] = useState(false);
  const [followError, setFollowError] = useState(null);


  const checkConnection = async () => {
    if (profile.address.toString() !== myProfile?.address?.toString()) {
      const isFollowed = await connectionClient().isConnectionExist(myProfile!.address, profile.address);
      setFollowed(isFollowed);
    }
  }

  const followProfile = () => {
    if (profile.address.toString() !== myProfile?.address?.toString()) {
      setFollowProcessing(true);
      setFollowError(null);

      connectionClient().createConnection(profile.address)
        .then(result => {
          setFollowed(true)
        })
        .catch(error => {
          console.log('error :>> ', error);
          setFollowError(error);
          enqueueSnackbar("Operation failed")
        })
        .finally(() => {
          setFollowProcessing(false);
        })
    }
  }

  const unfollowProfile = () => {
    if (profile.address.toString() !== myProfile?.address?.toString()) {
      setFollowProcessing(true);
      setFollowError(null);

      connectionClient().deleteConnection(profile.address)
        .then(result => {
          setFollowed(false)
        })
        .catch(error => {
          console.log('error :>> ', error);
          setFollowError(error);
          enqueueSnackbar("Operation failed")
        })
        .finally(() => {
          setFollowProcessing(false);
        })
    }
  }

  const submitConnectionButton = () => {
    if (followed) {
      unfollowProfile();
    } else {
      followProfile();
    }
  }

  useEffect(() => {
    if (profile && myProfile) {
      checkConnection();
    }
  }, [profile, myProfile])

  return (
    <Card>
      <CardMedia>

        {/* Profile Cover Image */}
        <Box
          height={160}
        >
          {profileLoading
            ?
            // <Skeleton height={'100%'} width={'100%'} variant='rectangular' />
            <Avatar
              alt={'Profile loading...'}
              src={'no-avatar.png'}
              variant='circular'
              sx={{
                width: '100%',
                height: '100%',
                bgcolor: theme => theme.palette.mode === 'light' ? '#fff' : '#1f1f1f',
                border: theme => `6px solid ${theme.palette.mode === 'light' ? '#fff' : '#1f1f1f'}`
              }}
            />
            :
            <Box
              height={160}
              bgcolor={theme => theme.palette.divider}
              sx={{
                backgroundImage: `url(${profile?.metadata?.imageCover})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                overflow: 'hidden'
              }}
            />
          }
        </Box>

        <Box
          display={'flex'}
          justifyContent='space-between'
        >
          {/* Profile Avatar */}
          <Box
            width={150}
            height={150}
            margin={'-75px 15px 0'}
          >

            <Avatar
              alt={profile?.alias || profile?.firstName || profile?.address.toString()}
              src={profile?.metadata?.image || 'no-avatar.png'}
              variant='circular'
              sx={{
                width: '100%',
                height: '100%',
                bgcolor: theme => theme.palette.mode === 'light' ? '#fff' : '#1f1f1f',
                border: theme => `6px solid ${theme.palette.mode === 'light' ? '#fff' : '#1f1f1f'}`
              }}
            />

          </Box>


          <Box
            mt={2}
          // textAlign='center'
          >
            <Typography
              component={'p'}
              fontSize={'1.2rem'}
              // variant="h6"
              color={'primary'}
            >
              {profileLoading
                ?
                <Skeleton width={200} />
                :
                <>
                  {`${profile?.firstName} ${profile?.lastName}`}
                  {profile?.isVerified &&
                    <Tooltip title={'Verified'}>
                      <VerifiedIcon color='primary' sx={{ mb: -0.3, fontSize: '1rem' }} />
                    </Tooltip>
                  }
                </>
              }
            </Typography>

            <Typography
              component={'p'}
              variant="caption"
              color={'secondary'}

              sx={{ mt: -0.3 }}
            >
              {profileLoading
                ?
                <Skeleton width={150} />
                :
                <>
                  {`@${profile?.alias}`}
                </>
              }
            </Typography>

            {!profileLoading &&
              <>
                <Typography
                  component={'p'}
                  variant="caption"
                  color={'primary'}

                  sx={{ mt: 0.3 }}
                >
                  {/* {profile?.metadata?.bio} */}
                  {profile?.metadata?.status}
                </Typography>
              </>
            }

          </Box>

          <Box
            ml='auto'
            mr={2}
            display={'flex'}
            alignItems='center'
          >
            {myProfile && profileLoading &&
              <Skeleton width={'50px'} height={'30px'} variant="rounded" />
            }

            {myProfile && !profileLoading &&
              <>
                {profile.address.toString() !== myProfile?.address?.toString() &&
                  <Button
                    variant="text"
                    size='small'
                    sx={{ marginRight: 1 }}
                  // onClick={() => setPreviewOpen(true)}
                  >
                    DM
                  </Button>
                }
              </>
            }

            {myProfile && profileLoading &&
              <Skeleton width={'80px'} height={'30px'} variant="rounded" />
            }

            {true && !profileLoading &&
              <>
                {profile.address.toString() !== myProfile?.address?.toString()
                  ?
                  <Button
                    startIcon={
                      followProcessing
                        ?
                        <CircularProgress color="inherit" size={16} />
                        :
                        <>
                          {followed ? <PersonRemoveAlt1 /> : <PersonAdd />}
                        </>
                    }
                    variant="contained"
                    size='small'
                    disabled={(followProcessing || myProfile === undefined)}

                    onClick={submitConnectionButton}
                  >
                    {followProcessing
                      ?
                      <>
                        {followed ? 'Unfollowing...' : 'Following...'}
                      </>
                      :
                      <>
                        {followed ? 'Unfollow' : 'Follow'}
                      </>
                    }
                  </Button>
                  :
                  <Button
                    component={NavLink}
                    to={'/settings'}
                    startIcon={<ManageAccounts />}
                    variant="contained"
                    size='small'
                  >
                    Edit
                  </Button>
                }
              </>
            }
          </Box>

        </Box>


      </CardMedia>

      <Divider />

      <CardContent>

        <Typography variant="caption" color={'primary'}>
          {profileLoading
            ?
            <Skeleton width={200} />
            :
            <>
              {/* Followers: {connectionInitializersKeys ? <Skeleton /> : connectionInitializersKeys} */}
            </>
          }
        </Typography>

      </CardContent>

    </Card>
  )
}



export function ProfileHeaderSkeleton() {

  return (
    <Card>
      <CardMedia>

        {/* Profile Wallpaper */}
        <Box
          height={150}
        >
          <Skeleton height={'100%'} width={'100%'} variant='rectangular' animation={false} />
        </Box>

        <Box
          display={'flex'}
          justifyContent='space-between'
        >
          {/* Profile Avatar */}
          <Box
            width={150}
            height={150}
            margin={'-75px 15px 0'}
          >
            {/* <Skeleton
              width={'100%'}
              height={'100%'}
              variant="circular"
            // animation={false}
            /> */}
            <Avatar
            alt={'Profile loading...'}
            src={'no-avatar.png'}
            variant='circular'
            sx={{
              width: '100%',
              height: '100%',
              bgcolor: theme => theme.palette.mode === 'light' ? '#fff' : '#1f1f1f',
              border: theme => `6px solid ${theme.palette.mode === 'light' ? '#fff' : '#1f1f1f'}`
            }}
          />
          </Box>


          <Box
            mt={2}
          // textAlign='center'
          >
            <Skeleton width={200} />

            <Skeleton width={150} />
          </Box>

          <Box
            ml='auto'
            mr={2}
            display={'flex'}
            alignItems='center'
          >

            <Skeleton width={'50px'} height={'30px'} variant="rounded" sx={{ mx: 1 }} />

            <Skeleton width={'80px'} height={'30px'} variant="rounded" />
          </Box>

        </Box>

      </CardMedia>

      <Divider />

      <CardContent>
        <Skeleton width={100} />
      </CardContent>

    </Card>
  )
}
