import React, { useContext, useEffect, useState } from 'react'
import { useParams, useLocation, useSearchParams, NavLink } from "react-router-dom";
import { useProfileConnectionInitializers } from '../hooks';
import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, IconButton, Link, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GroupsIcon from '@mui/icons-material/Groups';
import AddIcon from '@mui/icons-material/Add';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ChatIcon from '@mui/icons-material/Chat';
import { Profile, PublicKey, Subspace } from '@ju-protocol/sdk';
import { AppContext } from '../contexts/main';
import { ErrorComponent } from '../components/common';
import { getProfilePda } from '../utils/helpers';
import { Visibility } from '@mui/icons-material';


interface SubspaceListItemProps {
  subspace: Subspace
}
const SubspaceListItem: React.FC<SubspaceListItemProps> = ({ subspace }) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleButtonMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>

      <Divider variant="middle" light />

      <ListItem
        sx={{ my: 1.5 }}
        // disableGutters
        alignItems="flex-start"
        secondaryAction={
          <IconButton
            aria-label="menu"
            onClick={handleButtonMenuClick}
          >
            <MoreHorizIcon color='secondary' fontSize='small' />
          </IconButton>
        }
      >
        <ListItemAvatar sx={{ minWidth: 70 }}>
          <>
            <Avatar
              component={NavLink}
              to={`/communities/${subspace.address.toString()}`}
              alt={subspace.address.toString()}
              src={subspace.metadata?.image}
              sx={{
                mt: 0.5,
                mr: 1,
                bgcolor: theme => `${theme.palette.primary.main}`
              }}
              aria-label="recipe"
            >
              {subspace.alias ? subspace.alias.charAt(0).toUpperCase() : subspace.address.toString().charAt(0).toUpperCase()}
            </Avatar>
          </>
        </ListItemAvatar>

        <Link
          component={NavLink}
          to={`/communities/${subspace.address.toString()}`}
        >
          <ListItemText
            sx={{ maxWidth: 300 }}

            primary={
              <Typography
                noWrap
              >
                {subspace.name}
              </Typography>
            }
            secondary={`@${subspace.alias}`}
          />
        </Link>

      </ListItem>



      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >

        <MenuItem onClick={handleMenuClose}>
          <Link
            component={NavLink}
            to={`/${subspace.address.toString()}`}
            color={'secondary'}
            display={'flex'}
            alignItems={'center'}
          >
            <ListItemIcon>
              <Visibility fontSize="inherit" />
            </ListItemIcon>

            View details
          </Link>
        </MenuItem>

        {/* <MenuItem onClick={handleMenuClose}>
          <Link
            component={NavLink}
            to="/messenger"
            color={'secondary'}
            display={'flex'}
            alignItems={'center'}
          >
            <ListItemIcon>
              <ChatIcon fontSize="inherit" />
            </ListItemIcon>
            Message
          </Link>
        </MenuItem> */}

      </Menu>

    </>
  )
}

const SubspaceListItemSkeleton: React.FC = () => {
  return (
    <>
      <ListItem
        // disableGutters
        sx={{ my: 1.5 }}

        secondaryAction={
          <Skeleton width={'20px'} height={'20px'} sx={{ mr: 2 }} />
        }
      >
        <ListItemAvatar sx={{ minWidth: 70 }}>
          <Skeleton width={'56px'} height={'56px'} variant="circular" />
        </ListItemAvatar>

        <ListItemText
          primary={<Skeleton width={'40%'} animation='wave' />}
          secondary={<Skeleton width={'30%'} animation='wave' />}
        />

      </ListItem>

      <Divider variant="middle" light />
    </>
  )
}


export default function SubspacesByProfilePage() {

  const { profile: myProfile, profileClient, subspaceClient } = useContext(AppContext);

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get("type") || "managed"

  const [error, setError] = useState<string | null>(null);

  const [profile, setProfile] = useState<Profile>();
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileError, setProfileError] = useState<Error | null>(null);

  const [items, setItems] = useState<Subspace[] | undefined>(undefined);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsError, setItemsError] = useState<Error | null>(null);

  const fetchProfile = (key: PublicKey) => {
    setProfileLoading(true);
    setProfileError(null);

    profileClient().getProfile(key)
      .then(data => {
        setProfile(data);
      })
      .catch(error => {
        setProfileError(error)
      })
      .finally(() => {
        setProfileLoading(false);
      })
  }

  // Fetching Joined Communities
  const fetchJoinedCommunities = () => {

    setItemsLoading(true);
    setItemsError(null);

    // First get a items keys array
    subspaceClient().findSubspacesAsKeysByConnectionInitializer({ initializer: profile?.address })
      .then(keys => {

        // Get Subspace[] by keylist
        subspaceClient().getSubspacesByKeyList({ keys })
          .then(items => {
            setTimeout(
              () => {
                setItems(items);
                setItemsLoading(false);
              },
              1000
            );
          })
          .catch(error => {
            // console.log(error);
            setItemsError(error);
            setItemsLoading(false);
          })

      })
      .catch(error => {
        setProfileError(error);
        setItemsLoading(false);
      })
    // .finally(() => {
    //   setItemsLoading(false);
    // })
  }


  // Fetching Owned Communities
  const fetchManagedCommunities = () => {

    setItemsLoading(true);
    setItemsError(null);

    // First get a items keys array
    subspaceClient().findSubspaces(
      {
        creator: profile?.address,
        loadJsonMetadata: true
      }
    )
      .then(items => {
        setTimeout(
          () => {
            setItems(items);
            setItemsLoading(false);
          },
          1000
        );
      })
      .catch(error => {
        // console.log(error);
        setItemsError(error);
        setItemsLoading(false);
      })
    // .finally(() => {
    //   setItemsLoading(false);
    // })
  }

  const fetchItems = () => {
    if (profile) {
      if (type === "joined") {
        fetchJoinedCommunities();
      }

      if (type === "managed") {
        fetchManagedCommunities();
      }
    }
  }

  useEffect(() => {
    if (profile) {
      fetchItems();
    }
  }, [profile]);

  useEffect(() => {
    if (profile) {
      fetchItems();
    }
  }, [type]);

  useEffect(() => {
    const profileKey = getProfilePda(id);

    if (!profileKey) {
      setError('Profile Public key error');
    }

    if (profileKey && profileKey !== myProfile?.address) {
      fetchProfile(profileKey);
    }
  }, []);


  return (
    <>
      {(error || !profile) &&
        <Card sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh'
        }}>
          <ErrorComponent str="Error" />
        </Card>
      }

      {!error && profile &&
        <>
          <Card>

            <CardHeader
              // disableTypography
              sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
              avatar={
                <Avatar
                  component={NavLink}
                  to={`/${profile?.address}`}
                  alt={`/${profile?.address}`}
                  src={profile?.metadata?.image}
                  sx={{
                    mt: 0.5,
                    mr: 1,
                    bgcolor: theme => `${theme.palette.primary.main}`,
                    border: theme => `2px solid #ccc`
                  }}
                  aria-label="recipe"
                >
                  {profile?.firstName ? profile.firstName.charAt(0) : profile?.address.toString().charAt(0)}
                </Avatar>
              }
              title={
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={1}
                  textTransform={'uppercase'}
                >
                  Communities <GroupsIcon fontSize='small' color='secondary' />
                </Box>
              }
              // subheader={
              //   <Link component={NavLink} to="/nuclearblock">
              //     <Typography variant='caption'>
              //       John Doe
              //     </Typography>
              //   </Link>
              // }

              action={
                <Tooltip
                  title="Create new Subspace"
                  placement='left'
                  arrow
                >
                  {/* <IconButton
                    component={NavLink}
                    to="/communities/create"
                  >
                    <AddIcon fontSize='small' />
                  </IconButton> */}
                  <Button
                    component={NavLink}
                    to="/communities/create"
                    variant={'text'}
                    startIcon={<AddIcon fontSize='inherit' />}
                    size='small'
                    sx={{ mt: 1.5, mr: 1 }}
                  >
                    Create New
                  </Button>
                </Tooltip>
              }
            />

            <CardContent
              sx={{ minHeight: '80vh' }}
            >

              <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'space-between'}
                gap={0.5}
              >

                <Box>
                  <Button
                    component={NavLink}
                    to="?type=joined"
                    color={type === 'joined' ? 'secondary' : 'primary'}
                    variant={'text'}
                    size='small'
                  >
                    Joined
                  </Button>

                  <Button
                    component={NavLink}
                    to="?type=managed"
                    color={type === 'managed' ? 'secondary' : 'primary'}
                    variant={'text'}
                    size='small'
                  >
                    Managed
                  </Button>
                </Box>

                {/* <Button
                  component={NavLink}
                  to="/communities/create"
                  variant={'text'}
                  startIcon={<AddIcon fontSize='inherit' />}
                  size='small'
                >
                  Create New
                </Button> */}

              </Box>


              {itemsLoading && !itemsError &&
                <List>
                  {[...Array(10)].map((e, i) =>
                    <SubspaceListItemSkeleton />
                  )}
                </List>
              }
              {items && items.length > 0 &&
                <List>
                  {items.map(item => (
                    <SubspaceListItem subspace={item} />
                  ))}
                </List>
              }

              {items && items.length === 0 &&
                <Box
                  minHeight={'60vh'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  color={theme => theme.palette.divider}
                >
                  no items
                </Box>
              }


            </CardContent>

          </Card>
        </>
      }
    </>

  )
}
