import { Box, Container, Divider, Grid } from "@mui/material";

export default function Header() {

  return (
    <Box
      component='footer'
      borderTop={(theme) => `1px solid ${theme.palette.divider}`}
      color={(theme) => theme.palette.text.disabled}
      fontSize={'0.9rem'}
    >
      {/* <Divider /> */}
      <Container maxWidth="lg">

        <Grid container spacing={2}>

          <Grid item sm={4}>
            Powered by Solana & Ju Protocol
          </Grid>

          <Grid item sm={4}>

          </Grid>

          <Grid item sm={4}>

          </Grid>

        </Grid>

      </Container>
    </Box>
  )
}