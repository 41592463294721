import { useContext } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { Box, Link, LinkProps, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from "@mui/material";
import RssFeedIcon from '@mui/icons-material/RssFeed';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupIcon from '@mui/icons-material/Group';
import MessageIcon from '@mui/icons-material/Message';
import VideocamIcon from '@mui/icons-material/Videocam';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import SettingsIcon from '@mui/icons-material/Settings';

import { AppContext } from '../../contexts/main';
import { AutoAwesome, MoreHorizRounded, NotificationsNone } from "@mui/icons-material";


const StyledLink = styled(NavLink)<LinkProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  marginLeft: '10px',
  padding: '12px 0',

  textDecoration: 'none',
  color: 'inherit',

  transition: 'all 0.3s',
  opacity: 0.75,

  '&:hover': {
    opacity: 1
  },

  '& svg': {
    marginRight: '10px'
  }

  // '& .MuiInputBase-input': {
  //   padding: theme.spacing(0.7, 0.7, 0.7, 0),
  //   // vertical padding + font size from searchIcon
  //   paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //   transition: theme.transitions.create('width'),
  //   width: '100%',
  //   [theme.breakpoints.up('sm')]: {
  //     width: '12ch',
  //     '&:focus': {
  //       width: '20ch',
  //     },
  //   },
  // },
}));


export default function Header() {

  const { profileAddress, profile } = useContext(AppContext);

  return (
    <Box
      sx={{
        filter: (profile === undefined) ? 'blur(2px)' : 'unset'
      }}
    >
      <List>

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={'/feed'} >
            <RssFeedIcon fontSize="small" />
            Feed
          </StyledLink>
        </ListItem>

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={profile === undefined ? '/welcome' : `${profileAddress}`} >
            <AccountBoxIcon fontSize="small" />
            My Profile
          </StyledLink>
        </ListItem>

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={'/notifications'} >
            <NotificationsNone fontSize="small" />
            Notifications
          </StyledLink>
        </ListItem>

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={"/messenger"} >
            <MessageIcon fontSize="small" />
            Messenger
          </StyledLink>
        </ListItem>

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={`/${profileAddress}/connections`} >
            <GroupIcon fontSize="small" />
            Connections
          </StyledLink>
        </ListItem>

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={`/${profileAddress}/communities`} >
            <GroupsIcon fontSize="small" />
            Communities
          </StyledLink>
        </ListItem>

        {/* <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={`${profileAddress}/images`} >
            <PermMediaIcon fontSize="small" />
            Images
          </StyledLink>
        </ListItem>

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={`${profileAddress}/video`} >
            <VideocamIcon fontSize="small" />
            Video
          </StyledLink>
        </ListItem>

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={`${profileAddress}/music`} >
            <MusicNoteIcon fontSize="small" />
            Music
          </StyledLink>
        </ListItem> */}

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={`/suggestions`} >
            <AutoAwesome fontSize="small" />
            Suggestions
          </StyledLink>
        </ListItem>

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={`/bookmarks`} >
            <BookmarksIcon fontSize="small" />
            Bookmarks
          </StyledLink>
        </ListItem>

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={"/settings"} >
            <SettingsIcon fontSize="small" />
            Settings
          </StyledLink>
        </ListItem>

        <ListItem
          disablePadding
          sx={{
            pointerEvents: profile ? 'auto' : 'none'
          }}
        >
          <StyledLink to={'#'} >
            <MoreHorizRounded fontSize="small" />
            More
          </StyledLink>
        </ListItem>

      </List>

    </Box>
  )
}