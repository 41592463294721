import { useEffect, useState, useContext } from "react";
import { useParams, useLocation, NavLink } from "react-router-dom";
import { useProfile, usePublication } from "../hooks";
import { ErrorComponent, Loader } from "../components/common";
import { PublicKey } from "@solana/web3.js";
import PublicationCard, { PublicationCardSkeleton } from "../components/Publications/PublicationCard";
import { Publisher } from "../libs/types";
import { AppContext } from "../contexts/main";
import { Card } from "@mui/material";


export default function PublicationPage() {

  const { profileClient, subspaceClient } = useContext(AppContext);

  // const location = useLocation();
  const params = useParams();
  const address = new PublicKey(params.id!);

  const { publication, publicationLoading, publicationError } = usePublication(address);

  // const [publisher, setPublisher] = useState<Publisher>();
  // const [publisherLoading, setPublisherLoading] = useState(false);
  // const [publisherError, setPublisherError] = useState<Error | null>(null);

  // const getPublisher = (publisherType: 'profile' | 'subspace', address: PublicKey) => {
  //   if (publisherType === 'profile') {
  //     setPublisherLoading(true);
  //     setPublisherError(null);

  //     profileClient().getProfile(address)
  //       .then(data => {
  //         setPublisher(
  //           {
  //             type: publisherType,
  //             address: data.address,
  //             alias: data.alias,
  //             avatar: data.metadata?.image,
  //             name: (data.firstName || data.lastName) ? `${data.firstName} ${data.lastName}` : data.address.toString()
  //           }
  //         );
  //       })
  //       .catch(error => {
  //         setPublisherError(error)
  //       })
  //       .finally(() => {
  //         setPublisherLoading(false);
  //       })
  //   } else {
  //     setPublisherLoading(true);
  //     setPublisherError(null);

  //     subspaceClient().getSubspace(address)
  //       .then(data => {
  //         setPublisher(
  //           {
  //             type: publisherType,
  //             address: data.address,
  //             alias: data.alias,
  //             avatar: data.metadata?.image,
  //             name: data.name
  //           }
  //         );
  //       })
  //       .catch(error => {
  //         setPublisherError(error)
  //       })
  //       .finally(() => {
  //         setPublisherLoading(false);
  //       })
  //   }

  // }

  // useEffect(() => {
  //   if (publication) {
  //     if (publication?.subspace) {
  //       getPublisher('subspace', publication?.subspace);
  //     } else {
  //       getPublisher('profile', publication?.profile);
  //     }
  //   }
  // }, [publication]);


  return (
    <>

      {/* Progress Loader */}
      {publicationLoading &&
        <PublicationCardSkeleton />
      }

      {publication && !publicationLoading &&
        <PublicationCard 
        publication={publication} 
        // publisher={publisher} 
        showComments 
        />
      }

      {/* If smth went wrong */}
      {publicationError &&
        <Card
          sx={{
            minHeight: '90vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ErrorComponent />
        </Card>
      }

    </>
  )

}