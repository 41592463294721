import { OpenInNew } from "@mui/icons-material";
import { Box, LinearProgress, Link, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../contexts/main";



export default function ProfileCompleteness() {

  const { profile } = useContext(AppContext);

  const [percentage, setPercentage] = useState(0);

  const calculateCompleteness = () => {
    let completeness = 0;

    if (profile?.firstName) {
      completeness = completeness + 10;
    }
    if (profile?.lastName) {
      completeness = completeness + 10;
    }
    if (profile?.metadata?.image) {
      completeness = completeness + 10;
    }
    if (profile?.metadata?.imageCover) {
      completeness = completeness + 10;
    }
    if (profile?.metadata?.bio) {
      completeness = completeness + 10;
    }
    if (profile?.metadata?.status) {
      completeness = completeness + 10;
    }
    if (profile?.birthDate) {
      completeness = completeness + 10;
    }
    if (profile?.countryCode) {
      completeness = completeness + 10;
    }

    // console.log('completeness >> ', completeness )
    setPercentage(completeness);
  }

  useEffect(() => {
    if (profile) {
      calculateCompleteness();
    }
  }, [])


  return (
    <Box
      pl={1.5}
      pr={2}
      fontSize={'0.9rem'}
    >
      <Typography variant="caption" sx={{ mb: 0 }}>
        {`Profile completeness: ${percentage}%`}
      </Typography>

      <LinearProgress variant='determinate' value={percentage} sx={{ mb: 0.5 }} />

      <Link
        component={NavLink}
        to={'/settings'}
      >
        <Typography variant="caption">
          improve <OpenInNew fontSize="inherit" color="secondary" />
        </Typography>
      </Link>
    </Box>
  )
}