import { Box } from "@mui/material";
import AccessRequest from "../AccessRequest";
import Banner from "../Banner";
import DefinedSuggestedCommunities from "../DefinedSuggestedCommunities";
import SuggestedCommunities from "../SuggestedCommunities";
import SuggestedProfiles from "../SuggestedProfiles";

export default function RightColumn() {

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 65,

        display: { xs: 'none', sm: 'block' }
      }}
    >
      <AccessRequest />
      
      {/* <SuggestedProfiles /> */}
      <SuggestedCommunities />
      {/* <DefinedSuggestedCommunities /> */}

      {/* <Banner /> */}

    </Box>
  )
}