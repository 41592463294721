import React, { createContext, useEffect, useMemo, useState } from 'react';
import { createTheme, CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import { CoreUtilsClient, ConnectionClient, Ju, Profile, ProfileClient, PublicationClient, PublicKey, ReactionClient, sol, SubspaceClient, walletAdapterIdentity, webServerStorage, bundlrStorage } from '@ju-protocol/sdk';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useProfile } from '../hooks';

const APP = new PublicKey(process.env.REACT_APP_APP!);

export type TAppContext = {
  theme: Theme;
  mode: 'light' | 'dark';
  handleSwitchMode: () => void;

  isMobileMenuOpen: boolean;
  handleMobileMenu: () => void;

  clearState: () => void;

  APP: PublicKey;

  ju: Ju;
  setJu: (ju: Ju) => void;
  profileAddress?: PublicKey;
  // profileExist?: boolean;
  // setProfileExist: (profileExist: boolean) => void;
  profile?: Profile;
  profileLoading: boolean;
  profileError: Error | null;
  setProfile: (profile: Profile) => void;

  profileClient: () => ProfileClient;
  subspaceClient: () => SubspaceClient;
  publicationClient: () => PublicationClient;
  connectionClient: () => ConnectionClient;
  utilsClient: () => CoreUtilsClient;
  reactionClient: () => ReactionClient;
};

export const AppContext = createContext({} as TAppContext);


type Props = {
  children?: React.ReactNode
};
export const AppProvider: React.FC<Props> = ({ children }) => {

  const { connection } = useConnection();
  const wallet = useWallet();

  const [ju, setJu] = useState<Ju>(new Ju(connection));

  const [profileAddress, setProfileAddress] = useState<PublicKey>();
  // const [profileExist, setProfileExist] = useState<boolean | undefined>(undefined);

  const [profile, setProfile] = useState<Profile>();
  const [profileLoading, setProfileLoading] = useState(false);
  const [profileError, setProfileError] = useState<Error | null>(null);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [notifications, setNotification] = useState<Profile>();

  const getProfileFromLocalStorage = (): Profile | undefined => {
    const profileFromStorage = localStorage.getItem("profile");
    if (profileFromStorage !== null) {
      return JSON.parse(profileFromStorage);
    } else {
      return undefined
    }
  }

  // const getProfile = () => {
  //   if (!profile) {
  //     const profileFromStorage = getProfileFromLocalStorage();
  //     if (false) {
  //       setProfile(profileFromStorage);
  //     } else {
  //       fetchProfile();
  //     }
  //   }
  // }


  const fetchProfile = async (address: PublicKey) => {
    setProfileLoading(true);
    setProfileError(null);

    try {
      const profile = await profileClient().getProfile(address, true);
      setProfile(profile);
    } catch (error) {
      setProfileError(error as Error);
      console.log('error :>> ', error);
    } finally {
      setProfileLoading(false);
    }
  }

  // Core Clients for convinience usage
  const profileClient = () => {
    return ju.core().profiles(APP);
  }
  const subspaceClient = () => {
    return ju.core().subspaces(APP);
  }
  const publicationClient = () => {
    return ju.core().publications(APP);
  }
  const connectionClient = () => {
    return ju.core().connections(APP);
  }
  const utilsClient = () => {
    return ju.core().utils(APP);
  }
  const reactionClient = () => {
    return ju.core().reactions(APP);
  }

  const getModeFromStorage = (): 'light' | 'dark' => {
    if (localStorage.getItem("mode") === 'dark') return 'dark';
    return 'light';
  };

  const [mode, setMode] = useState<'light' | 'dark'>(getModeFromStorage() || 'light');

  const theme = createTheme({
    components: {
      // Name of the component
      MuiAppBar: {
        styleOverrides: {
          root: {

          },
        },
      },
      // MuiPaper: {
      //   styleOverrides: {
      //     root: {
      //       boxShadow: 'none',
      //       borderRadius: 0,
      //     },
      //   },
      // },
      MuiLink: {
        styleOverrides: {
          root: {
            transition: 'all 0.3s',
            textDecoration: 'none',
            // Some CSS
            '&:hover': {
              filter: 'brightness(85%)',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            transition: 'all 0.3s',
            textDecoration: 'none',
            borderRadius: 8,
            boxShadow: 'none',
            // backgroundImage: 'linear-gradient(135deg, rgb(79, 82, 97) 0%, rgb(56, 58, 69) 50%)',
            '&:hover': {
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            transition: 'all 0.3s',
            marginBottom: '15px',

            boxShadow: 'none',
            borderRadius: 0,

            '&:hover': {
            },
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            // paddingBottom: 0
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            // borderRadius: 2,

            '&:hover': {
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: 8,
            // backgroundImage: 'linear-gradient(135deg, rgb(79, 82, 97) 0%, rgb(56, 58, 69) 50%)',

            '&:hover': {
            },
          },
        },
      },
    },
    palette: {
      mode,
      ...(mode === 'light'
        ? {
          background: {
            default: '#f0f0f0',
          },
          border: '#e5e5e5',

        }
        : {
          background: {
            default: '#333',
          },
          border: '#444',
        }),
      primary: {
        main: '#434653',
        light: '#666a7e',
        dark: '#2c2e37'
      },
      secondary: {
        light: '#99ccff',
        main: '#6699ff',
        dark: '#3366ff',
        contrastText: '#666',
      },
    },
    typography: {
      allVariants: {

      },
    },
  });

  const clearState = () => {
    setMode('light');
    setProfile(undefined);
  };

  const handleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  const handleSwitchMode = () => {
    setMode((currentMode) => {
      const newMode = currentMode === 'light' ? 'dark' : 'light';
      // setModeInStorage(newMode);
      localStorage.setItem("mode", newMode);
      return newMode;
    });
  }

  const memoizedValue = useMemo(
    () => ({
      theme,
      mode,
      clearState,
      handleSwitchMode,
      isMobileMenuOpen,
      handleMobileMenu,
      APP,
      ju,
      setJu,
      profileAddress,
      // profileExist,
      profile,
      profileLoading,
      profileError,
      setProfile,
      profileClient,
      subspaceClient,
      publicationClient,
      connectionClient,
      utilsClient,
      reactionClient
    }),
    [
      mode,
      isMobileMenuOpen,
      ju,
      profileAddress,
      profile,
      profileLoading
    ],
  );

  useEffect(() => {
    if (wallet.connected && wallet.publicKey) {
      setJu(new Ju(connection)
        .use(walletAdapterIdentity(wallet))
        .use(bundlrStorage({
          address: 'https://devnet.bundlr.network',
          providerUrl: 'https://api.devnet.solana.com',
          timeout: 60000,
          priceMultiplier: 2,
        }))
        // .use(webServerStorage())
      )

      const profileAddr = ju.core().pdas().profile({ app: APP, authority: wallet.publicKey });

      setProfileAddress(profileAddr);

      fetchProfile(profileAddr);

      // ju.rpc().accountExists(profileAddr).then(result => setProfileExist(result))

      // ju.rpc().airdrop(wallet.publicKey, sol(100))
      //   .then(res => enqueueSnackbar("Airdrop successful!"))
      //   .catch(error => enqueueSnackbar("Airdrop failed"))
    }
  }, [wallet, connection])


  return (
    <AppContext.Provider value={memoizedValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          {children}
        </SnackbarProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
};