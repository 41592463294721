import React, { useCallback, useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, CircularProgress, Dialog, DialogTitle, Divider, IconButton, ImageList, ImageListItem, InputBase, Link, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import RepeatIcon from '@mui/icons-material/Repeat';
import PublicationReplyCard from './PublicationReplyCard';
import { PublicKey } from '@solana/web3.js';
import { formatDateTime, Profile, Publication, PublicationJsonMetadata, Subspace, toDateTime } from '@ju-protocol/sdk';
import ReactPlayer from 'react-player';
import { Chat, Delete, Favorite, FavoriteBorder, LensBlur, Send, Visibility, Warning } from '@mui/icons-material';
import { AppContext } from '../../contexts/main';
import { enqueueSnackbar } from 'notistack';
import { fromTimestamp } from '../../utils/helpers';
import CopyToClipboard from '../common/CopyToClipboard';


interface MetadataAttachment {
  index: number;
  type: string;
  description?: string;
  uri: string;
}

interface PublicationCardProps {
  publication: Publication;
  publisher?: Profile | Subspace;
  showComments?: boolean;
  key?: string
}

// function srcset(image: string, size: number, rows = 1, cols = 1) {
//   return {
//     src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
//     srcSet: `${image}?w=${size * cols}&h=${size * rows
//       }&fit=crop&auto=format&dpr=2 2x`,
//   };
// }

function ProfileTitle({ publisher }: { publisher?: Profile | Subspace; }) {

  return (
    <>
      {publisher &&
        <Link
          component={NavLink}
          to={publisher?.model === 'subspace' ? `/communities/${publisher.address.toString()}` : `/${publisher.address.toString()}`}
        >
          <Box display={'flex'} alignItems={'center'} gap={1}>
            {publisher?.model === 'profile' &&
              <Typography
                noWrap
                color={theme => theme.palette.primary.main}
                fontWeight={500}
              >
                {`${publisher.firstName} ${publisher.lastName}`}
              </Typography>
            }

            {publisher?.model === 'subspace' &&
              <Typography
                noWrap
                color={theme => theme.palette.primary.main}
                fontWeight={500}
              >
                {publisher?.name}
              </Typography>
            }

            {publisher?.alias &&
              <Typography
                noWrap
                color={theme => theme.palette.primary.dark}
                variant='subtitle1'
                sx={{
                  mt: '-2px',
                  display: { xs: 'none', sm: 'block' }
                }}
                fontWeight={500}
              >
                {`@${publisher.alias}`}
              </Typography>
            }
          </Box>
        </Link>
      }

      {/* {!publisher?.name && !publisher?.alias &&
        <Link
          component={NavLink}
          to={`/${publisher?.address.toString()}`}
        >
          <Typography color={theme => theme.palette.primary.main}>
            {publisher?.address.toString()}
          </Typography>
        </Link>
      } */}
    </>
  );
}

export default function PublicationCard({ publication, publisher: propsPublisher, showComments = false, key }: PublicationCardProps) {

  const navigate = useNavigate();

  // console.log(JSON.stringify(publication, null, 4))

  const { ju, APP, profile, profileClient, subspaceClient, publicationClient, reactionClient } = useContext(AppContext);

  const [publisher, setPublisher] = useState<Profile | Subspace | undefined>(propsPublisher);
  const [isPublisherFetching, setIsPublisherFetching] = useState(false);
  const [publisherError, setPublisherError] = useState<Error | null>(null);

  const [isMyReactionExist, setIsMyReactionExist] = useState(false);
  const [myReactionProcessing, setMyReactionProcessing] = useState(false);
  const [myReactionError, setMyReactionError] = useState<Error | null>(null);

  const [replyContent, setReplyContent] = useState<string>();

  const [isReplyingProcessing, setIsReplyingProcessing] = useState(false);
  const [ReplyingError, setReplyingError] = useState<Error | null>(null);

  const [reactionKeys, setReactionKeys] = useState<PublicKey[]>([]);
  const [commentKeys, setCommentKeys] = useState<PublicKey[]>([]);

  const [myReactionKey, setMyReactionKey] = useState<PublicKey>(PublicKey.default)

  const [comments, setComments] = useState<Publication[]>([]);

  const [isDeleteProcessing, setIsDeleteProcessing] = useState(false);
  const [deleteError, setDeleteError] = useState<Error | null>(null);

  // console.log('publication', publication)
  const files = publication.metadata?.attachments ?? [];

  let mainMediaFiles: MetadataAttachment[] = []
  let audioFiles: MetadataAttachment[] = [];

  files.forEach((item, index) => {
    if (item.type?.includes('image') || item.type?.includes('video')) {
      mainMediaFiles.push(
        {
          index,
          type: item.type!,
          description: item.description,
          uri: item.uri!
        }
      )
    }

    if (item.type?.includes('audio')) {
      audioFiles.push(
        {
          index,
          type: item.type!,
          description: item.description,
          uri: item.uri!
        }
      )
    }
  })

  const fetchPublisher = useCallback(() => {
    setIsPublisherFetching(true);

    // console.log('publication', JSON.stringify(publication, null, 4))
    if (publication.subspace.toString() === PublicKey.default.toString()) {
      profileClient().getProfile(publication.profile, true)
        .then(profile => {
          setPublisher(profile)
        })
        .catch(error => {
          setPublisherError(error);
        })
        .finally(() => setIsPublisherFetching(false))
    } else {
      subspaceClient().getSubspace(publication.subspace, true)
        .then(subspace => {
          setPublisher(subspace)
        })
        .catch(error => {
          setPublisherError(error);
        })
        .finally(() => setIsPublisherFetching(false))
    }
  }, [])

  // Detect if My user reaction exist
  const detectMyReaction =  useCallback(() => {
    if (profile?.address) {
      ju.rpc().accountExists(myReactionKey)
        .then(res => {
          setIsMyReactionExist(res);
        })
        .catch(error => {
          console.log('error :>> ', error);
        })
    }
  }, []);

  // Fetch reactions
  const fetchReactionKeys =  useCallback(() => {
    reactionClient().findReactionsAsKeys(
      {
        target: publication.address,
        reactionType: { __kind: 'UpVote' } // UpVote type
      }
    )
      .then(res => setReactionKeys(res))
      .catch(error => {
        console.log('error :>> ', error);
      })
  }, [reactionKeys])

  // Fetch comments keys
  const fetchCommentKeys =  useCallback(() => {
    publicationClient().findPublicationsAsKeys(
      {
        isEncrypted: false,
        targetPublication: publication.address,
        isReply: true
      }
    )
      .then(res => setCommentKeys(res))
      .catch(error => {
        console.log('error :>> ', error);
      })
  }, [])

  // // Fetch comments
  // const fetchComments = () => {
  //   publicationClient().getPublicationsByKeyList({ keys: commentKeys, loadJsonMetadata: true })
  //     .then(res => setComments(res as Publication[]))
  //     .catch(error => {
  //       console.log('error :>> ', error);
  //     })
  // }


  const createReaction = () => {
    // console.log('creating...')
    setMyReactionProcessing(true);
    setMyReactionError(null);

    reactionClient().createReaction(publication.address, { __kind: 'UpVote' })
      .then(result => {
        setMyReactionProcessing(false);

        setIsMyReactionExist(true);

        let newData = [...reactionKeys];
        newData.push(myReactionKey);
        setReactionKeys(newData);
      })
      .catch(error => {
        enqueueSnackbar('Failed')
        setMyReactionError(error);
        setMyReactionProcessing(false);
      })
  }

  const deleteReaction = () => {
    // console.log('deleting...')
    if (isMyReactionExist && reactionKeys.length > 0) {
      setMyReactionProcessing(true);
      setMyReactionError(null);

      reactionClient().deleteReaction(publication.address)
        .then(result => {
          setMyReactionProcessing(false);

          setIsMyReactionExist(false);

          const newData = [...reactionKeys].filter(item => item.toString() !== myReactionKey.toString());
          setReactionKeys(newData);
        })
        .catch(error => {
          enqueueSnackbar('Failed')
          setMyReactionError(error);
          setMyReactionProcessing(false);
        })
    }
  }


  const createReply = () => {
    setIsReplyingProcessing(true);
    setReplyingError(null);

    ju.storage().uploadMetadata<PublicationJsonMetadata<string>>(
      {
        appId: APP.toString(),
        content: {
          type: 'plainText',
          value: replyContent
        },
        // files: files.map(file => (
        //   {
        //     type: file.type,
        //     description: '',
        //     uri: file.isExternalLink ? file.uri : toJuFile(DataURIToByteString(file.uri), file.name!)
        //   }
        // ))
      }
    ).then(res => {
      publicationClient().createPublication(
        {
          isEncrypted: false,

          metadataUri: res.uri,

          isReply: true,
          target: publication.address,

          externalProcessors: {
            collectingProcessor: null,
            referencingProcessor: null
          }
        }
      ).then(res => {
        enqueueSnackbar("Comment successfully created!");
        setReplyContent('');

        const replyPublication = res.publication;

        // setComments(oldData => [replyPublication, ...oldData]);
        setCommentKeys(oldData => [replyPublication.address, ...oldData]);

        setIsReplyingProcessing(false);
      })
        .catch(error => {
          // Publication submit error handler here
          enqueueSnackbar("Failed");
          setReplyingError(error);
          setIsReplyingProcessing(false);
        })
    })
      .catch(error => {
        // Metadata upload error handler here
        enqueueSnackbar("Failed");
        setReplyingError(error);
        setIsReplyingProcessing(false);
      })

  }


  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(0);

  const handleClose = (value: number) => {
    setOpen(false);
    setSelectedValue(value);
  };


  const handleDelete = () => {
    setIsDeleteProcessing(true);

    publicationClient()
      .deletePublication(publication.address)
      .then(res => {
        enqueueSnackbar('Publication successfull deleted')
        // reload page
        navigate(0);
      })
      .catch(error => {
        enqueueSnackbar('Delete operation failed')
        setDeleteError(error);
        console.log('error :>> ', error);
      })
      .finally(() => {
        setIsDeleteProcessing(false);
      })

  };


  // This function will be triggered when the thumbnail is clicked
  const mainMediaClick = (
    value: number,
    event: any
    // event: React.MouseEvent<HTMLLIElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const element = event.currentTarget;

    setSelectedValue(value);
    setOpen(true);
  };

  const calculateSize = (itemsCount: number) => {
    let sizes = {
      rows: 1,
      columns: 1
    }

    if (itemsCount > 2 && itemsCount <= 4) {
      sizes.rows = itemsCount - 1;
      sizes.columns = itemsCount - 1;
    } else if (itemsCount > 4) {
      sizes.rows = 4;
      sizes.columns = 4
    }

    return sizes;
  }

  // Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleButtonMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {

    if (profile) {

      const mrk = ju.core().pdas().reaction(
        {
          app: APP,
          target: publication.address,
          initializer: profile.address
        }
      )
      setMyReactionKey(mrk);
      detectMyReaction();
    }

    fetchReactionKeys();
    fetchCommentKeys();
  }, [isMyReactionExist, commentKeys, reactionKeys]);


  useEffect(() => {
    if (!publisher) {
      fetchPublisher();
    }
  }, [publisher]);

  return (
    <>
      <Card
        key={key}
        sx={{
          transition: 'all 0.3s'
        }}
      >
        <CardHeader
          sx={{ paddingBottom: 0 }}
          avatar={
            <Link
              component={NavLink}
              to={publisher?.model === 'subspace' ? `/communities/${publication.subspace.toString()}` : `/${publication.profile.toString()}`}
            >
              <Avatar
                src={publisher?.metadata?.image}
                sx={{ bgcolor: theme => `${theme.palette.primary.main}` }}
                aria-label="avatar"
              >
                <LensBlur fontSize='small' />
                {/* {publisher?.alias ? publisher.alias.charAt(0).toUpperCase() : publication.profile.toString().charAt(0).toUpperCase()} */}
              </Avatar>
            </Link>
          }
          action={
            <IconButton
              aria-label="publication-menu"
              onClick={handleButtonMenuClick}
            >
              <MoreVertIcon fontSize='small' color='primary' />
            </IconButton>
          }
          title={
            <>
              {publisher
                ?
                <ProfileTitle publisher={publisher} />
                :
                <Typography
                  noWrap
                  variant='inherit'
                  sx={{
                    maxWidth: 200,
                    // filter: 'blur(1.5px)'
                  }}
                >
                  {publication.profile.toString()}
                </Typography>
              }
            </>
          }
          subheader={
            <Tooltip
              title={formatDateTime(toDateTime(publication.createdAt))}
              placement='right'
            // arrow
            >
              <Link
                color='secondary'
                component={NavLink}
                to={`/pubs/${publication.address.toString()}`}
              >
                {fromTimestamp(publication.createdAt)}
              </Link>
            </Tooltip>
          }
        />

        <CardContent>

          {publication.metadata?.content &&
            <Typography
              variant="body2"
              color="text.secondary"
              whiteSpace={'pre-wrap'}
            >
              {publication.metadata?.content.value}
            </Typography>
          }

          {mainMediaFiles.length > 0 &&
            <ImageList
              variant="quilted"
              cols={mainMediaFiles.length > 4 ? 4 : mainMediaFiles.length}
              rowHeight={mainMediaFiles.length === 1 ? 500 : 'auto'}
              gap={10}
              sx={{
                transition: 'all 0.3s'
              }}
            >
              {mainMediaFiles.map((item, index) => (
                <ImageListItem
                  key={item.index}
                  rows={index === 0 ? calculateSize(mainMediaFiles.length).rows : 1}
                  cols={index === 0 ? calculateSize(mainMediaFiles.length).columns : 1}
                  sx={{
                    borderRadius: 1,
                    overflow: 'hidden',
                    backgroundColor: '#000000',
                    transition: 'all 0.3s',
                    // opacity: 0.9,
                    '&:hover': {
                      opacity: 0.95
                    },
                    cursor: 'pointer'
                  }}
                  onClick={(e) => mainMediaClick(item.index, e)}
                >
                  {item.type === 'image' &&
                    <img
                      // srcSet={item.content}
                      src={item.uri}
                      alt={item.description}
                      loading="lazy"

                      onClick={(e) => mainMediaClick(index, e)}
                    />
                    // <Box
                    //   component={'img'}
                    //   // srcSet={item.content}
                    //   src={item.uri}
                    //   alt={item.description}
                    //   loading="lazy"
                    //   sx={{
                    //     height: '100%',
                    //     // width: '100%',
                    //     transition: 'all 0.5s',
                    //     '&:hover': {
                    //       opacity: 1,
                    //       transform: 'scale(1.1)'
                    //     },
                    //   }}
                    // />
                  }

                  {item.type === 'video' &&
                    <Box
                      height={'100%'}
                      sx={{ minHeight: '200px' }}
                      onClick={(e) => mainMediaClick(index, e)}
                    >
                      <ReactPlayer
                        // light
                        url={item.uri}
                        width='100%'
                        height='100%'
                        // height={index === 0 ? '100%' : `${360/calculateSize(mainMediaCount).rows}`}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          pointerEvents: 'none',
                        }}
                      />
                    </Box>
                  }

                </ImageListItem>
              ))}
            </ImageList>
          }

          {audioFiles.length > 0 &&
            <Box mt={3} mb={0}>
              {/* <Divider /> */}

              {audioFiles.map((file, index) => (
                <Box
                  key={index}
                  position='relative'
                  width={'100%'}
                >

                  {file.type?.includes('audio') &&
                    <audio src={file.uri} controls style={{ width: '100%' }} />
                  }

                </Box>
              ))}
            </Box>
          }

        </CardContent>


        <CardActions
          // disableSpacing
          sx={{
            mx: 1,
            mb: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            display={'flex'}
            gap={2}
          >
            <Chip
              disabled={myReactionProcessing || (profile === undefined)}

              sx={{
                px: 0.5,
                textAlign: 'center'
              }}

              icon={
                <>
                  {myReactionProcessing
                    ?
                    <>
                      <CircularProgress size={16} color={'inherit'} />
                    </>
                    :
                    <>
                      {isMyReactionExist
                        ?
                        <Favorite fontSize='small' color='primary' />
                        :
                        <FavoriteBorder fontSize='small' />
                      }
                    </>
                  }
                </>
              }
              size="small"
              label={reactionKeys.length}

              // onClick={e => { isMyReactionExist ? createReaction() : deleteReaction() }}
              onClick={e => {
                !isMyReactionExist ? createReaction() : deleteReaction();
              }}
            // onClick={createReaction}
            />

            <Chip
              disabled={myReactionProcessing || (profile === undefined)}
              component={NavLink}
              to={`/pubs/${publication.address.toString()}`}
              icon={<ChatBubbleOutlineIcon />}
              size="small"
              label={commentKeys.length}

              sx={{ cursor: 'pointer' }}
            />
          </Box>

          <Chip
            disabled={myReactionProcessing || (profile === undefined)}
            icon={<RepeatIcon />}
            size="small"
            label="0"
          />

        </CardActions>

        {showComments &&

          <>
            <Divider>
              {/* <Typography variant="caption" color={'primary'}>
                comments
              </Typography> */}
            </Divider>

            {commentKeys.length > 0
              ?
              <>

                {commentKeys.map((item, index) => (
                  <PublicationReplyCard publicationAddress={item} />
                ))}

                {/* <Link
                  href='#'
                  color={'secondary'}
                  variant='caption'
                >
                  <Box
                    mb={0.5}
                    textAlign={'center'}
                  >
                    show more
                  </Box>
                </Link> */}
              </>
              :
              <Box
                width={'100%'}
                minHeight={'20vh'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Typography
                  variant='caption'
                  color={theme => theme.palette.primary.light}
                >
                  no comments added
                </Typography>
              </Box>
            }

            <Divider light variant='fullWidth' />

            <Box
              // bgcolor={theme => theme.palette.background.paper}
              px={2}
              my={2}
            >

              <InputBase
                fullWidth
                multiline
                maxRows={4}
                startAdornment={
                  <Avatar
                    component={NavLink}
                    to={`/${profile?.address}`}
                    alt={`/${profile?.address}`}
                    src={profile?.metadata?.image}
                    sx={{
                      height: '32px',
                      width: '32px',
                      mt: 0.5,
                      mr: 1,
                      bgcolor: theme => `${theme.palette.primary.main}`
                    }}
                    aria-label="recipe"
                  >
                    {profile?.firstName ? profile.firstName.charAt(0) : profile?.address.toString().charAt(0)}
                  </Avatar>
                }
                endAdornment={
                  <IconButton
                    disabled={isReplyingProcessing || (profile === undefined)}

                    color="primary"
                    // sx={{ p: '10px' }}
                    aria-label="reply-send-button"

                    onClick={createReply}
                  >
                    {isReplyingProcessing
                      ?
                      <CircularProgress size={'16px'} color='inherit' />
                      :
                      <Send fontSize='small' />
                    }
                  </IconButton>
                }

                inputProps={{
                  // pt: '10px',
                  'aria-label': 'create reply'
                }}

                sx={{
                  ml: 0,
                  fontSize: '0.9rem',
                  alignItems: 'center'
                }}
                color='primary'
                placeholder="type your comment ..."

                value={replyContent}
                onChange={(event) => setReplyContent(event.target.value)}
                disabled={isReplyingProcessing}
              />
            </Box>
          </>
        }
      </Card>

      <Menu
        id="publication-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}

      >

        <MenuItem
          // onClick={handleMenuClose} 
          sx={{
            fontSize: '0.9rem'
          }}
        >
          <CopyToClipboard
            textToCopy={`${window.location.host}/pubs/${publication.address.toString()}`}
            title={'Copy URL'}
            gap={0.5}
          />
        </MenuItem>

        <MenuItem
          // onClick={handleMenuClose}
          sx={{
            fontSize: '0.9rem'
          }}
        >
          <CopyToClipboard
            textToCopy={`${publication.address.toString()}`}
            title={'Copy ID'}
            gap={0.5}
          />
        </MenuItem>

        {/* <MenuItem
          onClick={handleDelete}
          // sx={{
          //   fontSize: '0.9rem'
          // }}
        >
          <Delete fontSize="inherit" /> Delete
        </MenuItem> */}

        <MenuItem
          sx={{
            fontSize: '0.9rem'
          }}
          onClick={handleMenuClose}
        >
          <Link
            // component={NavLink}
            // to="/messenger"
            color={'primary'}
            display={'flex'}
            gap={0.5}
            alignItems={'center'}
          >
            {/* <ListItemIcon>
              <Warning fontSize="inherit" />
            </ListItemIcon> */}
            <Warning fontSize="inherit" /> Report
          </Link>
        </MenuItem>

      </Menu>

      {files[selectedValue] &&
        <Dialog
          fullWidth={files[selectedValue].type === 'video'}
          maxWidth={'md'}
          onClose={handleClose}
          open={open}
        >
          {files[selectedValue].type === 'image' &&
            <Box
              height={'100%'}
              textAlign={'center'}
            >
              <img
                // srcSet={item.content}
                src={files[selectedValue].uri}
                alt={files[selectedValue].description}
                loading="lazy"
                height={'100%'}
              />
            </Box>
          }

          {files[selectedValue].type === 'video' &&
            <ReactPlayer
              controls
              url={files[selectedValue].uri}
              width='100%'
              height='80vh'
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          }
        </Dialog>
      }
    </>
  )
}


export function PublicationCardSkeleton() {

  return (

    <Card>
      <CardHeader
        avatar={
          <Skeleton width={'40px'} height={'40px'} variant="circular" />
        }
        action={
          <></>
          // <Skeleton variant="circular"/>
        }
        title={
          <Skeleton width={'200px'} />
        }
        subheader={
          <Skeleton width={'100px'} />
        }
      />

      <CardContent>

        <Skeleton width={'90%'} />
        <Skeleton width={'60%'} />
        <Skeleton width={'40%'} />

        <Skeleton variant='rounded' height={'350px'} width={'100%'} sx={{ my: 2 }} />

      </CardContent>


      {/* <CardActions >
        <Skeleton width={'50px'}/>

        <Skeleton width={'50px'}/>

        <Skeleton width={'50px'}/>
      </CardActions> */}

    </Card>
  )
}
