import React, { useContext, useEffect, useState } from 'react'
import { Subspace } from '@ju-protocol/sdk'
import { useSubspaceMembers } from '../../hooks';
import { Avatar, Box, Button, Card, CardContent, CardMedia, CircularProgress, Divider, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { AppContext } from '../../contexts/main';
import { Edit, HowToReg, PersonAdd, Settings } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { NavLink } from 'react-router-dom';


interface SubspaceHeaderProps {
  subspace: Subspace;
  subspaceLoading?: boolean;
}

export default function SubspaceHeader({ subspace, subspaceLoading = false }: SubspaceHeaderProps) {

  const { profile, connectionClient } = useContext(AppContext);

  const [joined, setJoined] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const checkConnection = async () => {
    if (profile) {
      if (subspace.creator.toString() !== profile.address?.toString()) {
        const isJoined = await connectionClient().isConnectionExist(profile.address, subspace.address);
        setJoined(isJoined);
      } else {
        setJoined(true);
      }
    }
  }

  const joinSubspace = () => {
    if (!joined) {
      setLoading(true);
      setError(null);

      connectionClient().createConnection(subspace.address)
        .then(result => {
          setJoined(true);
          enqueueSnackbar("Successfully joined");
        })
        .catch(error => {
          // console.log('error :>> ', error);
          setError(error);
          enqueueSnackbar("Operation failed")
        })
        .finally(() => {
          setLoading(false);
        })
    }
  }

  const leaveSubspace = () => {
    if (profile) {
      if (profile.address.toString() !== subspace.creator.toString()) {
        setLoading(true);
        setError(null);

        connectionClient().deleteConnection(profile.address)
          .then(result => {
            setJoined(false)
          })
          .catch(error => {
            console.log('error :>> ', error);
            setError(error);
            enqueueSnackbar("Operation failed")
          })
          .finally(() => {
            setLoading(false);
          })
      }
    }
  }

  const submit = () => {
    if (joined) {
      leaveSubspace();
    } else {
      joinSubspace();
    }
  }

  // Fetching Joiners
  // const { addresses, addressesLoading, addressesError } = useSubspaceMembers(subspace.address, false);

  useEffect(() => {
    checkConnection();
  }, [profile])

  return (
    <Card>
      <CardMedia>

        {/* Subspace Cover Image */}
        <Box
          height={150}
        >
          {subspaceLoading
            ? <Skeleton height={'100%'} width={'100%'} variant='rectangular' />
            :
            <Box
              height={150}
              bgcolor={theme => theme.palette.divider}
              sx={{
                backgroundImage: `url(${subspace?.metadata?.imageCover})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                overflow: 'hidden'
              }}
            />
          }
        </Box>

        <Box
          mx={2}
          display={'flex'}
          justifyContent='space-between'
        >
          <Box
            mt={0.5}
          // textAlign='center'
          >
            <Typography
              component={'p'}
              fontSize={'1.2rem'}
              // variant="h6"
              color={'primary'}
            >
              {subspaceLoading
                ?
                <Skeleton width={200} />
                :
                <>
                  {subspace?.name}
                  {/* {subspace?.verified &&
                    <Tooltip title={'Verified'}>
                      <VerifiedIcon color='primary' sx={{ mb: -0.3, fontSize: '1rem' }} />
                    </Tooltip>
                  } */}
                </>
              }
            </Typography>

            <Typography
              component={'p'}
              variant="caption"
              color={'primary'}

              sx={{ mt: -0.3 }}
            >
              {subspaceLoading
                ?
                <Skeleton width={150} />
                :
                <>
                  {`@${subspace?.alias}`}
                </>
              }
            </Typography>

            {!subspaceLoading &&
              <>
                <Typography
                  component={'p'}
                  variant="caption"
                  color={'primary'}

                  sx={{ mt: 0.3 }}
                >
                  {/* {subspace?.metadata?.bio} */}
                  {subspace?.metadata?.description}
                </Typography>
              </>
            }

          </Box>

          <Box
            ml='auto'
            display={'flex'}
            alignItems='center'
          >
            {/* {subspaceLoading
              ?
              <Skeleton width={'50px'} height={'30px'} variant="rounded" sx={{ mx: 1 }} />
              :
              <>
                {subspace.address.toString() !== mySubspaceAddress?.toString() &&
                  <Button
                    variant="text"
                    size='small'
                    sx={{ marginRight: 1 }}
                  // onClick={() => setPreviewOpen(true)}
                  >
                    DM
                  </Button>
                }
              </>
            } */}

            {subspaceLoading
              ?
              <Skeleton width={'80px'} height={'30px'} variant="rounded" />
              :
              <>
                {(subspace.creator.toString() !== profile?.address.toString())
                  ?
                  <Button
                    startIcon={loading 
                      ? 
                      <CircularProgress color="inherit" size={16} /> 
                      : 
                      <>
                      {joined ? <HowToReg /> : <PersonAdd />}
                      </>
                    }
                    variant={joined ? 'outlined' : 'contained'}
                    size='small'
                    disabled={loading || (profile === undefined)}

                    onClick={submit}
                  >
                    {loading
                      ?
                      <>
                        {joined ? 'Processing...' : 'Joining...'}
                      </>
                      :
                      <>
                        {joined ? 'Leave' : 'Join'}
                      </>
                    }
                  </Button>
                  :
                  <IconButton
                    component={NavLink}
                    to={`/communities/${subspace.address.toString()}/edit`}
                    size='small'
                  >
                    <Settings />
                  </IconButton>
                }
              </>
            }


          </Box>

        </Box>


      </CardMedia>

      <Divider />

      <CardContent>

        <Typography variant="caption" color={'primary'}>
          {subspaceLoading
            ?
            <Skeleton width={200} />
            :
            <>
              {/* Members: {addressesLoading ? <Skeleton /> : (addresses?.length || 1)} */}
            </>
          }
        </Typography>

      </CardContent>

    </Card>
  )
}



export function SubspaceHeaderSkeleton() {

  return (
    <Card>
      <CardContent>

        {/* Subspace Wallpaper */}
        <Box
          height={150}
        >
          <Skeleton height={'100%'} width={'100%'} variant='rectangular' animation={false} />
        </Box>

        <Box
          display={'flex'}
          justifyContent='space-between'
        >
          {/* Subspace Avatar */}
          <Box
            width={150}
            height={150}
            margin={'-75px 15px 0'}
          >
            <Skeleton
              width={'100%'}
              height={'100%'}
              variant="circular"
            // animation={false}
            />
          </Box>


          <Box
            mt={2}
          // textAlign='center'
          >
            <Skeleton width={200} />

            <Skeleton width={150} />
          </Box>

          <Box
            ml='auto'
            display={'flex'}
            alignItems='center'
          >

            <Skeleton width={'50px'} height={'30px'} variant="rounded" sx={{ mx: 1 }} />

            <Skeleton width={'80px'} height={'30px'} variant="rounded" />
          </Box>

        </Box>

      </CardContent>

      <Divider />

      <CardContent>
        <Skeleton width={100} />
      </CardContent>

    </Card>
  )
}
