import React from "react"
import ConversationCard from "../components/Messenger/ConversationCard"

export default function ConversationPage() {

  return (
    <>
      <ConversationCard />
    </>
  )

}