import React, { useContext } from 'react';
import Header from './components/layouts/Header';
// import Nav from './components/layouts/Nav';
import { Outlet, useNavigate } from 'react-router-dom';
import Footer from './components/layouts/Footer';
import { Container, Grid } from '@mui/material';
import SideMenu from './components/layouts/SideMenu';
import { AppContext } from './contexts/main';
import RightColumn from './components/layouts/RightColumn';
import LeftColumn from './components/layouts/LeftColumn';

function App() {

  const navigate = useNavigate();

  const { profile, profileLoading } = useContext(AppContext);

  if (!profile) {
    // navigate('/welcome');
  }

  return (
    <>
      <Header />

      <div id="main">
        <Container maxWidth="lg">
          <Grid container spacing={2}>

            <Grid item sm={2}>
              <LeftColumn />
            </Grid>

            <Grid item sm={7}>
              <Outlet />
            </Grid>

            <Grid item sm={3}>
              <RightColumn />
            </Grid>

          </Grid>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default App;
