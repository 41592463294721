import { useContext, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { Avatar, Badge, Box, CircularProgress, Divider, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { AppContext } from '../../contexts/main';
import { PublicKey } from '@solana/web3.js';
import { LensBlur } from '@mui/icons-material';
import { fromTimestamp } from '../../utils/helpers';


interface Notification {
  type: string;
  title: string;
  description: string;
  address: PublicKey;
  timestamp: number;
  link: string;
}

export default function Notifications() {

  const { profile, profileClient, connectionClient } = useContext(AppContext);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationsLoading, setNotifictionsLoading] = useState(false)
  const [notificationsError, setNotificationsError] = useState<string | null>(null);

  const fetchFollowers = () => {
    setNotifictionsLoading(true);

    connectionClient().findConnections(
      {
        target: profile?.address!,
        isToday: true
      }
    )
      .then(data => {
        setNotifictionsLoading(false);
        const notifications: Notification[] = data.map(item => {
          return {
            type: 'follower',
            title: 'New follower',
            description: `User ${item.initializer.toString().slice(0, 10)}... followed you 
                          ${fromTimestamp(item.createdAt)}
                          `,
            address: item.initializer,
            timestamp: item.createdAt,
            link: `/${item.initializer}`
          }
        })
        setNotifications(notifications);
      })
      .catch(error => {
        setNotifictionsLoading(false);
        setNotificationsError(error as string);
      })
  }

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchor(null);
  };

  useEffect(() => {
    if (profile) {
      fetchFollowers();
    }
  }, [])


  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          sx={{ mt: 0.5 }}
          onClick={handleOpenMenu}
          color='default'
        >
          <Badge
            invisible={notifications.length === 0}
            badgeContent={notifications.length}
            color="error"
            sx={{
              // fontSize: '0.75rem'
            }}
          >
            <NotificationsNoneIcon fontSize='medium' sx={{ color: theme => theme.palette.common.white }} />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        sx={{ mt: '35px' }}
        id="menu-notification"
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            minHeight: '30ch',
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: '30ch',
          },
        }}
        open={Boolean(anchor)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          component={NavLink}
          to="settings"
          onClick={() => setAnchor(null)}
        >
          <ListItemIcon>
            <NotificationsNoneIcon fontSize="small" />
          </ListItemIcon>

          <Typography variant="subtitle2" >
            Daily Notifications:
          </Typography>

        </MenuItem>

        <Divider />


        {notificationsLoading &&
          <Box
            display={'flex'}
            justifyContent='center'
            alignItems={'center'}
            minHeight={'100px'}
            color={theme => theme.palette.primary.light}
            fontSize={'0.9rem'}
          >
            <CircularProgress size={20} />
          </Box>
        }

        {!notificationsLoading && notifications.length === 0 &&
          <Box
            display={'flex'}
            justifyContent='center'
            alignItems={'center'}
            minHeight={'100px'}
            color={theme => theme.palette.primary.light}
            fontSize={'0.9rem'}
          >
            no items for today
          </Box>
        }

        {!notificationsLoading && notifications.length > 0 &&
          <>
            {notifications.map(item => (
              <MenuItem
                dense
              // onClick={searchItemClick}
              >
                <ListItemIcon>
                  <Avatar
                    component={NavLink}
                    to={item.link}
                    alt={item.address.toString()}
                    // src={item.image}
                    sx={{
                      height: 35,
                      width: 35,
                      bgcolor: theme => `${theme.palette.primary.main}`
                    }}
                    aria-label="recipe"
                  >
                    <LensBlur fontSize='small' />
                  </Avatar>
                </ListItemIcon>

                <Link
                  component={NavLink}
                  to={item.link}
                >
                  <ListItemText
                    sx={{
                      ml: 1,
                      maxWidth: 150
                    }}

                    primary={
                      <Typography
                      // noWrap
                      >
                        {item.title}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Typography
                          variant='caption'
                          noWrap
                          sx={{ color: theme => theme.palette.secondary.main }}
                        >
                          {fromTimestamp(item.timestamp)}
                        </Typography>
                        {/* {item.description} */}
                      </>
                    }
                    secondaryTypographyProps={{
                      display: 'flex',
                      // maxWidth: '100px'
                    }}
                  />
                </Link>

              </MenuItem>

            ))}
          </>
        }


      </Menu>
    </>
  )
}