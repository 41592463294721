import React, { useEffect, useState } from 'react'
import { IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';


interface BackButtonProps {

}

export function BackButton({}: BackButtonProps) {

  // const { loaderType, isLoading } = props;

  return (
    <>
      <IconButton
        component={NavLink}
        to={'/'}
      >
        <ChevronLeft />
      </IconButton>
    </>
  )
}
