import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import { useConnection } from "@solana/wallet-adapter-react";
import { useProfile } from '../hooks';
import { Avatar, Box, Card, CardContent, CardHeader, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VerifiedIcon from '@mui/icons-material/Verified';
import { PublicKey } from '@solana/web3.js';
import { GroupAdd } from '@mui/icons-material';
import { useSubspaces } from '../hooks/useSubspaces';


interface SuggestedCommunitiesProps {
  profileAddress?: PublicKey;
}

export default function SuggestedCommunities(props: SuggestedCommunitiesProps) {


  const { subspaces, subspacesLoading, subspacesError } = useSubspaces(
    {
      loadJsonMetadata: true
    }
  );

  return (
    <>
      {subspaces && subspaces.length > 0 && (
        <Card>
          <CardHeader
            disableTypography
            sx={{ paddingBottom: 0 }}
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon fontSize='small' />
              </IconButton>
            }
            title="New Communities"
          />

          <CardContent>
            <List>
              {subspaces?.map((subspace, index) => (
                <ListItem
                  disableGutters
                  secondaryAction={
                    <IconButton aria-label="comment">
                      <GroupAdd fontSize='small' />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      component={NavLink}
                      to={`/communities/${subspace.address.toString()}`}
                      alt={subspace.address.toString()}
                      src={subspace.metadata?.image}
                      sx={{ bgcolor: theme => `${theme.palette.primary.main}` }}
                      aria-label="recipe"
                    >
                      {subspace.alias ? subspace.alias.charAt(0).toUpperCase() : subspace.address.toString().charAt(0).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>

                  <Link
                    component={NavLink}
                    to={`/communities/${subspace.address.toString()}`}
                  >
                    <ListItemText
                      sx={{ maxWidth: 150 }}

                      primary={
                        <Typography
                          noWrap
                        >
                          {subspace.name}
                        </Typography>
                      }
                      secondary={`@${subspace.alias}`}
                    />
                  </Link>

                </ListItem>


              ))}

            </List>
          </CardContent>

        </Card>
      )}
    </>
  )
}
