import { useContext, useEffect, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, CircularProgress, Link, ListItemIcon, ListItemText, Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import { AppContext } from '../../contexts/main';
import { PublicKey } from '@solana/web3.js';
import { isPda } from '@ju-protocol/sdk';
import { LensBlur } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';


interface SearchResultItem {
  model: string;
  title?: string;
  alias: string | null;
  address: PublicKey;
  link: string;
  image?: string;
}

const StyledSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  // borderRadius: theme.shape.borderRadius,
  borderRadius: 8,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  marginRight: 15,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
  transition: 'all 0.3'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1.3),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.8, 0, 0.8, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '30ch',
      // '&:focus': {
      //   width: '35ch',
      // },
    },
  },
  fontSize: '0.9rem',
  transition: 'all 0.3'
}));


const SearchResultSkeleton = () => {

  return (
    <>
      <MenuItem
        // onClick={handleCloseUserMenu}
        component='div'
        sx={{ cursor: 'default' }}
      >
        <Skeleton width={'40px'} height={'40px'} variant="circular" animation='wave' />

        <ListItemText
          primary={<Skeleton animation='wave' />}
          secondary={<Skeleton width={'60%'} animation='wave' />}
          // secondaryTypographyProps={{ display: 'flex' }}
          sx={{ ml: 1 }}
        />
      </MenuItem>
    </>
  )
}


export default function Search() {

  const { APP, ju, profile, profileClient, subspaceClient, publicationClient, utilsClient } = useContext(AppContext);

  const [searchString, setSearchString] = useState('');

  const [searchResults, setSearchResults] = useState<SearchResultItem[]>([]);
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    if (searchString.length > 3) {
      setOpen(true);
      setIsLoading(true);

      if (isPda(searchString)) {
        // Search by address

        const pk = new PublicKey(searchString);
        try {
          const profile = await profileClient().getProfile(pk, true);

          const result = {
            model: profile.model,
            title: `${profile.firstName} ${profile.lastName}`,
            alias: profile.alias,
            address: profile.address,
            link: `/${profile.address.toString()}`,
            image: profile.metadata?.image
          }
          setSearchResults([result]);
          setIsLoading(false);
          return;
        } catch (error) {
          // Not a profile
          setError(error as string);
          // setIsLoading(false);
        }

        try {
          const subspace = await subspaceClient().getSubspace(pk, true);

          const result = {
            model: subspace.model,
            title: subspace.name,
            alias: subspace.alias,
            address: subspace.address,
            link: `/communities/${subspace.address.toString()}`,
            image: subspace.metadata?.image
          }
          setSearchResults([result]);
          setIsLoading(false);
          return;
        } catch (error) {
          // TODO
          setError(error as string);
          // setIsLoading(false);
        }

        try {
          const publication = await publicationClient().getPublication(pk, true);

          const result = {
            model: publication.model,
            title: 'Post',
            alias: null,
            address: publication.address,
            link: `/pubs/${publication.address.toString()}`,
          }
          setSearchResults([result]);
          setIsLoading(false);
          return;
        } catch (error) {
          // TODO
          setError(error as string);
          // setIsLoading(false);
        }

      } else {
        // Search alias

        const pda = ju.core().pdas().alias(
          {
            app: new PublicKey(APP),
            alias: searchString
          }
        )

        let isPdaExist = false;

        try {
          isPdaExist = await ju.rpc().accountExists(pda);
        } catch (error) {
          // TODO
          setError(error as string);
          setIsLoading(false);
        }

        if (isPdaExist) {

          const aliasAccount = await utilsClient().findAliasByValue(searchString);

          try {
            const profile = await profileClient().getProfile(aliasAccount!.owner, true);

            const result = {
              model: profile.model,
              title: `${profile.firstName} ${profile.lastName}`,
              alias: profile.alias,
              address: profile.address,
              link: `/${profile.address.toString()}`,
              image: profile.metadata?.image
            }
            setSearchResults([result]);
            setIsLoading(false);
            return;
          } catch (error) {
            // not a profile
            setError(error as string);
            // setIsLoading(false);
            console.log('error :>> ', error);
          }

          try {
            const subspace = await subspaceClient().getSubspace(aliasAccount!.owner, true);

            const result = {
              model: subspace.model,
              title: subspace.name,
              alias: subspace.alias,
              address: subspace.address,
              link: `/communities/${subspace.address.toString()}`,
              image: subspace.metadata?.image
            }
            setSearchResults([result]);
            setIsLoading(false);
            return;

          } catch (error) {
            // TODO
            setError(error as string);
            // setIsLoading(false);

            console.log('error :>> ', error);
          }
        }

        // Search Profiles by name/surname
        // TODO
      }

      setIsLoading(false);
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      setAnchorEl(event.currentTarget);
      fetchData();
    }
    if (event.key === "Escape") {
    }
  };

  const searchItemClick = () => {
    setSearchString('');
    setOpen(false);
  }

  // useEffect(() => {
  //   if (profile) {
  //     // fetchData();
  //   }
  // }, [])


  return (
    <>
      <StyledSearch id="search">
        <SearchIconWrapper>
          {isLoading ? <CircularProgress color="inherit" size={16} /> : <SearchIcon fontSize='small' />}
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          onKeyDown={handleKeyPress}

          value={searchString}
          onChange={e => setSearchString(e.target.value)}
        />
      </StyledSearch>

      <Menu
        id="search-result-block"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'search-result',
        }}
        sx={{
          mt: 1.5,
          width: '280px',
        }}
        // sx={{ width: `${Math.floor(elementWidth)}px` }}
        PaperProps={{
          sx: {
            width: `280px`,
            height: '300px',
            boxShadow: 1,
            // backgroundColor: '#eee'
          }
        }}
      >
        {!isLoading && searchResults.length > 0 &&
          <>
            {searchResults.map(item => (
              <MenuItem
                dense
                onClick={searchItemClick}
              >
                <ListItemIcon>
                  <Avatar
                    component={NavLink}
                    to={item.link}
                    alt={item.address.toString()}
                    src={item.image}
                    sx={{ bgcolor: theme => `${theme.palette.primary.main}` }}
                    aria-label="recipe"
                  >
                    <LensBlur fontSize='small' />
                  </Avatar>
                </ListItemIcon>

                <Link
                  component={NavLink}
                  to={item.link}
                >
                  <ListItemText
                    sx={{
                      ml: 1,
                      maxWidth: 150
                    }}

                    primary={
                      <Typography
                        noWrap
                      >
                        {item.title}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant='caption'
                        noWrap
                        sx={{ color: theme => theme.palette.secondary.main }}
                      >
                        {item.alias ? `@${item.alias}` : item.address.toString()}
                      </Typography>
                    }
                  />
                </Link>

              </MenuItem>

            ))}
          </>
        }

        {isLoading &&
          <>
            {[...Array(3)].map((item, index) => (
              <SearchResultSkeleton />
            ))}
          </>
        }

        {/* {!isLoading && error &&
          <Box
            height={'100%'}
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            fontSize={'0.85rem'}
          >
            Something went wrong
          </Box>
        } */}

        {/* 
        <Box
          height={'100%'}
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <CircularProgress color='inherit' size={20} sx={{ color: '#999' }} />
        </Box> */}


        {/* <Divider /> */}

        {/* <MenuItem dense disabled className={'search-result-footer'}>
          {[].length === 0 &&
            <>
              <Box
                component={'span'}
                bgcolor={theme => theme.palette.secondary.dark}
              >
                ▼
              </Box>
              <Box
                component={'span'}
                bgcolor={theme => theme.palette.secondary.dark}
              >
                ▲
              </Box> to navigate </>
          }
          <Box
            component={'span'}
            bgcolor={theme => theme.palette.secondary.dark}
          >
            ESC
          </Box>
          to Cancel
          {[].length === 0 &&
            <><Box component={'span'} bgcolor={theme => theme.palette.secondary.dark}>
              ↵
            </Box>
              to Enter
            </>
          }
        </MenuItem> */}

      </Menu>

      {/* <Backdrop open={open}></Backdrop>  */}

    </>

  )
}