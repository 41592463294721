import React, { useContext, useEffect, useState } from 'react'
import { useParams, NavLink } from "react-router-dom";
import { Avatar, Box, Card, CardContent, CardHeader, IconButton, List, ListItem } from '@mui/material';
import { ErrorComponent, Loader } from '../components/common';
import { PublicKey } from '@solana/web3.js';
import { AppContext } from '../contexts/main';
import { getProfilePda } from '../utils/helpers';
import { AutoAwesome, MoreVert, MusicNote, PhotoLibrary } from '@mui/icons-material';
import { Profile } from '@ju-protocol/sdk';


export default function SuggestionsPage() {

  const { profile, profileClient } = useContext(AppContext);

  const { id } = useParams();

  const [error, setError] = useState<string | null>(null);

  const [items, setItems] = useState<string[]>();
  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsError, setItemsError] = useState<Error | null>(null);

  const fetchData = () => {
    setItemsLoading(true);
    setItemsError(null);

    // TODO

  }

  useEffect(() => {

  }, []);


  return (
    <>
      {(error || !profile) &&
        <Card sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh'
        }}>
          <ErrorComponent str="Error" />
        </Card>
      }

      {profile &&
        <Card
          sx={{ minHeight: '90vh' }}
        >
          <CardHeader
            // disableTypography
            sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
            avatar={
              <Avatar
                component={NavLink}
                to={`/${profile?.address}`}
                alt={`/${profile?.address}`}
                src={profile?.metadata?.image}
                sx={{
                  mt: 0.5,
                  mr: 1,
                  bgcolor: theme => `${theme.palette.primary.main}`,
                  border: theme => `2px solid #ccc`
                }}
                aria-label="recipe"
              >
                {profile?.firstName ? profile.firstName.charAt(0) : profile?.address.toString().charAt(0)}
              </Avatar>
            }
            title={
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                textTransform={'uppercase'}
              >
                Suggestions <AutoAwesome fontSize='small' color='secondary' />
              </Box>
            }
            // subheader={
            //   <Link component={NavLink} to="/nuclearblock">
            //     <Typography variant='caption'>
            //       John Doe
            //     </Typography>
            //   </Link>
            // }

            action={
              <IconButton
                sx={{ mt: 1.5, mr: 1 }}
              >
                <MoreVert fontSize='small' />
              </IconButton>
            }
          />

          <CardContent
            sx={{ minHeight: '100%' }}
          >

            {items && items.length > 0 && (
              <List>
                {items.map((item, index) => (
                  <ListItem >
                    {/* <audio src={item.uri} controls style={{ width: '100%' }} /> */}
                  </ListItem>
                ))}
              </List>
            )}

          </CardContent>

        </Card>
      }
    </>
  )
}
