import React, { useEffect, useState, useContext } from 'react'
import { NavLink, useParams } from "react-router-dom";
import { Avatar, Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, IconButton, Input, InputAdornment, InputLabel, Link, MenuItem, Select, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { FileContent, useFilePicker } from 'use-file-picker';
import { JuFile, Subspace, SubspaceJsonMetadata, toJuFileFromBrowser } from '@ju-protocol/sdk';
import { useProfile, useSubspace } from '../hooks';
import { AppContext } from '../contexts/main';
import { ErrorComponent, Loader } from '../components/common';
import { enqueueSnackbar } from 'notistack';
import { SelectChangeEvent } from '@mui/material/Select';
import countries from '../libs/countries.json'
import SubmitDialog from '../components/SubmitDialog';
import { AlternateEmail, Check, ChevronLeft, GroupAdd, Save, Warning } from '@mui/icons-material';
import { BackButton } from '../components/common/BackButton';
import { PublicKey } from '@solana/web3.js';
import { getSubspacePda } from '../utils/helpers';


interface ImageAttachment {
  juFile?: JuFile;
  uri?: string;
}

interface Country {
  flag: any;
  title: string;
  code: number;
  iso2: string;
  iso3: string;
}

interface City {
  title: string;
  code: number;
  iso2: string;
  iso3: string;
}

interface ItemProps {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
}

const Item: React.FC<ItemProps> = ({ title, subtitle, children }) => {

  return (
    <Box
      mx={1}
      minHeight={'75px'}
      display={'flex'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
    >
      <Box mt={0.3}>
        <Typography
          variant='body1'
        >
          {title}
        </Typography>

        {subtitle &&
          <Typography
            variant='subtitle2'
            sx={{
              mt: -0.5,
              color: theme => theme.palette.divider
            }}
          >
            {subtitle}
          </Typography>
        }
      </Box>

      <Box
        minWidth={'60%'}
      >
        {children}
      </Box>

    </Box>
  );
}

export default function SubspaceUpdatePage() {

  const { APP, ju, subspaceClient } = useContext(AppContext);

  const { id } = useParams();

  const [subspacePublicKey, setSubspacePublicKey] = useState<PublicKey>();
  const [subspacePublicKeyError, setSubspacePublicKeyError] = useState<string | null>(null);

  const [subspace, setSubspace] = useState<Subspace>();
  const [subspaceLoading, setSubspaceLoading] = useState(false);
  const [subspaceError, setSubspaceError] = useState<Error | null>(null);

  const [avatar, setAvatar] = useState<ImageAttachment | undefined>(undefined);
  const [avatarError, setAvatarError] = useState<string | null>(null);

  const [imageCover, setImageCover] = useState<ImageAttachment | undefined>(undefined);
  const [imageCoverError, setImageCoverError] = useState<string | null>(null);

  const [alias, setAlias] = useState<string | null>(null);
  const [aliasError, setAliasError] = useState<string | null>(null);

  const [isCheckingAlias, setIsCheckingAlias] = useState(false);
  const [isAliasAvailable, setIsAliasAvailable] = useState<boolean | undefined>(undefined);

  const [name, setName] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);

  // const [country, setCountry] = useState<Country | null>(null);
  // const [countryError, setCountryError] = useState<string | null>(null);

  const [countryCode, setCountryCode] = useState<number | null>(null);
  const [countryCodeError, setCountryCodeError] = useState<string | null>(null);

  const [city, setCity] = useState<City | null>(null);
  const [cityError, setCityError] = useState<string | null>(null);

  const [statusText, setStatusText] = useState<string | null>(null);
  const [statusTextError, setStatusTextError] = useState<string | null>(null);

  const [description, setDescription] = useState<string | undefined>(undefined);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);


  const [isLoading, setIsLoading] = React.useState(false);

  const [isValidationError, setIsValidationError] = React.useState(false);

  const [countryList, setCountryList] = useState<Country[]>([])
  const [stateList, setStateList] = useState<Country[]>([])
  const [cityList, setCityList] = useState<City[]>([])

  const submitSteps: string[] = [
    "Upload metadata to decentralized storage",
    "Save data into Solana blockchain"
  ];
  const [activeStep, setActiveStep] = useState<number>(0);
  const [submitCompleted, setSubmitCompleted] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const fetchSubspace = () => {
    setSubspaceLoading(true);
    setSubspaceError(null);

    subspaceClient().getSubspace(subspacePublicKey!)
      .then(data => {
        setSubspace(data);
      })
      .catch(error => {
        setSubspaceError(error)
      })
      .finally(() => {
        setSubspaceLoading(false);
      })
  }


  const [openAvatarSelector, avatarPickerHelper] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    // minFileSize: 0.1, // in megabytes
    // maxFileSize: 50,
    // imageSizeRestrictions: {
    //   maxHeight: 900, // in pixels
    //   maxWidth: 1600,
    //   minHeight: 600,
    //   minWidth: 768,
    // },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
      console.log('plainFiles', plainFiles)
      toJuFileFromBrowser(plainFiles[0])
        .then(file => setAvatar(
          {
            juFile: file,
            uri: filesContent[0].content
          }
        ))
    },
  });

  const [openCoverImageSelector, imageCoverPickerHelper] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: false,
    // minFileSize: 0.1, // in megabytes
    // maxFileSize: 50,
    // imageSizeRestrictions: {
    //   maxHeight: 900, // in pixels
    //   maxWidth: 1600,
    //   minHeight: 600,
    //   minWidth: 768,
    // },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
      toJuFileFromBrowser(plainFiles[0])
        .then(file => setImageCover(
          {
            juFile: file,
            uri: filesContent[0].content
          }
        ))
    },
  });


  const aliasChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;

    setIsAliasAvailable(undefined);

    setAlias(value);
  }


  // Alias availability
  const checkAlias = async () => {
    if (alias && (alias !== subspace!.alias) && !(alias.length < 4 || alias.length > 30)) {
      setIsAliasAvailable(undefined);
      setIsCheckingAlias(true);

      // console.log(`checking alias ${alias} ...`)

      const aliasPda = ju.core().pdas().alias(
        {
          app: APP,
          alias
        }
      );

      try {
        const isExist = await ju.rpc().accountExists(aliasPda);

        if (isExist) {
          setAliasError(`@${alias} is unavailable`);

          setIsAliasAvailable(false);
          setIsCheckingAlias(false);

          setIsValidationError(true);
        } else {
          setIsAliasAvailable(true);
          setIsCheckingAlias(false);

          setIsValidationError(false);
        }
      } catch (error) {
        setAliasError(`Unable to check @${alias} alias`);

        setIsAliasAvailable(false);
        setIsCheckingAlias(false);

        setIsValidationError(true);
      }
    }
  }


  const validateAlias = () => {
    let isError = false;

    if (alias && (alias.length < 4 || alias.length > 30)) {
      isError = true;
      setAliasError('Alias must be between 3 and 10 characters!');
    } else {
      isError = false;
      setAliasError(null);
    }

    return isError;
  }


  const validateName = () => {
    let isError = false;

    if (name && name.length > 20) {
      isError = true;
      setNameError('Name must be between 2 and 20 characters!');
    } else {
      isError = false;
      setNameError(null);
    }

    return isError;
  }


  const validateStatusText = () => {
    let isError = false;

    if (statusText && statusText.length > 64) {
      isError = true;
      setStatusTextError('Status text must be no more than 64 characters');
    } else {
      isError = false;
      setStatusTextError(null);
    }

    return isError;
  }

  const validateDescription = () => {
    let isError = false;

    if (description && description.length > 300) {
      isError = true;
      setDescriptionError('Description must be no more than 300 characters');
    } else {
      isError = false;
      setDescriptionError(null);
    }

    return isError;
  }

  const validateAll = () => {
    let isError = false;

    if (validateAlias()) {
      isError = true;
    }

    if (validateName()) {
      isError = true;
    }

    if (validateStatusText()) {
      isError = true;
    }

    if (validateDescription()) {
      isError = true;
    }


    setIsValidationError(isError);
  }


  const onCountryChange = (event: SelectChangeEvent) => {
    let code: number | null = null;

    const value = event.target.value

    if (value !== '') {
      code = Number(value);
    }

    setCountryCode(code);
  }

  // const getCountries = () => {
  //   let headers = new Headers();
  //   headers.append("X-CSCAPI-KEY", "RmZWVWwzckwxVTBzbGNmTHF6dGNzSjFCRUhzcGlpd2FYSjI3T1Q4TQ==");

  //   const requestOptions: RequestInit = {
  //     method: 'GET',
  //     headers: headers,
  //     redirect: 'follow'
  //   };

  //   fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
  //     .then(response => response.json())
  //     .then(result => console.log(result))
  //     .catch(error => console.log('error', error));
  // }


  const updateSubspace = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);
    setActiveStep(0);

    // Upload metadata
    ju.storage().uploadMetadata<SubspaceJsonMetadata<JuFile | string>>(
      {
        appId: subspace?.app.toString(),

        title: name ? name : undefined,
        description: description,

        image: avatar?.juFile ? avatar?.juFile : avatar?.uri,
        imageCover: imageCover?.juFile ? imageCover?.juFile : imageCover?.uri,

      }
    ).then(res => {

      setActiveStep(1);

      subspaceClient().updateSubspace(
        subspace!,
        {
          alias,
          name: name ?? undefined,
          metadataUri: res.uri,
        }
      ).then(res => {
        enqueueSnackbar("Community updated successfully!");

        setSubmitCompleted(true);

        setIsLoading(false);
      })
        .catch(error => {
          // Error handler here
          enqueueSnackbar("Community update failed")

          setSubmitError(String(error));
          // console.log('error', error)

          setIsLoading(false);
        })
    })
      .catch(error => {
        // Metadata upload error handler here
        enqueueSnackbar("Community update failed")

        setSubmitError(String(error));
        // console.log('error', error)

        setIsLoading(false);
      })
      .finally(() => {

      })
  }


  useEffect(() => {
    // console.log('avatar', avatar)
    if (subspace) {
      setAlias(subspace.alias)
      setName(subspace.name)

      setAvatar(
        {
          juFile: undefined,
          uri: subspace.metadata?.image
        }
      );
      setImageCover(
        {
          juFile: undefined,
          uri: subspace.metadata?.imageCover
        }
      );

      // setStatusText(subspace.metadata?.description);
      setDescription(subspace.metadata?.description);

      // setCountryCode(subspace.countryCode);
    }
  }, [subspace]);

  useEffect(() => {
    validateAll();
  }, [
    name,
    alias,
    countryCode,
    //country
    city,
    description,
    statusText
  ]);


  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      checkAlias();
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [alias]);


  useEffect(() => {
    const subspacePda = getSubspacePda(id);
    // console.log('subspacePda :>> ', subspacePda?.toBase58());
    if (subspacePda) {
      setSubspacePublicKey(subspacePda);
    } else {
      setSubspacePublicKeyError('Subspace Public key error');
    }
  }, [id]);

  useEffect(() => {
    if (subspacePublicKey) {
      fetchSubspace();
    }
  }, [subspacePublicKey]);


  return (
    <>

      {false && //profileLoading &&
        <Card
          sx={{ minHeight: '90vh' }}
        >
          <CardHeader
            sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
            avatar={
              <Skeleton width={'40px'} height={'40px'} variant="circular" />
            }
            title={
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                textTransform={'uppercase'}
              >
                Edit Community <SettingsIcon fontSize='small' color='primary' />
              </Box>
            }
          />

          <CardContent

            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '80vh'
            }}
          >
            <Loader />
          </CardContent>

        </Card>
      }

      {false && //profileError &&
        <Card
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '90vh'
          }}
        >
          <ErrorComponent />

        </Card>
      }

      {true && //profile && !profileLoading && !profileError &&
        <Card>
          <CardHeader
            // disableTypography
            sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
            avatar={
              <>
                {/* <BackButton /> */}

                <Avatar
                  component={NavLink}
                  to={`/communities/${subspace?.address.toString()}`}
                  alt={subspace?.address.toString()}
                  src={subspace?.metadata?.image}
                  sx={{
                    mt: 0.5,
                    mr: 1,
                    bgcolor: theme => `${theme.palette.primary.main}`,
                    border: theme => `2px solid #ccc`
                  }}
                  aria-label="recipe"
                >
                  {subspace?.alias ? subspace?.alias.charAt(0).toUpperCase() : subspace?.address.toString().charAt(0).toUpperCase()}
                </Avatar>
              </>
            }
            title={
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={1}
                textTransform={'uppercase'}
              >
                Community Settings <GroupAdd fontSize='small' color='secondary' />
              </Box>
            }
          // subheader="subheader"
          // action={

          // }
          />

          <CardContent>

            <Box
              component='form'
              onSubmit={updateSubspace}
            >

              <Item
                title='Cover Image'
              >

                <Box
                  position='relative'
                  my={1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  height={100}
                  width={'100%'}
                  borderRadius={1}
                  border={theme => `1px solid ${theme.palette.divider}`}
                  bgcolor={theme => theme.palette.divider}
                  sx={{
                    cursor: 'pointer',
                    backgroundImage: `url(${imageCover?.uri})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    overflow: 'hidden',

                    transition: 'all 0.3s',
                    '&:hover': {
                      opacity: 0.8
                    },
                  }}
                  onClick={openCoverImageSelector}
                >
                  {!imageCover?.uri &&
                    <Typography
                      sx={{ color: theme => theme.palette.divider }}
                    >
                      not set
                    </Typography>
                  }

                  <DriveFileRenameOutlineIcon
                    fontSize='small'
                    color='primary'
                    sx={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px'
                    }}
                  />
                </Box>
              </Item>

              <Item
                title='Avatar image'
              // subtitle='test'
              >

                <Box
                  position='relative'
                  my={1}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  height={100}
                  width={100}
                  borderRadius={1}
                  border={theme => `1px solid ${theme.palette.divider}`}
                  bgcolor={theme => theme.palette.divider}
                  sx={{
                    cursor: 'pointer',
                    backgroundImage: `url(${avatar?.uri})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    overflow: 'hidden',

                    transition: 'all 0.3s',
                    '&:hover': {
                      opacity: 0.8
                    },
                  }}
                  onClick={openAvatarSelector}
                >
                  {!avatar?.uri &&
                    <Typography
                      sx={{ color: theme => theme.palette.divider }}
                    >
                      not set
                    </Typography>
                  }

                  <DriveFileRenameOutlineIcon
                    fontSize='small'
                    color='primary'
                    sx={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px'
                    }}
                  />
                </Box>

              </Item>


              <Divider variant='inset' light sx={{ my: 3 }} />


              <Item
                title='Alias'
              >
                <TextField
                  fullWidth
                  size='small'
                  id="input-alias"
                  label="alias"

                  autoComplete="off"

                  value={alias}

                  error={aliasError !== null}
                  helperText={aliasError}

                  InputLabelProps={{ shrink: true }}

                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >
                        <AlternateEmail fontSize='inherit' />
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment position="end" >
                        {isCheckingAlias &&
                          <CircularProgress color="inherit" size={16} />
                        }
                        {isAliasAvailable && !isCheckingAlias &&
                          <Check color="success" fontSize='inherit' />
                        }
                        {isAliasAvailable === false && !isCheckingAlias &&
                          <Warning color="error" fontSize='inherit' />
                        }
                      </InputAdornment>
                    )
                  }}

                  // onChange={(e) => setAlias(e.target.value)}
                  onChange={aliasChange}
                />
              </Item>

              <Item
                title='Title'
                subtitle='Subspace name'
              >

                <TextField
                  fullWidth
                  size='small'
                  id="input-name"
                  label="Name"
                  autoComplete="off"

                  value={name}

                  InputLabelProps={{ shrink: true }}

                  error={nameError !== null}
                  helperText={nameError}

                  onChange={(e) => setName(e.target.value)}
                />

              </Item>


              <Divider variant='inset' light sx={{ my: 3 }} />

              <Item
                title='Description'
              >

                <TextField
                  fullWidth
                  size='small'
                  multiline
                  minRows={5}
                  maxRows={5}
                  id="input-description"
                  label="Description"
                  autoComplete="off"

                  value={description}

                  InputLabelProps={{ shrink: true }}

                  error={descriptionError !== null}
                  helperText={descriptionError}

                  inputProps={{
                    style: { fontSize: '0.9rem' }
                  }}

                  onChange={(e) => setDescription(e.target.value)}
                />
              </Item>


              <Divider variant='inset' light sx={{ my: 3 }} />


              <Item
                title='Status text'
              >

                <TextField
                  fullWidth

                  multiline
                  minRows={3}
                  maxRows={3}

                  size='small'
                  id="input-status"
                  label="Status"
                  autoComplete="off"

                  value={statusText}

                  InputLabelProps={{ shrink: true }}

                  error={statusTextError !== null}
                  helperText={statusTextError}

                  inputProps={{
                    style: { fontSize: '0.9rem' }
                  }}

                  onChange={(e) => setStatusText(e.target.value)}
                />
              </Item>


              <Divider variant='inset' light sx={{ my: 3 }} />

              <Item
                title='Country'
              >
                <Select
                  fullWidth
                  size='small'
                  id="input-city"
                  // label="City"
                  placeholder='select country...'
                  // renderValue={(selected) => {
                  //   if (selected.length === 0) {
                  //     return <em>select country...</em>;
                  //   }

                  //   return selected;
                  // }}
                  value={String(countryCode ?? '')}
                  onChange={onCountryChange}
                >
                  {countries.map((item, index) => (
                    <MenuItem value={item.numeric_code}>{item.emoji} {item.name}</MenuItem>
                  ))}
                </Select>

              </Item>

              {/* <Item
                title='City'
              >

                <TextField
                  fullWidth
                  size='small'
                  id="input-city"
                  label="City"
                />

              </Item> */}


              <Divider variant='inset' light sx={{ my: 3 }} />


              {/* <Item
                title='Current Location'
              >

                <Box>
                  {location && (
                    <>
                      {location.latitude} / {location.longitude}
                    </>
                  )}

                  <Tooltip title={location ? 'Edit location' : 'Add location'} placement='top' arrow>
                    <IconButton>
                      <EditLocationAlt fontSize='small' color='primary' />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Item> */}

              {/* <Divider variant='inset' light sx={{ my: 3 }} /> */}

              <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Button
                  type='submit'
                  startIcon={isLoading ? <CircularProgress color="inherit" size={16} /> : <Save />}
                  variant="contained"
                  // size='small'
                  disabled={isLoading || isValidationError}
                >
                  {isLoading ? 'Saving...' : 'Save'}
                </Button>
              </Box>

            </Box>

          </CardContent>

        </Card>
      }

      <SubmitDialog
        isOpen={isLoading}
        steps={submitSteps}
        activeStep={activeStep}
        isCompleted={submitCompleted}
        stepError={submitError}
      />

    </>
  )

}