import React, { useContext, useEffect, useState } from 'react'
import { Amount, BundlrStorageDriver, Currency, JuFile, PublicationClient, PublicationJsonMetadata, toJuFile, toJuFileFromBrowser } from '@ju-protocol/sdk'
import { alpha, Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, IconButton, ImageList, ImageListItem, InputAdornment, InputBase, OutlinedInput, Paper, Skeleton, styled, Tab, Tabs, TextField, Tooltip, Typography, useTheme } from '@mui/material';
// import LoadingButton from '@mui/lab/LoadingButton';
import PlaceIcon from '@mui/icons-material/Place';
import VideocamIcon from '@mui/icons-material/Videocam';
import CollectionsIcon from '@mui/icons-material/Collections';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DirectionsIcon from '@mui/icons-material/Directions';
import { AppContext } from '../../contexts/main';
import { useFilePicker, FileContent } from 'use-file-picker';
import { PublicKey } from '@solana/web3.js'
import ReactPlayer from 'react-player'
import { usePublicationCreate } from '../../hooks/usePublicationCreate';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { SelectAll } from '@mui/icons-material';
import { DataURIToByteString } from '../../utils/helpers';


interface FileAttachment {
  uri: string,
  name?: string,
  size?: number,
  type: string,
  description?: string,
  isExternalLink: boolean
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PublicationCreate({ subspaceAddress, avatar }: { subspaceAddress?: PublicKey, avatar?: string }) {

  const navigate = useNavigate();

  const { APP, ju, profile, publicationClient } = useContext(AppContext);

  // console.log('profile (PublicationCreateCard)', profile)

  const [content, setContent] = useState<string>('');

  const [files, setFiles] = useState<FileAttachment[]>([]);

  const [mainMediaCount, setMainMediaCount] = useState(0);

  const [isLoading, setIsLoading] = React.useState(false);

  const [videoPickerDialogOpen, setVideoPickerDialogOpen] = useState(false);
  const [videoPickerDialogTabValue, setVideoPickerDialogTabValue] = React.useState(0);
  const [videoExternalLink, setVideoExternalLink] = useState<string | undefined>(undefined);

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(0);

  const handleVideoPickerDialogTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setVideoPickerDialogTabValue(newValue);
  };

  const [uploadPrice, setUploadPrice] = useState<Amount<Currency>>();
  const [isUploadPriceFetching, setIsUploadPriceFetching] = useState(false);
  const [isUploadPriceError, setIsUploadPriceError] = useState<Error | null>(null);

  // const [publicationAddress, setPublicationAddress] = useState<PublicKey>();
  // const [publication, setPublication] = useState<Publication>();
  // const [response, setResponse] = useState<SendAndConfirmTransactionResponse>();
  // const [publicationCreateLoading, setPublicationCreateLoading] = useState(false);
  // const [publicationCreateError, setPublicationCreateError] = useState<Error | null>(null);


  const [selectImage, imagePicker] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    multiple: true,
    limitFilesConfig: { max: 10 },
    // minFileSize: 0.1, // in megabytes
    // maxFileSize: 50,
    // imageSizeRestrictions: {
    //   maxHeight: 900, // in pixels
    //   maxWidth: 1600,
    //   minHeight: 600,
    //   minWidth: 768,
    // },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
      addAttachments('image', filesContent);
    },
  });

  const [selectVideo, videoPicker] = useFilePicker({
    multiple: true,
    readAs: 'DataURL',
    accept: 'video/*',
    readFilesContent: true,
    onFilesRejected: ({ errors }) => {
    },
    onFilesSelected: () => {
      setVideoPickerDialogOpen(false);
    },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
      addAttachments('video', filesContent);
    },
  });

  const [selectAudio, audioPicker] = useFilePicker({
    readAs: 'DataURL',
    accept: 'audio/*',
    multiple: true,
    limitFilesConfig: { max: 10 },
    // minFileSize: 0.1, // in megabytes
    // maxFileSize: 50,
    // imageSizeRestrictions: {
    //   maxHeight: 900, // in pixels
    //   maxWidth: 1600,
    //   minHeight: 600,
    //   minWidth: 768,
    // },
    onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
      addAttachments('audio', filesContent);
    },
  });

  const addAttachments = async (contentType = 'image', filesContent: FileContent[]) => {
    const newAttachments: FileAttachment[] = [];
    filesContent.forEach((fileContent, index) => {
      const attachment: FileAttachment = {
        uri: fileContent.content,
        name: fileContent.name,
        size: fileContent.size,
        type: contentType,
        description: undefined,
        isExternalLink: false
      };
      newAttachments.push(attachment);
    })
    setFiles(oldData => [...oldData, ...newAttachments]);
  }

  const addExternalVideo = () => {
    if (videoExternalLink) {
      const attachment: FileAttachment = {
        uri: videoExternalLink,
        type: 'video',
        isExternalLink: true
      };

      setFiles(oldData => [...oldData, attachment]);

      setVideoPickerDialogOpen(false);
      setVideoExternalLink(undefined);
    }
  }

  const removeFileByIndex = (index: number) => {
    setFiles(previousFiles => [
      ...previousFiles.slice(0, index),
      ...previousFiles.slice(index + 1)
    ]);
  }


  const handleClose = (value: number) => {
    setOpen(false);
    setSelectedValue(value);
  };

  // This function will be triggered when the thumbnail is clicked
  const mainMediaClick = (
    value: number,
    // event: React.MouseEvent<HTMLLIElement>
    event?: any
  ) => {
    // console.log('value', value)
    event?.preventDefault();
    event?.stopPropagation();

    const element = event?.currentTarget;

    setSelectedValue(value);
    setOpen(true);
  };

  const calculateSize = (itemsCount: number) => {
    let sizes = {
      rows: 1,
      columns: 1
    }

    if (itemsCount > 2 && itemsCount <= 4) {
      sizes.rows = itemsCount - 1;
      sizes.columns = itemsCount - 1;
    } else if (itemsCount > 4) {
      sizes.rows = 4;
      sizes.columns = 4
    }

    return sizes;
  }


  async function create() {
    if (uploadPrice) {
      setIsLoading(true);

      // Upload metadata containing all media.
      ju.storage().uploadMetadata<PublicationJsonMetadata<JuFile | string>>(
        {
          appId: APP.toString(),
          content: {
            type: 'plainText',
            value: content
          },
          attachments: files.map(file => (
            {
              type: file.type,
              description: '',
              uri: file.isExternalLink ? file.uri : toJuFile(DataURIToByteString(file.uri), file.name!)
            }
          ))
        }
      ).then(res => {
        publicationClient().createPublication(
          {
            isEncrypted: false,
            metadataUri: res.uri,

            subspace: subspaceAddress,

            externalProcessors: {
              collectingProcessor: null,
              referencingProcessor: null
            }
          }
        ).then(res => {
          enqueueSnackbar("Publication successfully created!");
          setFiles([]);
          setContent('');

          setIsLoading(false);

          navigate(0);
        })
          .catch(error => {
            // Publication submit error handler here
            enqueueSnackbar("Publishing failed")
            setIsLoading(false);
          })
      })
        .catch(error => {
          // Metadata upload error handler here
          enqueueSnackbar("Publishing failed")
          setIsLoading(false);
        })
        .finally(() => {

        })
    }
  }

  const getUploadPrice = () => {
    setIsUploadPriceFetching(true);

    let totalBytes = 0;
    files.forEach(item => {
      if (item.isExternalLink) {

      } else {
        totalBytes = + DataURIToByteString(item.uri).byteLength;
      }
    })

    ju.storage()
      .getUploadPriceForBytes(totalBytes)
      .then(result => {
        setUploadPrice(result);
      })
      .catch(error => {
        setIsUploadPriceError(error);
      })
      .finally(() => setIsUploadPriceFetching(false))
  }

  useEffect(() => {
    setMainMediaCount(files.filter(file => (file.type?.includes('video') || file.type?.includes('image'))).length);

    getUploadPrice();

    // console.log(ju.storage().driver());
  }, [files]);


  return (
    <>
      <Card>

        <CardContent>

          <InputBase
            fullWidth
            multiline
            maxRows={6}
            startAdornment={
              <Avatar
                src={profile?.metadata?.image}

                sx={{
                  mr: 1,
                  // mb: 1,
                  bgcolor: (theme) => `${theme.palette.primary.main}`
                }}
                aria-label="publication-create-avatar"
              >
                &nbsp;
                {/* {profile?.alias ? profile.alias.charAt(0).toUpperCase() : publication.profile.toString().charAt(0).toUpperCase()} */}
              </Avatar>
            }

            inputProps={{
              pt: '10px',
              'aria-label': 'create post'
            }}

            sx={{
              ml: 0,
              // mt:1,
              fontSize: '0.9rem',
              alignItems: 'flex-start'
            }}
            color='primary'

            placeholder="what's new?"

            value={content}
            onChange={(event) => setContent(event.target.value)}
            disabled={isLoading}
          />

          {mainMediaCount > 0 &&
            <ImageList
              variant="quilted"
              cols={mainMediaCount > 4 ? 4 : mainMediaCount}
              rowHeight={mainMediaCount === 1 ? 500 : 'auto'}
              gap={10}
            >
              {files.map((item, index) => (
                (item.type === 'image' || item.type === 'video') &&
                <ImageListItem
                  key={index}
                  rows={index === 0 ? calculateSize(mainMediaCount).rows : 1}
                  cols={index === 0 ? calculateSize(mainMediaCount).columns : 1}
                  sx={{
                    borderRadius: '4px',
                    overflow: 'hidden',
                    backgroundColor: '#000000',
                    transition: 'all 0.3s',
                    opacity: isLoading ? 0.6 : 0.9,
                    '&:hover': {
                      opacity: 1
                    },
                    pointerEvents: isLoading ? 'none' : 'auto',
                    cursor: 'pointer'
                  }}

                >
                  {item.type === 'image' &&
                    <img
                      // srcSet={item.content}
                      src={item.uri}
                      alt={item.description}
                      loading="lazy"

                      onClick={(e) => mainMediaClick(index, e)}
                    />
                  }

                  {item.type === 'video' &&
                    <Box
                      height={'100%'}
                      sx={{ minHeight: '200px' }}
                      onClick={(e) => mainMediaClick(index, e)}
                    >
                      <ReactPlayer
                        light={item.isExternalLink}
                        url={item.uri}
                        width='100%'
                        height='100%'
                        // height={index === 0 ? '100%' : `${360/calculateSize(mainMediaCount).rows}`}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          pointerEvents: 'none',
                        }}
                      />
                    </Box>
                  }

                  <Tooltip arrow title='delete'>
                    <IconButton
                      aria-label="delete"
                      size='small'
                      // onClick={() => videoPickerHelper.removeFileByIndex(index)}
                      onClick={() => removeFileByIndex(index)}
                      sx={{
                        position: 'absolute',
                        right: '0',
                        top: '0',
                        // zIndex: 1200
                      }}
                      color={'secondary'}
                    >
                      <ClearIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>

                </ImageListItem>
              ))}
            </ImageList>

          }


          {files.filter(file => (file.type?.includes('audio'))).length > 0 &&
            <Box mt={3} mb={0}>

              {/* <Divider /> */}

              {files.map((file, index) => (
                <Box key={index} position='relative'>

                  {file.type?.includes('audio') &&
                    <audio src={file.uri} controls />
                  }

                  <Tooltip arrow title='delete'>
                    <IconButton
                      aria-label="delete"
                      size='small'
                      // onClick={() => audioPickerHelper.removeFileByIndex(index)}
                      onClick={() => removeFileByIndex(index)}
                      sx={{
                        position: 'absolute',
                        right: '0',
                        top: '0',
                        // zIndex: 1200
                      }}
                    >
                      <ClearIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                </Box>
              ))}
            </Box>
          }

        </CardContent>

        <CardActions >
          <Tooltip arrow title='Add location' placement={'top'} >
            <IconButton
              aria-label="location"
              size='small'
              color='secondary'
              disabled={isLoading || (profile === undefined)}
            >
              <PlaceIcon fontSize='small' />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title='Add images' placement={'top'} >
            <IconButton
              aria-label="image"
              size='small'
              color='secondary'
              onClick={selectImage}
              disabled={isLoading || (profile === undefined)}
            >
              <CollectionsIcon fontSize='small' />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title='All video' placement={'top'} >
            <IconButton
              aria-label="video"
              size='small'
              color='secondary'
              // onClick={selectVideo}
              onClick={() => setVideoPickerDialogOpen(true)}
              disabled={isLoading || (profile === undefined)}
            >
              <VideocamIcon fontSize='small' />
              {/* <input
                hidden
                type="file"
                accept="video/*"
                multiple
                ref={inputRefVideo}
              // onChange={handleProgramFileSelect}
              /> */}
            </IconButton>
          </Tooltip>

          <Tooltip arrow title='Add audio' placement={'top'} >
            <IconButton
              aria-label="audio"
              size='small'
              color='secondary'
              onClick={selectAudio}
              disabled={isLoading || (profile === undefined)}
            >
              <AudiotrackIcon fontSize='small' />
            </IconButton>
          </Tooltip>


          <Box
            display={'flex'}
            justifyContent={'end'}
            width={'100%'}
            gap={2}
            // ml={'auto'}
            mr={1}
          >

            {files.length > 0 && uploadPrice &&
              <Box textAlign={'right'}>
                <Typography
                  component={'p'}
                  variant='caption'
                  color={theme => isLoading ? theme.palette.divider : theme.palette.primary.main}
                  sx={{ mt: -0.3 }}
                >
                  Estimated upload price:
                </Typography>
                <Typography
                  component={'p'}
                  variant='caption'
                  sx={{ mt: -0.5 }}
                  color={theme => isLoading ? theme.palette.divider : theme.palette.secondary.main}
                >
                  {isUploadPriceFetching ? <Skeleton width={'50px'} /> : `${uploadPrice.basisPoints / Math.pow(10, uploadPrice.currency.decimals)} ${uploadPrice.currency.symbol}`}
                </Typography>
              </Box>
            }

            <Box>
              <Button
                startIcon={isLoading ? <CircularProgress color="inherit" size={16} /> : <CloudUploadIcon />}
                variant="contained"
                size='small'
                onClick={create}
                disabled={isLoading || (!content && files.length === 0)}
              >
                {isLoading ? 'Publishing...' : 'Publish'}
              </Button>
              {/* <LoadingButton
                size="small"
                onClick={create}
                startIcon={<CloudUploadIcon />}
                loading={!isLoading}
                // loadingIndicator="Publishing..."
                loadingPosition="start"
                variant="contained"
              >
                <span>Publish</span>
              </LoadingButton> */}
            </Box>
          </Box>

        </CardActions>
      </Card >


      {
        files[selectedValue] &&
        <Dialog
          fullWidth
          maxWidth={'md'}
          onClose={handleClose}
          open={open}
        >
          {files[selectedValue].type === 'image' &&
            <img
              // srcSet={item.content}
              src={files[selectedValue].uri}
              alt={files[selectedValue].description}
              loading="lazy"
            />
          }

          {files[selectedValue].type === 'video' &&
            <ReactPlayer
              controls
              url={files[selectedValue].uri}
              width='100%'
              height='80vh'
            // style={{
            //   display: 'flex',
            //   justifyContent: 'center',
            // }}
            />
          }
        </Dialog>
      }


      {/* Image upload dialog */}
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={videoPickerDialogOpen}
        onClose={() => setVideoPickerDialogOpen(false)}
      // sx={{
      //   minHeight: '300px'
      // }}
      >
        <DialogTitle>
          Add video
        </DialogTitle>

        <DialogContent
          sx={{
            minHeight: '450px'
          }}
        >

          <Box
            sx={{
              width: '100%',
              // height: '30vh'
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={videoPickerDialogTabValue} onChange={handleVideoPickerDialogTabChange} aria-label="basic tabs example">
                <Tab label="Local computer" {...a11yProps(0)} />
                <Tab label="External Link" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel
              value={videoPickerDialogTabValue}
              index={0}
            >
              <Box
                height={'200px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Button
                  variant="contained"
                  // size='small'
                  startIcon={<SelectAll />}
                  onClick={selectVideo}
                >
                  Select file
                </Button>
              </Box>
            </CustomTabPanel>

            <CustomTabPanel value={videoPickerDialogTabValue} index={1}>
              {/* External Link */}

              <Paper
                component="form"
                sx={{
                  mb: 2,
                  p: '0 2px',
                  display: 'flex',
                  alignItems: 'center',
                  // width: 400
                  backgroundColor: theme => alpha('#ccc', 0.15),
                  boxShadow: 'none'
                }}
              >
                <IconButton
                  // size='small'
                  sx={{ p: '5px' }}
                  aria-label="video"
                >
                  <VideocamIcon fontSize='small' color='primary' />
                </IconButton>

                <InputBase
                  value={videoExternalLink || ''}
                  sx={{
                    ml: 0,
                    flex: 1,
                    fontSize: '0.9rem',
                  }}
                  color='secondary'
                  placeholder="external link ..."
                  inputProps={{ 'aria-label': 'add external video' }}
                  onChange={(event) => setVideoExternalLink(event.target.value)}
                />

                {/* <Divider sx={{ height: 28, my: 0.5 }} orientation="vertical" /> */}

                <IconButton
                  color="primary"
                  size='small'
                  sx={{ p: '10px' }} aria-label="add link"
                  onClick={() => setVideoExternalLink(undefined)}
                >
                  <ClearIcon fontSize='small' />
                </IconButton>

              </Paper>


              {videoExternalLink &&
                <Box>
                  <ReactPlayer
                    light
                    url={videoExternalLink}
                    // controls
                    width='100%'
                    height='300px'
                  />

                  <Button
                    fullWidth
                    variant="contained"
                    size='small'
                    sx={{ mt: 1 }}
                    // onClick={() => setVideoPickerDialogOpen(false)}
                    onClick={addExternalVideo}
                  >
                    Add video
                  </Button>

                </Box>
              }

            </CustomTabPanel>
          </Box>
        </DialogContent>

        {/* <DialogActions>
          <Button
            // disabled
            variant="outlined"
            size='small'
            onClick={() => setVideoPickerDialogOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions> */}

      </Dialog>
    </>

  )
}
