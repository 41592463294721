import React, { useEffect, useState, useContext } from 'react'
import { NavLink } from "react-router-dom";
import { Avatar, Box, Card, CardContent, CardHeader, Divider, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatIcon from '@mui/icons-material/Chat';
import VerifiedIcon from '@mui/icons-material/Verified';
import { MoreHoriz, MoreVert } from '@mui/icons-material';
import { AppContext } from '../contexts/main';


function ConversationListSkeleton() {

  const { profile, profileClient } = useContext(AppContext);

  return (
    <>
      <Card>
        <CardHeader
          // disableTypography
          sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
          avatar={
            <Avatar
              component={NavLink}
              to={`/${profile?.address}`}
              alt={`/${profile?.address}`}
              src={profile?.metadata?.image}
              sx={{
                mt: 0.5,
                mr: 1,
                bgcolor: theme => `${theme.palette.primary.main}`,
                border: theme => `2px solid #ccc`
              }}
              aria-label="recipe"
            >
              {profile?.firstName ? profile.firstName.charAt(0) : profile?.address.toString().charAt(0)}
            </Avatar>
          }
          action={<></>}

          title={
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={1}
              textTransform={'uppercase'}
            >
              Messenger <ChatIcon fontSize='small' color='secondary' />
            </Box>
          }
        />

        <CardContent
          sx={{ minHeight: '80vh' }}
        >
          <List>

            {[...Array(8)].map(item => (
              <>
                <ListItem
                  // disableGutters
                  secondaryAction={
                    <>
                      <Skeleton width={'30px'} animation='wave' />
                      <Skeleton width={'10px'} animation='wave'/>
                    </>
                  }
                >
                  <ListItemAvatar>
                    <Skeleton width={'40px'} height={'40px'} variant="circular" animation='wave'/>
                  </ListItemAvatar>

                  <ListItemText
                    primary={<Skeleton width={'60%'} animation='wave'/>}
                    secondary={
                      <Skeleton width={'80%'} animation='wave'/>
                    }
                  />

                </ListItem>

                <Divider variant='inset' />
              </>
            ))}

          </List>
        </CardContent>
      </Card>
    </>
  );
}

export default function MessengerPage() {

  const { profile } = useContext(AppContext);

  return (
    <>

      <ConversationListSkeleton />

      {/* <Card>
        <CardHeader
          // disableTypography
          sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}
          avatar={
            <Avatar
              component={NavLink}
              to={`/${profile?.address}`}
              alt={`/${profile?.address}`}
              src={profile?.metadata?.image}
              sx={{
                mt: 0.5,
                mr: 1,
                bgcolor: theme => `${theme.palette.primary.main}`
              }}
              aria-label="recipe"
            >
              {profile?.firstName ? profile.firstName.charAt(0) : profile?.address.toString().charAt(0)}
            </Avatar>
          }
          title={
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={1}
              textTransform={'uppercase'}
            >
              Messenger <ChatIcon fontSize='small' color='secondary' />
            </Box>
          }
          // subheader={
          //   <Link component={NavLink} to="/nuclearblock">
          //     <Typography variant='caption'>
          //       John Doe
          //     </Typography>
          //   </Link>
          // }

          action={
            <IconButton
              sx={{ mt: 1.5, mr: 1 }}
            >
              <MoreVert fontSize='small' />
            </IconButton>
          }
        />

        <CardContent
          sx={{ minHeight: '80vh' }}
        >
          <List>

            <ListItem
              // disableGutters
              secondaryAction={
                <>
                  <Typography
                    variant='caption'
                    color={theme => theme.palette.secondary.dark}
                    sx={{ mr: 1 }}
                  >
                    27 aug
                  </Typography>

                  <IconButton aria-label="comment">
                    <MoreHoriz fontSize='small' color='secondary' />
                  </IconButton>
                </>
              }
            >
              <ListItemAvatar>
                <Avatar
                  component={NavLink}
                  to="1"
                  alt="NY Times"
                  src="https://mui.com/static/images/avatar/5.jpg"
                  sx={{ bgcolor: theme => `${theme.palette.primary.main}` }}
                  aria-label="recipe"
                >
                  R
                </Avatar>
              </ListItemAvatar>

              <Link
                component={NavLink}
                to="1"
              >
                <ListItemText primary="John Doe" secondary="Lasm message form user..." />
              </Link>
            </ListItem>

            <Divider variant='inset' />

            <ListItem
              // disableGutters
              secondaryAction={
                <>
                  <Typography
                    variant='caption'
                    color={theme => theme.palette.secondary.dark}
                    sx={{ mr: 1 }}
                  >
                    27 aug
                  </Typography>

                  <IconButton aria-label="comment">
                    <MoreHoriz fontSize='small' color='secondary' />
                  </IconButton>
                </>
              }
            >
              <ListItemAvatar>
                <Avatar
                  component={NavLink}
                  to="1"
                  alt="NY Times"
                  src="https://mui.com/static/images/avatar/5.jpg"
                  sx={{ bgcolor: theme => `${theme.palette.primary.main}` }}
                  aria-label="recipe"
                >
                  R
                </Avatar>
              </ListItemAvatar>

              <Link
                component={NavLink}
                to="1"
              >
                <ListItemText primary="John Doe" secondary="Lasm message form user..." />
              </Link>
            </ListItem>

            <Divider variant='inset' />

            <ListItem
              // disableGutters
              secondaryAction={
                <>
                  <Typography
                    variant='caption'
                    color={theme => theme.palette.secondary.dark}
                    sx={{ mr: 1 }}
                  >
                    27 aug
                  </Typography>

                  <IconButton aria-label="comment">
                    <MoreHoriz fontSize='small' color='secondary' />
                  </IconButton>
                </>
              }
            >
              <ListItemAvatar>
                <Avatar
                  component={NavLink}
                  to="1"
                  alt="NY Times"
                  src="https://mui.com/static/images/avatar/5.jpg"
                  sx={{ bgcolor: theme => `${theme.palette.primary.main}` }}
                  aria-label="recipe"
                >
                  R
                </Avatar>
              </ListItemAvatar>

              <Link
                component={NavLink}
                to="1"
              >
                <ListItemText primary="John Doe" secondary="Lasm message form user..." />
              </Link>
            </ListItem>

            <Divider variant='inset' />

          </List>
        </CardContent>

      </Card> */}
    </>
  )

}